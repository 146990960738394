import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  addBanners,
  addMensagens,
  addPopup,
  addParceiros,
  setOcupacao,
} from "../../logic/actions";
import {
  Button,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Table,
} from "reactstrap";
import { fetchAcessToken123Milhas } from "../../logic/utilities/request";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      horario: new Date(),
      link123Milhas: "",
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidMount() {
    this.setBanners();
    this.setMensagens();
    this.setPopup();
    this.setAcessos();
    this.setParceiros();
    this.setCampanha123();
  }

  async setPopup() {
    var data = await this.props.requisition(
      false,
      false,
      "https://minastenisclube.com.br/umbraco/api/areaassociado/getpopup",
      ""
    );

    if (data.status === "ok") {
      this.props.dispatch(addPopup(data.response));
    }
  }

  async setMensagens() {
    var data = await this.props.requisition(
      false,
      false,
      "https://minastenisclube.com.br/umbraco/api/areaassociado/getmensagens",
      ""
    );

    if (data && data.status === "ok") {
      this.props.dispatch(addMensagens(data.response));
    }
  }

  async setBanners() {
    var data = await this.props.requisition(
      false,
      false,
      "https://minastenisclube.com.br/umbraco/api/areaassociado/getbanner",
      ""
    );

    if (data && data.status === "ok") {
      this.props.dispatch(addBanners(data.response));
    }
  }

  async setParceiros() {
    var data = await this.props.requisition(
      false,
      true,
      "https://socio.minastc.com.br/ws/imagens_area_logada.php",
      "key=97882219848c23186ba37696ba8f8620",
      ""
    );
    if (data && data.status === "ok") {
      this.props.dispatch(addParceiros(data.eventos));
    }
  }

  async setCampanha123() {
    console.log("acess:" + this.state.link123Milhas);
    var acessToken = await fetchAcessToken123Milhas(this.props.usuario.npf);
    console.log("acess:" + this.state.link123Milhas);
    if (acessToken !== "") {
      var link =
        "https://minasclube.parceiros.123milhas.com/?auth=" + acessToken;

      this.setState({
        link123Milhas: link,
      });
    }
  }

  async setAcessos() {
    var data = await this.props.requisition(
      false,
      false,
      "https://socio.minastc.com.br/ws/acessos_ao_clube.php",
      "hora=" + this.state.horario.getHours()
    );

    if (data && data.status === "ok") {
      this.props.dispatch(setOcupacao(data.res.acessos));
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.home.banners.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.home.banners.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  renderMensagem(mensagem, key) {
    if (mensagem != null) {
      if (mensagem.tipo === "Informativo (Azul)") {
        return (
          <Alert color="info" className="shadow" key={key}>
            <strong>{mensagem.titulo}</strong> {ReactHtmlParser(mensagem.texto)}
          </Alert>
        );
      } else if (mensagem.tipo === "Sucesso (Verde)") {
        return (
          <Alert color="success" className="shadow" key={key}>
            <strong>{mensagem.titulo}</strong> {ReactHtmlParser(mensagem.texto)}
          </Alert>
        );
      } else if (mensagem.tipo === "Perigo (Vermelho)") {
        return (
          <Alert color="danger" className="shadow" key={key}>
            <strong>{mensagem.titulo}</strong> {ReactHtmlParser(mensagem.texto)}
          </Alert>
        );
      } else if (mensagem.tipo === "Alerta (Laranja)") {
        return (
          <Alert color="warning" className="shadow" key={key}>
            <strong>{mensagem.titulo}</strong> {ReactHtmlParser(mensagem.texto)}
          </Alert>
        );
      } else {
        return (
          <Alert color="white" className="shadow" key={key}>
            <strong>{mensagem.titulo}</strong> {ReactHtmlParser(mensagem.texto)}
          </Alert>
        );
      }
    } else {
      return "";
    }
  }

  exibirTermos() {
    window.open(
      "http://socio.minastc.com.br/termo.php?filial=" +
        this.props.usuario.unidade +
        "&token=" +
        this.props.usuario.token +
        "&npf=" +
        this.props.usuario.npf,
      "_blank"
    );
  }

  redirectPesquisa() {
    this.props.history.push("satisfacao");
  }

  render() {
    return (
      <>
        <Row>
          <Col className="col-lg-8 col-xxl-7 offset-xxl-1">
            <Card className="bg-secondary shadow mb-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col className="justify-content-between align-items-center d-md-flex text-center">
                    <h3 className="mb-12 mb-md-0 d-inline-block">
                      Pesquisa de Satisfação
                    </h3>
                    <Button
                      color="minas-blue"
                      onClick={() => this.redirectPesquisa()}
                    >
                      {" "}
                      Clique aqui para preencher!{" "}
                      <i className="fas fa-file-alt pl-1"></i>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            <Card className="bg-secondary shadow mb-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col className="justify-content-between align-items-center d-md-flex text-center">
                    <h3 className="mb-12 mb-md-0 d-inline-block">
                      Ocupação do clube no momento
                    </h3>
                    <p className="my-auto">
                      Horário da pesquisa: {this.state.horario.getHours()}:
                      {this.state.horario.getMinutes()}
                    </p>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center"></th>
                    <th scope="col" className="text-center">
                      Minas I
                    </th>
                    <th scope="col" className="text-center">
                      Minas II
                    </th>
                    <th scope="col" className="text-center">
                      Country
                    </th>
                    <th scope="col" className="text-center">
                      Náutico
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Vagas ocupadas
                      <br />
                      nos clubes
                    </td>
                    <td className="text-center">
                      {this.props.ocupacao.minas1}
                      {this.props.ocupacao.exibirCapacidade === "S"
                        ? " de " + this.props.ocupacao.capacidademinas1
                        : ""}
                    </td>
                    <td className="text-center">
                      {this.props.ocupacao.minas2}
                      {this.props.ocupacao.exibirCapacidade === "S"
                        ? " de " + this.props.ocupacao.capacidademinas2
                        : ""}
                    </td>
                    <td className="text-center">
                      {this.props.ocupacao.country}
                      {this.props.ocupacao.exibirCapacidade === "S"
                        ? " de " + this.props.ocupacao.capacidadecountry
                        : ""}
                    </td>
                    <td className="text-center">
                      {this.props.ocupacao.nautico}
                      {this.props.ocupacao.exibirCapacidade === "S"
                        ? " de " + this.props.ocupacao.capacidadenautico
                        : ""}
                    </td>
                  </tr>
                  {/*                   <tr>
                    <td>Vagas ocupadas<br />nas Academias</td>
                    <td className="text-center">{this.props.ocupacao.saldoacademia1} de {this.props.ocupacao.capacidadeacademia1}</td>
                    <td className="text-center">{this.props.ocupacao.saldoacademia2} de {this.props.ocupacao.capacidadeacademia2}</td>
                    <td className="text-center">-</td>
                    <td className="text-center">{this.props.ocupacao.saldoacademia1nautico} de {this.props.ocupacao.capacidadeacademianautico}</td>
                  </tr> */}
                </tbody>
              </Table>
            </Card>
            {this.renderMensagem(this.props.home.popup, 0)}
            {/*     
            <Card className="bg-secondary shadow overflow-hidden">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0 text-center text-md-left">Destaques</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <div className="banner-carrossel">
                  {this.props.home.banners.length === 0 ? (
                    <h4 className="text-center py-4">
                      Nenhuma notícia até no momento.
                    </h4>
                  ) : (
                    <Carousel
                      activeIndex={this.state.activeIndex}
                      next={this.next}
                      previous={this.previous}
                    >
                      <CarouselIndicators
                        items={this.props.home.banners}
                        activeIndex={this.state.activeIndex}
                        onClickHandler={this.goToIndex}
                      />
                      {this.props.home.banners.map((item, key) => {
                        return (
                          <CarouselItem
                            key={key}
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                          >
                            <a href={item.src} target="_blank">
                              {" "}
                              <img
                                src={item.src}
                                title={item.tiutlo}
                                alt={item.descricao}
                              />
                            </a>
                          </CarouselItem>
                        );
                      })}
                      <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}
                      />
                    </Carousel>
                  )}
                </div>
              </CardBody>
            </Card>
            <Card className="bg-secondary shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0  text-center text-md-left">
                      Mensagens
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <a
                  href="https://storage.googleapis.com/site-minas-tenis-gcp/media/uysnxn0k/banner-123milhas.jpg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="mt-3 img-fluid"
                    alt="Banner-123-milhas"
                    src="https://storage.googleapis.com/site-minas-tenis-gcp/media/uysnxn0k/banner-123milhas.jpg"
                  ></img>
                </a>
              </CardBody>
            </Card>*/}
            {/* Campanha 123-Milhas 
            {this.state.link123Milhas !== "" ? (
              <a
                href={this.state.link123Milhas}
                target="_blank"
                rel="noopener noreferrer"
                className="banner-campanha"
                id="banner-campanha"
              >
                <img
                  className="mt-3 img-fluid"
                  alt="Banner-123-milhas"
                  src="https://storage.googleapis.com/site-minas-tenis-gcp/media/uysnxn0k/banner-123milhas.jpg"
                ></img>
              </a>
            ) : (
              ""
            )}*/}
            {this.props.home.parceiros !== undefined
              ? this.props.home.parceiros.map((item, index) => {
                  return (
                    <Card key={index} className="bg-secondary shadow mt-4">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col>
                            <h3
                              style={{ textTransform: "capitalize" }}
                              className="mb-0  text-center text-md-left"
                            >
                              {item.tipo}
                            </h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <img
                          className="img-fluid"
                          alt="comididades"
                          src={item.link}
                        ></img>
                      </CardBody>
                    </Card>
                  );
                })
              : ""}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { home: state.home, ocupacao: state.ocupacao };
};

export default withRouter(connect(mapStatetoProps)(Home));

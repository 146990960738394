import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import InputGroupValidation from "../../components/Utilities/InputGroupValidation";
import InputAddon from "../../components/Utilities/InputAddon";
import InputMask from 'react-input-mask';
import { showPassword, checkEmail, validarCPF, onlyNumbers, setValidInvalidInput, formatDate } from '../../logic/utilities/inputHelpers';
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroup, Row, NavLink, Col } from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";


class Registrar extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showPassword: false,
      email: '',
      senha: '',
      senha2: '',
      dataNasc: '',
      cpf: '',
      npf: '',
      unidade: 'minas',
      response: ''
    }
  }

  setProperty(key, value) { this.setState({ [key]: value }) };

  checkSenha(event, isMainSenha)
  {
    event.preventDefault();

    if(isMainSenha)
    {
      this.setState({ senha: event.target.value });
      setValidInvalidInput(document.getElementById('confirmaSenha'), (this.state.senha2 == event.target.value));
    }else{
      this.setState({ senha2: event.target.value });
      setValidInvalidInput(event.target, (this.state.senha == event.target.value));
    }
  }

  redirect() {
    this.props.history.push({
      pathname: '/auth/login'
    });
  }

  async submitForm(event) {

    event.preventDefault();
    var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/cadastro.php', 'f=' + this.state.unidade + '&c=' + onlyNumbers(this.state.cpf) + '&n=' + this.state.npf + '&d=' + this.state.dataNasc + '&e=' + this.state.email + '&s1=' + encodeURIComponent(this.state.senha) + '&s2=' + encodeURIComponent(this.state.senha2) + '&u&imei');

    if (data.status === 'ok') {
      this.setState({ dados: data });
      this.props.dialog(true, "Sucesso!", 'Usuario ' + data.nome + ' registrado com sucesso!', this.redirect);
    }
  }

  showHelp = () => {
    var imagem = require("assets/img/carteirinha.png");
    this.props.dialog(true, "Código do sócio - NPF", '<img alt="..." src="' + imagem + '" />');
  }
 
  render() {

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 ">
              <div className="text-muted text-center pt-3 pb-4 font-bree-regular">
                <big><i className="fas fa-id-card"></i> <b>Registrar</b></big>
              </div>
              <Form role="form" onSubmit={e => this.submitForm(e)}>
                <InputGroupValidation>
                <FormGroup>
                    <InputGroup className="justify-content-between">
                      <div className="text-muted text-left ml-0">Unidade:</div>
                      <div className="custom-control custom-radio">
                        <input className="custom-control-input" defaultChecked id="customRadioMinas" name="customRadio" type="radio" onClick={e => this.setState({ unidade: 'minas' })}/>
                        <label className="custom-control-label" htmlFor="customRadioMinas">Minas</label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input className="custom-control-input" id="customRadioNautico" name="customRadio" type="radio" onClick={e => this.setState({ unidade: 'nautico' })}/>
                        <label className="custom-control-label" htmlFor="customRadioNautico">Náutico</label>
                      </div>
                    </InputGroup>
                  </FormGroup> 

                  <InputAddon id="cpf" mask="999.999.999-99" validate={true} placeholder="CPF" type="text" value={this.state.cpf} setValue={(value) => this.setProperty('cpf', value)} leftAddon="fas fa-id-card" tipError="CPF inválido" checkValue={validarCPF} />
                  
                  <InputAddon id="codSocio" mask="999999" value={this.state.npf} validate={true} placeholder="Código do sócio (NPF)" type="text" setValue={(value) => this.setProperty('npf', value)} leftAddon="fas fa-id-badge" tipError="NPF inválido" rightAddon="fas fa-question-circle" rightAddonAction={this.showHelp} />

                  <InputAddon id="dataNasc" mask="99/99/9999" value={this.state.dataNasc} validate={true} placeholder="Data de Nascimento" type="text" setValue={(value) => this.setProperty('dataNasc', value)}leftAddon="fas fa-calendar-alt" tipError="Data inválida"/>

                  <InputAddon id="email" validate={true} placeholder="Email" type="email" value={this.state.email} setValue={(value) => this.setProperty('email', value)} leftAddon="fas fa-envelope" tipError="E-mail inválido" />

                  <InputAddon id="senha1" validate={true} placeholder="Senha" type="password" value={this.state.senha} setValue={(value) => this.setProperty('senha', value)} leftAddon="fas fa-unlock-alt" rightAddon="fas fa-eye" rightTip="Mostar/Ocultar senha" /> 
                  
                  <InputAddon id="senha2" validate={true} placeholder="Confirme a senha" type="password" value={this.state.senha2} setValue={(value) => this.setProperty('senha2', value)} leftAddon="fas fa-unlock-alt" rightAddon="fas fa-eye" rightTip="Mostar/Ocultar senha" /> 


                  
                  </InputGroupValidation>
                  <div className="text-center">
                    <Button id="buttonId" type="submit" className="my-2" color="minas-blue-dark" >
                      Registrar
                    </Button>
                  </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-1">
            <Col xs="6">
              <NavLink className="text-light text-minas-blue-light" to="/auth/recuperar-senha" tag={Link}>
              <i className="fas fa-question-circle"></i> <small>Esqueceu a senha?</small>
              </NavLink>
            </Col>
            <Col xs="6" className="text-right">
              <NavLink className="text-light text-minas-blue-light" to="/auth/login" tag={Link}>
              <i className="fas fa-door-open"></i> <small>Login</small>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default withRouter(Registrar);

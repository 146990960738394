const initialState = {
  todos: [],
}

export default function meusCursos(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURSOS':
      return {
        ...state, 
        todos: action.mod
      }
    case 'CLEAR_CURSOS':
      return {
        ...state,
        todos: []
      }
    default:
      return state
  }
};

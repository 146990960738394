import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert, Form, FormGroup } from "reactstrap";
import InputAddon from "../Utilities/InputAddon";
import InputGroupValidation from "../Utilities/InputGroupValidation";

class ModalSorteioSorteioCancelamento extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      termos: false
    }
  }

  inputDataUpdate = (name, value) => {
    this.setState({ [name]: value });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.show} className="modal-dialog-centered" size="md" toggle={this.props.close()}>
          <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1 }} data-dismiss="modal" type="button" onClick={this.props.close()}>
          <span aria-hidden={true}>×</span>
        </button>
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-center">
                <span className="text-muted text-center d-block">
                    Cancelar inscrição para:<br/>{this.props.titulo}
                </span>
              </div>
            </CardHeader>
              <CardBody className="px-lg-4 pb-lg-3 d-block text-center my-0 pt-0" style={{ margin: '2rem' }}>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <InputGroupValidation>
                <Row className="my-4 text-center">
                  <h6 className="m-auto mb-3">Termo de cancelamento</h6>
                  <p>{this.props.termos}</p>
                </Row>
                <Row>
                  <FormGroup className="mb-0">
                    <InputAddon preValidade={false} label='Concordo com os Termos de Cancelamento do sorteio' outClass="input-group" id="input-termos" validate={true} type="checkbox" value={this.state.termos} setValue={(value) => this.inputDataUpdate('termos', value)} tipError="Termos de Sorteios não aceito." />
                  </FormGroup>
                </Row>
              <Row className="my-4 text-center">
                <Col className="text-center">
                    <Button color="primary" id="button1" type="button" onClick={this.props.close()}>
                    Voltar
              </Button>
                    <Button color="success" id="button2" type="submit" onClick={this.props.cancelarInscricao()}>
                          Confirmar cancelamento
              </Button>
                </Col>
              </Row>
                  </InputGroupValidation>
                  </Form>
            </CardBody>
          </Card>
        </div>
        </Modal>
        </>
      );
  }
}

export default ModalSorteioSorteioCancelamento;

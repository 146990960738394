import React from "react";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";

class Calendario extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      calendarioSrc: null
    };
  }

   componentDidMount() {
     this.getCalendario(this.props.usuario.npf);
  }

  async getCalendario()
  {
    var data = await this.props.requisition(true, false, 'https://socio.minastc.com.br/ws/link_calendario_educacao.php', 'key=97882219848c23186ba37696ba8f8620');

    if (data.res.status === 'ok') {
      this.setState({ calendarioSrc: data.res.link})
    }
  }

  render() {

    return (
      <>
        <Row>
          <Col>
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col >
                    <h3 className="mb-0 text-center">Calendário</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-3">
                {(this.state.calendarioSrc) ? <a href={this.state.calendarioSrc} target="_blank" className="card-calendario"><img src={this.state.calendarioSrc} /></a> : '' }
              </CardBody>
            </Card>
          </Col>
        </Row>
        </>
    );
  }
}

export default Calendario;
import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert } from "reactstrap";
import ModalSorteioCancelamento from "./SorteioCancelamento";
import ModalSorteioInscricao from './SorteioInscricao';

class ModalSorteio extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showTermosCancelamento: false,
      showTermos: false,
      showInscricao: false
    }
  }

  decode64(value)
  {
    var decodedString = atob(value);
    return decodedString;
  }

  closeModalCancelamento = () =>
  {
    this.setState({ showTermosCancelamento: false });
  }

  openModalCancelamento() {
    this.setState({ showTermosCancelamento: true });
  }

  closeModalInscricao = () => {
    this.setState({ showInscricao: false });
  }

  openModalInscricao() {
    this.setState({ showInscricao: true });
  }

  render() {

    return (
      <>
        <ModalSorteioCancelamento show={this.state.showTermosCancelamento} cancelarInscricao={() => this.props.cancelarInscricao} close={() => this.closeModalCancelamento} termos={this.props.sorteioSelecionado.TermosCancelamento} />
        <ModalSorteioInscricao termos={this.decode64(this.props.sorteioSelecionado.Termos)} show={this.state.showInscricao} close={() => this.closeModalInscricao} titulo={this.props.sorteioSelecionado.DescricaoEvento} inscricaoSorteio={this.props.inscricaoSorteio} maxIngressos={/* this.props.sorteioSelecionado.QuantidadeMaximaIngressosParaEsseSocio*/ 1} minIngressos={1}/>
        <Modal isOpen={this.props.show}  className="modal-dialog-centered"  size="lg" toggle={() => this.props.close()}>
        <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1}} data-dismiss="modal" type="button" onClick={()=>this.props.close()}>
          <span aria-hidden={true}>×</span>
        </button>
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-center">
                <span className="text-muted text-center d-block">
                    {this.props.sorteioSelecionado.DescricaoEvento}
                </span>
              </div>
            </CardHeader>
            <CardBody className="px-lg-4 pb-lg-3 d-block text-center" style={{ margin: '0 2rem'}}>
              <Row>
                  <Col lg="6">
                    {(this.props.sorteioSelecionado.DisponivelParaInscricao === 'não') ?
                      <Alert color='warning' className="d-inline-block py-1 mb-3">
                        <span className="alert-inner--icon">
                          <small><i className="fas fa-exclamation-circle"></i></small>
                        </span>{" "}
                        <span className="alert-inner--text">
                          Evento indisponível para a inscrição.
                        </span>
                      </Alert>
                      :
                      <Alert color='success' className="d-inline-block py-1 mb-3">
                        <span className="alert-inner--icon">
                          <small><i className="fas fa-check-circle"></i></small>
                        </span>{" "}
                        <span className="alert-inner--text">
                          Evento disponível para a inscrição.
                        </span>
                      </Alert>
                    }
                 </Col>
                  <Col lg="6">
                    {(this.props.sorteioSelecionado.Status.indexOf('não') > 0) ?
                      <Alert color='warning' className="d-inline-block py-1 mb-3">
                        <span className="alert-inner--icon">
                          <small><i className="fas fa-exclamation-circle"></i></small>
                        </span>{" "}
                        <span className="alert-inner--text">
                          {this.props.sorteioSelecionado.Status}
                        </span>
                      </Alert>
                      :
                      <Alert color='warning' className="d-inline-block py-1 mb-3">
                        <span className="alert-inner--icon">
                          <small><i className="fas fa-check-circle"></i></small>
                        </span>{" "}
                        <span className="alert-inner--text">
                          {this.props.sorteioSelecionado.Status}
                        </span>
                      </Alert>
                    }
                 </Col>
                </Row>
                <Row className="text-center">
                  <Col lg="6" className="m-auto">
                    <div className="fake-input">
                      <div className="fake-input__header">
                        <span>
                          Data do sorteio:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="fas fa-money-bill text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.DataSorteio} às {this.props.sorteioSelecionado.HorasSorteio}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="text-center">               
                  <Col lg="5" className="ml-auto">
                    <div className="fake-input">
                      <div className="fake-input__header">
                        <span>
                          Início das Inscrições:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="fas fa-money-bill text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.DataInicioInscricao}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="5" className="mr-auto">
                    <div className="fake-input">
                      <div className="fake-input__header">
                        <span>
                          Fim das Inscrições:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="fas fa-money-bill text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.DataFimInscricao}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr className="my-2" />
                <Row>
                  <Col>
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Local:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-calendar-alt text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.LocalEvento}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="fake-input">
                      <div className="fake-input__header">
                        <span>
                          Data e Hora do Evento:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="fas fa-money-bill text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.DataInicio} às {this.props.sorteioSelecionado.HoraInicio}
                        </span>
                      </div>
                    </div>
                  </Col>
                <Col lg="6">
                  <div className="fake-input ">
                    <div className="fake-input__header">
                      <span>
                        Unidade:
                      </span>
                    </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                      <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                      <span>
                          {this.props.sorteioSelecionado.Unidade}
                        </span>
                    </div>
                  </div>
                </Col>
                </Row>
                <hr className="my-2" />
             <Row>
                <Col lg="6">
                  <div className="fake-input ">
                  <div className="fake-input__header">
                      <span>
                          Sobre a compra:
                      </span>
                    </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                      <i className="fas fa-university text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.ObservacaoCompra}
                        </span>
                    </div>
                  </div>
                  </Col>
                  <Col lg="6">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Disponíbiliade do evento:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.TextoTotal}
                        </span>
                      </div>
                    </div>
                  </Col>
              </Row>
                <Row>
                <Col>
                  <div className="fake-input ">
                    <div className="fake-input__header">
                      <span>
                        Observações da compra:
                      </span>
                    </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                      <i className="fas fa-university text-muted ml--2 mr-2"></i>
                      <span>
                          {(this.props.sorteioSelecionado.ObservacaoEventoRelacionado) ? this.props.sorteioSelecionado.ObservacaoEventoRelacionado : '-'}
                        </span>
                    </div>
                  </div>
                </Col>
                </Row>
                <hr className="mb-2 mt-4" />
                <Row>
                  <Col lg="6">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Disponíbiliade para o associado:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.QuantidadeMaximaIngressosParaEsseSocio} ingressos
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Adquirido pelo o associado:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.sorteioSelecionado.TotalSocio}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Observações sobre a cota de ingresso:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                        <span>
                          {(this.props.sorteioSelecionado.ObservacaoIngressoCota) ? this.props.sorteioSelecionado.ObservacaoIngressoCota : '-'}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              <Row className="my-4 text-center">
                  <Col className="text-center">
                    <Button color="primary" type="button" onClick={() => this.props.close()}>
                      Fechar
                    </Button>
                    {(this.props.sorteioSelecionado.Status.indexOf('não') > 0) ?
                      <Button color="warning" type="button" disabled>
                        Cancelar Inscrição
                    </Button>
                      :
                      <Button color="warning" type="button" onClick={() => this.openModalCancelamento()}>
                        Cancelar Inscrição
                    </Button>
                  }
                    {(this.props.sorteioSelecionado.DisponivelParaInscricao === 'não') ?
                      <Button color="success" type="button" disabled>
                        Inscrever
                    </Button>
                      :
                      <Button color="success" type="button" onClick={() => this.openModalInscricao()}>
                        Inscrever
                    </Button>
                    }
                  </Col>            
              </Row>
            </CardBody>
          </Card>
        </div>
        </Modal>
        </>
      );
  }
}

export default ModalSorteio;

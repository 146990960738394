import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addFaturas } from "../../logic/actions";
import ModalFatura from "../../components/Modals/Fatura";
import CustomPagination from "../../components/Utilities/Pagination";
import { Card, CardHeader, CardFooter, Table, Row } from "reactstrap";
import moment from "moment";

class Faturas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itensPerPage: 4,
      numPages: 0,
      page: 0,
      faturaSelecionada: {},
    };
  }

  async componentDidMount() {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/faturas.php",
      "f=" + this.props.usuario.unidade + "&t=" + this.props.usuario.token
    );

    if (data.status === "ok") {
      this.setFaturas(data);
    }
  }

  setFaturas(faturas) {
    this.props.dispatch(addFaturas(faturas));

    if (faturas.total_pagas > this.state.itensPerPage) {
      var paginas = faturas.total_pagas / this.state.itensPerPage;

      if (faturas.total_pagas % this.state.itensPerPage > 0) {
        paginas = paginas + 1;
      }

      this.setState({ numPages: paginas });
    }
  }

  showModal = async (event, numeroFatura) => {
    event.preventDefault();
    var faturaIndex = -1;
    var faturasFitradas = this.props.faturas.todas.filter(function (
      val,
      index
    ) {
      if (val.numero === numeroFatura) {
        faturaIndex = index;
        return true;
      } else {
        return false;
      }
    });

    if (faturasFitradas && faturaIndex > -1) {
      var data = await this.props.requisition(
        true,
        true,
        "https://socio.minastc.com.br/ws/extrato.php",
        "f=" +
          this.props.usuario.unidade +
          "&t=" +
          this.props.usuario.token +
          "&fatura=" +
          numeroFatura
      );

      if (data.status === "ok") {
        faturasFitradas[0].detalhes = data;
      } else {
        faturasFitradas[0].detalhes = null;
      }

      await this.setState({ faturaSelecionada: faturasFitradas[0] });
      this.setState({ showModal: true });
    } else {
      faturasFitradas[0].detalhes = null;
      await this.setState({ faturaSelecionada: faturasFitradas[0] });
      this.setState({ showModal: true });
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  setPage = (pageNum) => {
    this.setState({ page: pageNum });
  };

  render() {
    var start = this.state.page * this.state.itensPerPage;
    var end = start + this.state.itensPerPage;
    var pagas = this.props.faturas.pagas.slice(start, end);

    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 d-inline-block">Faturas em aberto</h3>
                <h3 className="mb-0 d-inline-block ml-auto">
                  <span className="font-weight-light">Total: </span>{" "}
                  {this.props.faturas.total_faturas}
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Número</th>
                    <th scope="col" className="text-center">
                      Data de Vencimento
                    </th>
                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      Detalhes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.faturas.total_n_pagas === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="4">
                        Não foi encontrada nenhuma fatura em aberto.
                      </td>
                    </tr>
                  ) : (
                    this.props.faturas.em_aberto.map((item) => {
                      var dataVen = moment(
                        new Date(
                          item.data_vencimento.split("/")[2],
                          parseInt(item.data_vencimento.split("/")[1]) - 1,
                          item.data_vencimento.split("/")[0]
                        )
                      );

                      var today = moment(new Date());

                      var vencido = !moment(dataVen).isAfter(today);

                      return (
                        <tr key={item.numero}>
                          <td>{item.numero}</td>
                          <td className="text-center">
                            {item.data_vencimento}
                          </td>
                          <td className="text-center">
                            <i className="text-danger fas fa-exclamation-circle mr-2"></i>
                            {vencido ? "Vencido" : "A pagar"}
                          </td>
                          <td className="text-center">
                            <a
                              href="#pablo"
                              onClick={(e) => this.showModal(e, item.numero)}
                            >
                              <i className="fas fa-search"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {this.props.faturas.txt_em_aberto !== "" && (
                  <p className="text-center">
                    {this.props.faturas.txt_em_aberto}
                  </p>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>

        <Row>
          <div className="col mt-4">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 d-inline-block">Faturas pagas</h3>
                <h3 className="mb-0 d-inline-block ml-auto">
                  <span className="font-weight-light">Total: </span>{" "}
                  {this.props.faturas.total_pagas}
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Número</th>
                    <th scope="col" className="text-center">
                      Data de pagamento
                    </th>
                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      Detalhes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.faturas.total_pagas === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="4">
                        Não foi encontrada nenhuma fatura paga.
                      </td>
                    </tr>
                  ) : (
                    pagas.map((item) => {
                      return (
                        <tr key={item.numero}>
                          <td>{item.numero}</td>
                          <td className="text-center">{item.data_pagamento}</td>
                          <td className="text-center">
                            <i className="text-success fas fa-check-circle mr-2"></i>
                            Pago
                          </td>
                          <td className="text-center">
                            <a
                              href="#pablo"
                              onClick={(e) => this.showModal(e, item.numero)}
                            >
                              <i className="fas fa-search"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <CustomPagination
                  numPages={this.state.numPages}
                  changePage={this.setPage}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
        <ModalFatura
          faturaSelecionada={this.state.faturaSelecionada}
          show={this.state.showModal}
          close={() => this.closeModal}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { faturas: state.faturas };
};

export default withRouter(connect(mapStatetoProps)(Faturas));

import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import ModalIFrame from "./Iframe";

class ModalModalidades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalContent: "",
      modalFunction: null,
      modalidae: null
    };
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  continuarMatricula = () => {
    this.setState({ showModal: false });
    this.props.matricular(this.state.modalidade);
  }

  matricular = (modalidade) => {

    if (modalidade.inf_curso && modalidade.inf_curso != "") {
      this.setState({ modalidade: modalidade, modalContent: modalidade.inf_curso, showModal: true, modalFunction: this.continuarMatricula });
    } else {
      this.props.matricular(modalidade);
    }
  }

  getDisponibilidade(item) {

    if (item.idt_status === 'INSCRICAO_FILA_DISPONIVEL') {
       return <div className="fake-input__body bg-danger text-white text-center mr-0 ml-auto py-2 px-2 d-block">
        <i className="fas fa-exclamation-triangle mr-2" />
        <span>
          <strong>Atenção! Curso lotado.</strong> A sua inscrição irá para uma fila de espera. <br />
                Nº de sócios na fila de espera: <strong>{item.nro_fila} </strong>
        </span>
      </div>
    } else if (item.idt_status === 'MATRICULA_DISPONIVEL'){
      return <div className="fake-input__body bg-success text-white text-center mr-0 ml-auto py-2 px-2 d-block">
        <i className="fas fa-thumbs-up mr-2" />
        <span>
          Matrícula disponível.
        </span>
      </div>
  } else if (item.idt_status === 'MATRICULADO'){
        return <div className="fake-input__body bg-info text-white text-center mr-0 ml-auto py-2 px-2 d-block">
          <i className="fas fa-times mr-2" />
          <span>
            Matrículado.
          </span>
      </div>
    }else if (item.idt_status === 'INSCRITO_FILA'){
        return <div className="fake-input__body bg-info text-white text-center mr-0 ml-auto py-2 px-2 d-block">
          {/* <i className="fas fa-times mr-2" /> */}
          <span>
          Você já esta inscrito nesta fila de espera.
          </span>
      </div>
    }
  }


  render() {

    return (
      <>
        <ModalIFrame show={this.state.showModal} close={() => this.closeModal} funcao={this.state.modalFunction} code={this.state.modalContent} titulo="Observações do curso" />
        <Modal size="lg" isOpen={this.props.show} toggle={this.props.close()}>
          <div className="modal-body p-0">
            <Card className="bg-lighter shadow border-0 ">
              <CardHeader className="bg-white">
                <div className="text-center">
                  <span className="text-muted text-center d-block">
                    {this.props.modalidade}
                  </span>
                </div>
                <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1 }} data-dismiss="modal" type="button" onClick={this.props.close()}>
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>

              {this.props.turmas.map((item, index) => {
                return <div key={'card' + index}>
                  <CardHeader className="bg-white">
                    <Row>
                      <Col className="justify-content-center d-flex flex-column">
                        <span>
                          <b>{item.des_curso} - Turma: </b> {item.cod_turma}
                        </span>
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody className="pt-3 pb-5">
                    <Row>
                      <Col>{this.getDisponibilidade(item)}</Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <div className="fake-input">
                          <div className="fake-input__header">
                            <span>
                              Unidade:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              <i className="fas fa-school text-muted ml--2 mr-2"></i>
                              {item.des_unidade}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Dias da semana:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              <i className="far fa-calendar-alt text-muted ml--2 mr-2"></i>
                              {item.des_turma}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Horário:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              <i className="far fa-clock text-muted ml--2 mr-2"></i>
                              {item.horario}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Valor da matrícula:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              <i className="fas fa-donate text-muted ml--2 mr-2"></i>
                              {item.vlr_matricula}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Valor da mensalidade:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              <i className="fas fa-donate text-muted ml--2 mr-2"></i>
                              {item.vlr_mensalidade}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Faixa etária:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              {item.faixa_etaria} anos
                                    </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Exige atestado:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              {(item.exige_atestado === 'S') ?
                                <><i className="fas fa-check text-success ml--2 mr-2"></i><span>Sim </span></>
                                :
                                <><i className="fas fa-times text-danger ml--2 mr-2"></i><span>Não</span></>
                              }
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Exige Par-Q:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              {(item.exige_parq === 'S') ?
                                <><i className="fas fa-check text-success ml--2 mr-2"></i><span>Sim </span></>
                                :
                                <><i className="fas fa-times text-danger ml--2 mr-2"></i><span>Não</span></>
                              }
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Exige avaliação:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              {(item.exige_avaliacao === 'S') ?
                                <><i className="fas fa-check text-success ml--2 mr-2"></i><span>Sim </span></>
                                :
                                <><i className="fas fa-times text-danger ml--2 mr-2"></i><span>Não</span></>
                              }
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="fake-input ">
                          <div className="fake-input__header">
                            <span>
                              Exige primeira aula:
                                  </span>
                          </div>
                          <div className="fake-input__body bg-light">
                            <span>
                              {(item.exige_primeira_aula === 'S') ?
                                <><i className="fas fa-check text-success ml--2 mr-2"></i><span>Sim </span></>
                                :
                                <><i className="fas fa-times text-danger ml--2 mr-2"></i><span>Não</span></>
                              }
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {(item.matriculado === 'não') ?
                      <Row className="justify-content-md-center pt-2">
							{(item.idt_status === 'MATRICULA_DISPONIVEL') ?
									<Button color="info" onClick={() => this.matricular(item)}>
										Quero me matricular!
                              </Button>
									: null}
								
						{(item.idt_status === 'INSCRICAO_FILA_DISPONIVEL') ?
                          <Button color="info" onClick={() => this.matricular(item)}>
										Entrar na fila de espera
                              </Button>
							:null	
                        }
                      </Row>
                      :
                      ''
                    }
                  </CardBody>
                </div>
              })}
            </Card>
          </div>
        </Modal>
        </>
      );
  }
}

export default ModalModalidades;

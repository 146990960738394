import React from "react";
import { Modal, Button, CardBody, Card, CardHeader, CardFooter } from "reactstrap";

class ModalIFrame extends React.Component {
  render() {

    if (this.props.code !== null) {
      return (
        <Modal id="activeModal" className="modal-dialog-centered modal-iframe modal-info" size="xl" isOpen={this.props.show} toggle={this.props.close()} >
          <Card className="shadow">
            <CardHeader className="border-0 d-flex justify-content-between">
              <h3 className="mb-0 d-inline-block">
                {this.props.titulo}
              </h3>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.props.close()}>
                <span className="text-default" aria-hidden={true}>×</span>
              </button>
            </CardHeader>
            <CardBody className="p-3 overflow-hidden">
              <iframe className="card-iframe" srcDoc={this.props.code} title={this.props.titulo}></iframe>
            </CardBody>
            {(this.props.funcao) ?
              <CardFooter className="p-3 d-flex justify-content-around">
                <Button color="danger" onClick={this.props.close()}>
                  Voltar
              </Button>
                <Button color="succes" onClick={() => this.props.funcao()}>
                  Continuar
              </Button>
              </CardFooter>
              : null}
          </Card>
        </Modal>
      );
    } else {
      return '';
    }
  }
}

export default ModalIFrame;

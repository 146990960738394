import React from "react";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";

class Satisfacao extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      code: ''
    };
  }

  render() {

    return (
      <>
        <Row>
          <Col>
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col >
                    <h3 className="mb-0 text-center">Pesquisas de Satisfação</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <form method='post' id='form' name='form' action='https://socio.minastc.com.br/p_pesquisas.php' encType='multipart/form-data' target='myIFrame'>
                  <input type='hidden' id='token' name='token' value={this.props.usuario.token} />
                </form>
                <iframe className="iframe-alt" width='100%' height='auto' id='myIFrame' title='myIFrame' name='myIFrame' src={'https://socio.minastc.com.br/p_pesquisas.php?t=' + this.props.usuario.token} ></iframe>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </>
    );
  }
}

export default Satisfacao;
import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert, FormGroup, InputGroup } from "reactstrap";
import SelecionarUsuario from "./SelecionarUsuario";
import Ingresso from '../Utilities/Ingresso';
import ModalEventoFinalizarCompra from './EventoFinalizarCompra';
import ModalEventoImprimirIngresso from './EventoImprimirIngresso';
import moment from "moment";

class ModalEventoComprar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ingressos: [],
      ids: 0,
      ingressosResponse: [],
      showFinalizarCompra: false,
      showImprimirIngreasso: false
    }
  }

  componentDidMount() {
    this.adicionarIngresso();
  }

  adicionarIngresso = () =>
  {
    var updateIngresso = this.state.ingressos;
    updateIngresso.push({ id: this.state.ids });
    this.setState({ ingressos: updateIngresso });
    this.setState({ ids: this.state.ids + 1 });
  }

  removerIngresso = (ingressoID) => {
    var updateIngresso = this.state.ingressos;
    
    if (updateIngresso.length > 1)
    {
        updateIngresso = updateIngresso.filter(item => item.id !== ingressoID);
        this.setState({ ingressos: updateIngresso });
    }    
  }

  updateIngresso = (ingressoID, property, value) => {
    var updateIngresso = this.state.ingressos;
    var ingresso = updateIngresso.find(item => item.id === ingressoID);
    ingresso[property] = value;
    this.setState({ ingressos: updateIngresso });
  }

  finalizarCompra = () =>
  {
    var ingressosResponse = [];

    this.state.ingressos.map(item => {
      var usuario = {};
      
      if (item.usuarioConvidado === true)
      {
        usuario['cod_tipo_ingresso'] = item.tipoConvite;
        usuario['cod_associado'] = '';
        usuario['nome'] = item.nome;
        usuario['identidade'] = item.identidade;
        usuario['idade'] = item.idade;

        ingressosResponse.push(usuario);
      } else {
        usuario['cod_tipo_ingresso'] = item.tipoConvite;
        usuario['cod_associado'] = item.npf;

        ingressosResponse.push(usuario);
      }
    });

    return ingressosResponse;
  }

  confirmarCompra = async () => {
    
    var dataBody = [];
    dataBody['t'] = this.props.usuario.token;
    dataBody['n'] = this.props.eventoSelecionado.numeroSeqEvento;
    dataBody['i'] = this.finalizarCompra();

    //var data = await this.props.requisition(false, true, 'https://socio.minastc.com.br/ws/listar_tipo_ingressos.php', '', '', dataBody);
    var data = {
      "element": [
        {
          "dat_inic_evento": "2019-10-06-03:00",
          "hor_inic_evento": null,
          "cod_tipo_ingresso": "SOCIO",
          "des_ingresso": "CAMAR",
          "des_tipo_ingresso": "SÓCIO",
          "nro_ingresso": 1,
          "des_evento": "SHOW ANIVERSÁRIO DO MINAS - CAMAROTE",
          "cod_projeto": "ANIVERSARI",
          "cod_grupo_evento": "ANIVERSARI",
          "des_data": "06 de outubro de 2019",
          "des_hora": "12:00h",
          "des_data_hora": "06 de outubro de 2019 - 12:00h",
          "des_dia_semana": "DOMINGO",
          "nom_local": "CAMAROTE - MINAS NÁUTICO",
          "vlr_ingresso": "180.00",
          "des_unidade": "MINAS NÁUTICO",
          "nom_associado_comp": "USUARIO MINAS SD",
          "nom_espectador": "USUARIO MINAS CD",
          "nom_associado_expe": null,
          "dat_ingresso": "2019-10-01-03:00",
          "nro_seq_evento": 152112,
          "cod_barras": "4512C",
          "des_evento_1": "SHOW ANIVERSÁ",
          "nom_associado_expe_1": "USUARIO MINAS",
          "des_mesa": null,
          "des_cadeira": null,
          "des_identidade": "",
          "npf_associado_comp": "695061",
          "des_texto_1": "NÃO FAÇA CÓPIAS DESTE INGRESSO.",
          "des_texto_2": "SOMENTE O PRIMEIRO INGRESSO A  ",
          "des_texto_3": "PASSAR NOS NOSSOS LEITORES TERÁ",
          "des_texto_4": "ACESSO AO EVENTO.",
          "des_texto_5": "INGRESSO VALIDO SOMENTE COM A",
          "des_texto_6": "APRESENTAÇÃO DA CARTEIRA SOCIAL",
          "des_texto_7": "E EXCLUSIVO PARA SÓCIOS.",
          "des_texto_8": null,
          "des_texto_9": "CANCELAMENTOS PODEM SER FEITOS",
          "des_texto_10": "ATÉ 96 HORAS ANTES DO EVENTO.",
          "des_texto_11": null,
          "des_texto_12": "CLASSIFICAÇÃO ETÁRIA 18 ANOS.",
          "des_texto_13": null,
          "des_internet": "APP",
          "des_vlr_ingresso": "R$ 180,00",
          "nom_clube": "MINAS TÊNIS CLUBE",
          "des_cod_barras": "4512C",
          "des_sexo": null,
          "hor_dat_aux": "12:00"
        },
        {
          "dat_inic_evento": "2019-10-06-03:00",
          "hor_inic_evento": null,
          "cod_tipo_ingresso": "SOCIO",
          "des_ingresso": "CAMAR",
          "des_tipo_ingresso": "SÓCIO",
          "nro_ingresso": 2,
          "des_evento": "SHOW ANIVERSÁRIO DO MINAS - CAMAROTE",
          "cod_projeto": "ANIVERSARI",
          "cod_grupo_evento": "ANIVERSARI",
          "des_data": "06 de outubro de 2019",
          "des_hora": "12:00h",
          "des_data_hora": "06 de outubro de 2019 - 12:00h",
          "des_dia_semana": "DOMINGO",
          "nom_local": "CAMAROTE - MINAS NÁUTICO",
          "vlr_ingresso": "180.00",
          "des_unidade": "MINAS NÁUTICO",
          "nom_associado_comp": "USUARIO MINAS SD",
          "nom_espectador": "LUCAS FERNANDO DE C PEREIRA",
          "nom_associado_expe": null,
          "dat_ingresso": "2019-10-01-03:00",
          "nro_seq_evento": 152112,
          "cod_barras": "8200A",
          "des_evento_1": "SHOW ANIVERSÁ",
          "nom_associado_expe_1": "LUCAS FERNAND",
          "des_mesa": null,
          "des_cadeira": null,
          "des_identidade": "",
          "npf_associado_comp": "695061",
          "des_texto_1": "NÃO FAÇA CÓPIAS DESTE INGRESSO.",
          "des_texto_2": "SOMENTE O PRIMEIRO INGRESSO A  ",
          "des_texto_3": "PASSAR NOS NOSSOS LEITORES TERÁ",
          "des_texto_4": "ACESSO AO EVENTO.",
          "des_texto_5": "INGRESSO VALIDO SOMENTE COM A",
          "des_texto_6": "APRESENTAÇÃO DA CARTEIRA SOCIAL",
          "des_texto_7": "E EXCLUSIVO PARA SÓCIOS.",
          "des_texto_8": null,
          "des_texto_9": "CANCELAMENTOS PODEM SER FEITOS",
          "des_texto_10": "ATÉ 96 HORAS ANTES DO EVENTO.",
          "des_texto_11": null,
          "des_texto_12": "CLASSIFICAÇÃO ETÁRIA 18 ANOS.",
          "des_texto_13": null,
          "des_internet": "APP",
          "des_vlr_ingresso": "R$ 180,00",
          "nom_clube": "MINAS TÊNIS CLUBE",
          "des_cod_barras": "8200A",
          "des_sexo": null,
          "hor_dat_aux": "12:00"
        }
      ]
    };


    if (!data.status || data.status !== 'erro') {
      var ingressos = [];
      data.element.map((value) => {
        ingressos.push(value);
      });
      this.setState({ ingressosResponse: ingressos });

      this.openModalImprimir();
      this.closeModaFinalizar();
      this.props.close();
      
    }

  }

  closeModaFinalizar = () => {
    this.setState({ showFinalizarCompra: false });
  }

  openModalFinalizar() {
    this.setState({ showFinalizarCompra: true });
  }

  closeModalImprimir = () => {
    this.setState({ showImprimirIngreasso: false });
  }

  openModalImprimir() {
    this.setState({ showImprimirIngreasso: true });
  }


  render() {

    return (
      <>
       {(this.state.ingressosResponse !== null) ?
          <ModalEventoImprimirIngresso show={this.state.showImprimirIngreasso} close={this.closeModalImprimir} ingressos={this.state.ingressosResponse} />
          : '' } 
        {(this.state.ingressos.length > 0) ?
          <ModalEventoFinalizarCompra show={this.state.showFinalizarCompra} eventoSelecionado={this.props.eventoSelecionado} close={this.closeModaFinalizar} detalhesEvento={this.props.detalhesEvento} ingressos={this.state.ingressos}  confirmarCompra={this.confirmarCompra} />
          : ''}
        <Modal isOpen={this.props.show} className="modal-dialog-centered" size='lg'>
        <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1}} data-dismiss="modal" type="button" onClick={()=>this.props.close()}>
          <span aria-hidden={true}>×</span>
        </button>
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-center">
                  <span className="text-muted text-center d-block">
                    Selecione os ingressos
                </span>
              </div>
              </CardHeader>
              <CardBody className="px-lg-4 pb-lg-3 d-block text-center" style={{ margin: '0 2rem' }}>
                {this.state.ingressos.map(item => (
                  <Ingresso key={item.id} id={item.id} remover={this.removerIngresso} update={this.updateIngresso} {...this.props}></Ingresso>
                ))
                }
                <Row className="mb-5 text-center">
                  <Col xl="11" className="text-center p-0">
                    <Button color="primary" type="button" className="text-md w-100" onClick={() => this.adicionarIngresso()}>
                      <i className="fas fa-plus"></i> Adicionar Ingresso  
                    </Button>
                  </Col>
                </Row>
                  <Row className="my-4 text-center">
                    <Col className="text-center">
                      <Button color="danger" type="button" onClick={() => this.props.close()}>
                          Cancelar
                      </Button>
                    <Button color="success" type="button" onClick={() => this.openModalFinalizar()}>
                          Finalizar
                      </Button>
                    </Col>            
                      </Row>
            </CardBody>
          </Card>
        </div>
        </Modal>
        </>
      );
  }
}

export default ModalEventoComprar;

import React from "react";
import { Container} from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header minas-gradient-bg-esportes-blue-light pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;

import React from "react";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";

class Bioprotecao extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      code: ''
    };
  }

  render() {

    return (
      <>
        <Row>
          <Col>
            <iframe className="iframe shadow" width='100%' height='auto' id='myIFrame' title='myIFrame' name='myIFrame' src='https://socio.minastc.com.br/protocolo_bioprotecao.pdf'></iframe>          
          </Col>
        </Row>
        </>
    );
  }
}

export default Bioprotecao;
export const clearTurmas = () => ({
    type: 'CLEAR_TURMAS'
  });

export const addTurmas = turmas => ({
    type: 'SET_TURMAS',
    turmas
});

export const updateTurmas = turmas => ({
  type: 'ADD_TURMAS',
  turmas
});
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import RequestDialog from "components/Utilities/RequestDialog";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
import ErrorBoundary from "components/Utilities/Error";
import { isAndroid } from "react-device-detect";
import routes from "routes.js";

class Auth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAPP: true,
    };
  }

  componentDidMount() {
    this.checkLogin();
    document.body.classList.add("bg-minas-blue-dark");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-minas-blue-dark");
  }

  checkLogin() {
    if (this.props.usuario && this.props.usuario.isLogged) {
      this.props.history.push({
        pathname: "/admin/home",
        state: this.props.usuario,
      });
    }
  }

  makeRequisition = async (
    showErrorDialog,
    isPost,
    requestUrl,
    requestParams,
    header,
    body
  ) => {
    var data = await this.requestRef.requisition(
      isPost,
      requestUrl,
      requestParams,
      header,
      body
    );

    if (data.status !== "ok" && showErrorDialog) {
      this.requestRef.showError("Erro!", data.error);
    }

    return data;
  };

  showDialog = (isSuccess, title, text, callback) => {
    if (isSuccess) {
      this.requestRef.showSuccess(title, text, callback);
    } else {
      this.requestRef.showError(title, text, callback);
    }
  };

  getRoutes = (routes) => {
    return routes.map(({ component: CustomComponent, ...prop }, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (
              <CustomComponent
                requisition={this.makeRequisition}
                dialog={this.showDialog}
                {...this.props}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <ErrorBoundary>
        <RequestDialog
          wrappedComponentRef={(ref) => (this.requestRef = ref)}
        ></RequestDialog>
        <div className="header-auth">
          <div className="header minas-gradient-bg-esportes-blue-light pt-5">
            <div className="separator separator-bottom separator-xl-top separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 2560 839.3"
                preserveAspectRatio="xMinYMin meet"
              >
                <g>
                  <path
                    className="fill-minas-blue-dark"
                    d="M2386.5,308.7c-214,134.6-502.5,191.3-699,260.2s-354.6,202.8-369.9,236c-15.3,33.2-37,34.4-37,34.4H2560V0
		C2560,45.9,2544.7,209.2,2386.5,308.7z"
                  />
                  <path
                    className="fill-minas-blue-dark"
                    d="M1243.7,804.8c-15.3-33.2-173.5-167.1-369.9-236c-196.4-68.9-484.9-125.6-699-260.2C16.6,209.2,1.3,45.9,1.3,0v839.3
		h1279.3C1280.7,839.3,1259,838,1243.7,804.8z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div className="main-content">
            <AuthNavbar />
            <Container>
              <Row className="justify-content-center mb-4">
                <Col xs="6" md="4" lg="3">
                  <img
                    alt="..."
                    src={require("assets/img/brand/Minas-Logo-Branca.svg")}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center" lang="pt-BR">
                <Switch>{this.getRoutes(routes)}</Switch>
              </Row>
            </Container>
            <AuthFooter />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default Auth;

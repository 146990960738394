const initialState = {
  protocolos: [],
  tiposOperacoes: [],
  docsByTpo: [],
  link: "",
};

export default function novoProtocolo(state = initialState, action) {
  switch (action.type) {
    case "ADD_NOVO_PROTOCOLO":
      return {
        ...state,
      };
    case "SET_PROTOCOLOS_ASSOCIADO":
      return {
        ...state,
        protocolos: action.protocolosAssociado,
      };
    case "SET_DOCS_BY_TPO":
      return {
        ...state,
        docsByTpo: action.docsByTpo,
      };
    case "SET_TIPOS_OPERACAO":
      return {
        ...state,
        tiposOperacoes: action.tiposOperacao,
      };
    case "CLEAR_DOCS_BY_TPO":
      return {
        ...state,
        docsByTpo: [],
      };
    case "CLEAR_PROTOCOLOS":
      return {
        ...state,
        protocolos: [],
        tiposOperacoes: [],
      };
    default:
      return state;
  }
}

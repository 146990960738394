import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Row, Col } from "reactstrap";

class SelecionarUsuario extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usuario: this.props.usuarioDados.nome,
            npf: this.props.usuarioDados.npf
        };
    }

    selectUsuario(npf, nome) {
        this.setState({ npf: npf });
        this.setState({ usuario: nome });
        this.props.changeUsuario(npf, nome);
    }

    render() {

        var myStyle = '';
        if (this.props.styleAlternative === true) {
            myStyle = 'alt-style';
        }
        return (<>
            <Row className="mb-3">
                <Col className="d-flex">
                    <div className={"btn btn-white dropdown-selected flex-1 " + myStyle}>
                            {this.state.npf} - {this.state.usuario}
                    </div>
                        <UncontrolledDropdown >
                            <DropdownToggle caret className={"m-0 btn-white " + myStyle}>
                                Selecionar Associado
                        </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>Titular</DropdownItem>
                                <DropdownItem onClick={() => this.selectUsuario(this.props.usuarioDados.npf, this.props.usuarioDados.nome)} >{this.props.usuarioDados.npf} - {this.props.usuarioDados.nome}</DropdownItem>
                                <DropdownItem header>Dependentes</DropdownItem>
                                {
                                    this.props.usuarioDados.dependentes.map((item, key) => {
                                        return <DropdownItem key={key} onClick={() => this.selectUsuario(item.npf, item.nome)}>{item.npf} - {item.nome}</DropdownItem>
                                    })
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                </Col>
            </Row>
            </>
        );
    }
}

export default SelecionarUsuario;

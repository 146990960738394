const initialState = {
  nome: "",
  npf: "",
  foto_url: "",
  foto: "",
  dat_validade_cart: "",
  dat_validade_foto: "",
  titular: "",
  data_alteracao: "",
  cota: "",
  email: "",
  cpf: "",
  telefone_celular: "",
  telefone_fixo: "",
  cod_parentesco: "",
  dat_nascimento: "",
  enderecos: {
    cobranca: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      pais: "",
    },
    correspondencia: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      pais: "",
    },
  },
  dependentes: [],
};

export default function usuarioDados(state = initialState, action) {
  switch (action.type) {
    case "ADD_USER_DATA":
      return {
        ...state,
        nome: action.user.nome,
        chave: action.user.chave,
        npf: action.user.npf,
        foto_url: action.user.foto_url,
        foto: action.user.foto,
        unidade: action.user.unidade,
        dat_validade_cart: action.user.dat_validade_cart,
        dat_validade_foto: action.user.dat_validade_foto,
        titular: action.user.titular,
        data_alteracao: action.user.data_alteracao,
        cota: action.user.cota,
        email: action.user.email,
        cpf: action.user.cpf,
        telefone_celular: action.user.telefone_celular,
        telefone_fixo: action.user.telefone_fixo,
        cod_parentesco: action.user.cod_parentesco,
        dat_nascimento: action.user.dat_nascimento,
        enderecos: {
          cobranca: action.user.enderecos.cobranca,
          correspondencia: action.user.enderecos.correspondencia,
        },
        dependentes:
          action.user.dependentes != null ? action.user.dependentes : [],
      };
    case "UPDATE_USER_BASIC_DATA":
      return {
        ...state,
        email: action.user.email != null ? action.user.email : state.email,
        telefone_celular:
          action.user.telefone_celular != null
            ? action.user.telefone_celular
            : state.telefone_celular,
        telefone_fixo:
          action.user.telefone_fixo != null
            ? action.user.telefone_fixo
            : state.telefone_fixo,
      };
    case "UPDATE_USER_DEPENDENTES":
      return {
        ...state,
        dependentes: action.dependentes != null ? action.dependentes : [],
      };
    case "UPDATE_USER_DATA_CORRESPONDENCIA":
      return {
        ...state,
        enderecos: {
          correspondencia: {
            cep:
              action.end.cep != null
                ? action.end.cep
                : state.enderecos.correspondencia.cep,
            logradouro:
              action.end.logradouro != null
                ? action.end.logradouro
                : state.enderecos.correspondencia.logradouro,
            numero:
              action.end.numero != null
                ? action.end.numero
                : state.enderecos.correspondencia.numero,
            complemento:
              action.end.complemento != null
                ? action.end.complemento
                : state.enderecos.correspondencia.complemento,
            bairro:
              action.end.bairro != null
                ? action.end.bairro
                : state.enderecos.correspondencia.bairro,
            cidade:
              action.end.cidade != null
                ? action.end.cidade
                : state.enderecos.correspondencia.cidade,
            uf:
              action.end.uf != null
                ? action.end.uf
                : state.enderecos.correspondencia.uf,
          },
          cobranca: state.enderecos.cobranca,
        },
      };
    case "UPDATE_USER_DATA_COBRANCA":
      return {
        ...state,
        enderecos: {
          cobranca: {
            cep:
              action.end.cep != null
                ? action.end.cep
                : state.enderecos.cobranca.cep,
            logradouro:
              action.end.logradouro != null
                ? action.end.logradouro
                : state.enderecos.cobranca.logradouro,
            numero:
              action.end.numero != null
                ? action.end.numero
                : state.enderecos.cobranca.numero,
            complemento:
              action.end.complemento != null
                ? action.end.complemento
                : state.enderecos.cobranca.complemento,
            bairro:
              action.end.bairro != null
                ? action.end.bairro
                : state.enderecos.cobranca.bairro,
            cidade:
              action.end.cidade != null
                ? action.end.cidade
                : state.enderecos.cobranca.cidade,
            uf:
              action.end.uf != null
                ? action.end.uf
                : state.enderecos.cobranca.uf,
          },
          correspondencia: state.enderecos.correspondencia,
        },
      };
    case "CLEAR_USER_DATA":
      return {
        ...state,
        nome: "",
        npf: "",
        foto_url: "",
        foto: "",
        dat_validade_cart: "",
        dat_validade_foto: "",
        titular: "",
        data_alteracao: "",
        cota: "",
        email: "",
        cpf: "",
        telefone_celular: "",
        telefone_fixo: "",
        cod_parentesco: "",
        dat_nascimento: "",
        enderecos: {
          cobranca: {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            uf: "",
            pais: "",
          },
          correspondencia: {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            uf: "",
            pais: "",
          },
        },
        dependentes: [],
      };
    default:
      return state;
  }
}

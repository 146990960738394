const initialState = {
  todas: [],
  disponiveis: [],
  filaEspera: []
}

export default function modalidades(state = initialState, action) {
  switch (action.type) {
    case 'SET_MODALIDADES':
      return {
        ...state, 
        todas: action.mod
      }
    case 'SET_MODALIDADES_DISPONIVEIS':
      return {
        ...state, 
        disponiveis: action.mod
      }
    case 'SET_MODALIDADES_FILA':
      return {
        ...state, 
        filaEspera: action.mod
      }
    case 'CLEAR_MODALIDADES':
      return {
        ...state,
        todas: [],
        disponiveis: [],
        filaEspera: []
      }
    default:
      return state
  }
};

import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class ModalNovaMatriculaEfetivar extends React.Component {

  drawAula() {
    if (this.props.novaMatricula.exige_primeira_aula === 'S') {
      return <Row>
        <Col md='12' className="text-center mt-4 mb--2">
          Primeira Aula
                  </Col>
        <Col md="6">
          <div className="fake-input ">
            <div className="fake-input__header">
              <span>
                Dia:
                </span>
            </div>
            <div className="fake-input__body bg-light">
              <span>
                <i className="far fa-calendar-alt text-muted ml--2 mr-2"></i>
                {this.props.novaMatricula.data_aula}
              </span>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="fake-input ">
            <div className="fake-input__header">
              <span>
                Detalhes:
                        </span>
            </div>
            <div className="fake-input__body bg-light">
              <span>
                <i className="far fa-clock text-muted ml--2 mr-2"></i>
                {this.props.novaMatricula.hora_aula}
              </span>
            </div>
          </div>
        </Col>
      </Row>;
    } else {
      return '';
    }
  }

  drawAvaliaçao() {
    if (this.props.novaMatricula.exige_avaliacao === 'S') {
      return <Row>
        <Col md='12' className="text-center mt-4 mb--2">
          Avaliação
                  </Col>
        <Col md="6">
          <div className="fake-input ">
            <div className="fake-input__header">
              <span>
                Dia:
                        </span>
            </div>
            <div className="fake-input__body bg-light">
              <span>
                <i className="far fa-calendar-alt text-muted ml--2 mr-2"></i>
                {this.props.novaMatricula.data_avaliacao}
              </span>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="fake-input ">
            <div className="fake-input__header">
              <span>
                Horário:
                        </span>
            </div>
            <div className="fake-input__body bg-light">
              <span>
                <i className="far fa-clock text-muted ml--2 mr-2"></i>
                {this.props.novaMatricula.hora_avaliacao}
              </span>
            </div>
          </div>
        </Col>
      </Row>;
    } else {
      return '';
    }
  }

  drawParq() {
    if (this.props.novaMatricula.exige_parq === 'S') {
      return <Row className="justify-content-center" >
        <Col md='12' className="text-center mt-4 mb--2">
          Respostas Par-Q
                  </Col>
        {
          this.props.novaMatricula.parq.map((item, index) => {
            return <Col md="2" key={"parqR" + index}>
              <div className="fake-input ">
                <div className="fake-input__body bg-light">
                  <span>
                    {Object.keys(item)[0]} : {Object.values(item)[0]}
                  </span>
                </div>
              </div>
            </Col>
          })
        }
      </Row>;
    } else {
      return '';
    }
  }

  drawAtestado() {
    if (this.props.novaMatricula.exige_atestado === 'S') {
      return <Row>
        <Col md='12' className="text-center mt-4 mb--2">
          Atestado médico
                  </Col>
        <Col md="8">
          <div className="fake-input ">
            <div className="fake-input__header">
              <span>
                Arquivo selecionado:
                        </span>
            </div>
            <div className="fake-input__body bg-light">
              <span>
                <i className="fas fa-folder-open text-muted ml--2 mr-2"></i>
                {this.props.novaMatricula.atest_nome}
              </span>
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="fake-input ">
            <div className="fake-input__header">
              <span>
                Data do atestado:
                        </span>
            </div>
            <div className="fake-input__body bg-light">
              <span>
                <i className="far fa-calendar-alt text-muted ml--2 mr-2"></i>
                {this.props.novaMatricula.atest_data}
              </span>
            </div>
          </div>
        </Col>
      </Row>;
    } else {
      return '';
    }
  }

  render() {

    let aula = this.drawAula();
    let avaliacao = this.drawAvaliaçao();
    let parq = this.drawParq();
    let atestado = this.drawAtestado();

      return (
        <Modal isOpen={this.props.show} className="modal-dialog-centered" size="lg" toggle={this.props.close()}>
          <div className="modal-body p-0">
            <Card className="bg-lighter shadow border-0 ">
              <CardHeader className="bg-white">
                <div className="text-center">
                  <span className="text-muted text-center d-block">
                    Verifique os dados a serem enviados
                  </span>
                </div>
                <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1 }} data-dismiss="modal" type="button" onClick={this.props.close()}>
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody className="pt-3 pb-5">
                {aula}
                {avaliacao}
                {parq}
                {atestado} 
                <Row>
                  <Col md='12'>
                    <span>
                      <b>{this.props.novaMatricula.des_curso} - Turma: </b> {this.props.novaMatricula.cod_turma}
                      {(this.props.novaMatricula.des_curso.indexOf('ECONOMICO') !== -1) ? '(econônico)' : ''}
                    </span>
                  </Col>
                  <Col lg="4">
                    <div className="fake-input">
                      <div className="fake-input__header">
                        <span>
                          Unidade:
                                  </span>
                      </div>
                      <div className="fake-input__body bg-light">
                        <span>
                          <i className="fas fa-school text-muted ml--2 mr-2"></i>
                          {this.props.novaMatricula.des_unidade}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Dias da semana:
                                  </span>
                      </div>
                      <div className="fake-input__body bg-light">
                        <span>
                          <i className="far fa-calendar-alt text-muted ml--2 mr-2"></i>
                          {this.props.novaMatricula.des_turma}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Horário:
                                  </span>
                      </div>
                      <div className="fake-input__body bg-light">
                        <span>
                          <i className="far fa-clock text-muted ml--2 mr-2"></i>
                          {this.props.novaMatricula.horario}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Valor da matrícula:
                                  </span>
                      </div>
                      <div className="fake-input__body bg-light">
                        <span>
                          <i className="fas fa-donate text-muted ml--2 mr-2"></i>
                          {this.props.novaMatricula.vlr_matricula}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Valor da mensalidade:
                                  </span>
                      </div>
                      <div className="fake-input__body bg-light">
                        <span>
                          <i className="fas fa-donate text-muted ml--2 mr-2"></i>
                          {this.props.novaMatricula.vlr_mensalidade}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Faixa etária:
                                  </span>
                      </div>
                      <div className="fake-input__body bg-light">
                        <span>
                          {this.props.novaMatricula.faixa_etaria} (anos)
                                    </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-around mt-4">
                  <Button color="danger" onClick={this.props.close()} >Voltar</Button>
                  <Button color="info" onClick={() => this.props.efetivar()} >Confirmar dados e enviar</Button>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Modal>
        );
  }
}

export default ModalNovaMatriculaEfetivar;

export const clearModalidades = () => ({
    type: 'CLEAR_MODALIDADES'
  });

export const addModalidadesDisponiveis = mod => ({
    type: 'SET_MODALIDADES_DISPONIVEIS',
    mod
});

export const addModalidadesFila = mod => ({
  type: 'SET_MODALIDADES_FILA',
  mod
});

export const addModalidades = mod => ({
  type: 'SET_MODALIDADES',
  mod
});
export const clearUsuarioDados = () => ({
    type: 'CLEAR_USER_DATA'
  });

export const addUsuarioDados = user => ({
    type: 'ADD_USER_DATA',
    user
});

export const updateUsuarioDados = user => ({
  type: 'UPDATE_USER_BASIC_DATA',
    user
});

export const updateUsuarioDadosDependentes = dependentes => ({
  type: 'UPDATE_USER_DEPENDENTES',
  dependentes
});

export const updateUsuarioCorrespondencia = end => ({
  type: 'UPDATE_USER_DATA_CORRESPONDENCIA',
  end
});

export const updateUsuarioCobranca = end => ({
  type: 'UPDATE_USER_DATA_COBRANCA',
  end
});


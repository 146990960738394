import React from "react";
import { Link } from "react-router-dom";
import UserHeader from '../Headers/UserHeader';

import {
  Navbar,
  Container
} from "reactstrap";

class AdminNavbar extends React.Component {

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            <div className="d-none d-md-contents">
              <UserHeader usuarioFoto={this.props.usuario.foto_url} usuarioNome={this.props.usuario.nome} logoutHandler={this.props.logoutHandler} ></UserHeader>
            </div>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;

import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert, Table } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { onlyNumbers } from "../../logic/utilities/inputHelpers";
import moment from "moment";

class ModalFatura extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      copiedText: ''
    };
  }

  confirmationBtns = () => {
    if (this.props.faturaSelecionada.url_boleto !== 'boleto pago' && this.props.faturaSelecionada.linha_digitavel && onlyNumbers(this.props.faturaSelecionada.linha_digitavel).length > 0) {
      return <>
        <Col lg="6" className="text-center">
          <Button color="default" type="button" href={this.props.faturaSelecionada.url_boleto} target="_blank" >
            Baixar Boleto
            </Button>
        </Col>
        <Col lg="6" className="text-center">
          <Button color="primary" type="button" onClick={this.props.close()} >
            Fechar
          </Button>
        </Col>
      </>
    } else if (this.props.faturaSelecionada.detalhes && this.props.faturaSelecionada.detalhes.url_extrato !== null && this.props.faturaSelecionada.detalhes.url_extrato !== "")
    {
      return <>
        <Col lg="6" className="text-center">
          <Button color="default" type="button" href={this.props.faturaSelecionada.detalhes.url_extrato} target="_blank" >
            Baixar Extrato
          </Button>
        </Col>
        <Col lg="6" className="text-center">
          <Button color="primary" type="button" onClick={this.props.close()} >
            Fechar
          </Button>
        </Col>
      </>
    }else{
      return <>
        <Col className="text-center">
          <Button color="primary" type="button" onClick={this.props.close()}>
            Fechar
          </Button>
        </Col>
      </>
    }
  }

  render() {

    if (this.props.faturaSelecionada) {
      var alertColor = 'success';
      var alertIcon = <i className="fas fa-check-circle"></i>;
      var alertText = <small>Fatura paga!</small>;
      var dataPagamento = this.props.faturaSelecionada.dataPagamento;
      var codigoBarras = '';

      if (this.props.faturaSelecionada.pago === 'não') {
        if (this.props.faturaSelecionada.linha_digitavel && onlyNumbers(this.props.faturaSelecionada.linha_digitavel).length > 0) {
          codigoBarras = <Row>
          <Col className="text-center">
            <CopyToClipboard text={this.props.faturaSelecionada.linha_digitavel} onCopy={() => this.setState({ copiedText: this.props.faturaSelecionada.linha_digitavel })} >
              <div className="fake-input ">
                <div className="fake-input__header">
                  <span>
                    Código de barras:
                  </span>
                </div>
                <div>
                  <div className="fake-input__body fake-input__body--fit bg-lighter d-inline-block">
                    <i className="fas fa-barcode text-muted mr-2"></i>
                    <span>
                      {this.props.faturaSelecionada.linha_formatada}
                    </span>
                  </div>
                  <Button className="arrow-left px-3 ml-3 d-inline-block" color="default" type="button">
                    <i className="fas fa-barcode mr-2"></i>
                    {(this.state.copiedText === this.props.faturaSelecionada.linha_digitavel)
                      ? "Copiado!" : "  Copiar  "}
                  </Button>
                </div>
              </div>
            </CopyToClipboard>
          </Col>
        </Row>;
        }
        var dataVen = moment(new Date(this.props.faturaSelecionada.data_vencimento.split('/')[2], parseInt(this.props.faturaSelecionada.data_vencimento.split('/')[1]) - 1, this.props.faturaSelecionada.data_vencimento.split('/')[0]));

        var today = moment(new Date());

        if (!moment(dataVen).isAfter(today)) {
          dataPagamento = 'vencido';
          alertColor = 'danger';
          alertText = <small><strong>Atenção!</strong> Essa fatura se encontra <u>vencida</u>.</small>;
        } else {
          dataPagamento = 'em aberto';
          alertColor = 'warning';
          alertText = <small><strong>Atenção!</strong> Essa fatura se encontra em aberto.</small>;
        }
        alertIcon = <i className="fas fa-exclamation-circle"></i>;
      }

      return (
        <Modal isOpen={this.props.show} className="modal-dialog-centered" size="lg" toggle={this.props.close()}>
          <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1 }} data-dismiss="modal" type="button" onClick={this.props.close()}>
            <span aria-hidden={true}>×</span>
          </button>
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-center">
                  <span className="text-muted text-center d-block">
                    Fatura Nº {this.props.faturaSelecionada.numero}
                  </span>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 pb-lg-3" style={{ width: 'fit-content', margin: 'auto' }}>
                <Row className="text-center">
                  <Col>
                    <Alert color={alertColor} className="d-inline-block py-1 mb-3">
                      <span className="alert-inner--icon">
                        <small>
                          {alertIcon}
                        </small>
                      </span>{" "}
                      <span className="alert-inner--text">
                        {alertText}
                      </span>
                    </Alert>
                  </Col>
                </Row>
                {codigoBarras}
                <Row>
                  <Col lg="4">
                    <div className="fake-input">
                      <div className="fake-input__header">
                        <span>
                          Valor:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="fas fa-money-bill text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.faturaSelecionada.valor}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Data de vencimento:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-calendar-alt text-muted ml--2 mr-2"></i>
                        <span>
                          {this.props.faturaSelecionada.data_vencimento}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Data de pagamento:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                        <span>
                          {(this.props.faturaSelecionada.data_pagamento) ? this.props.faturaSelecionada.data_pagamento : dataPagamento}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Retorno do banco:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="fas fa-university text-muted ml--2 mr-2"></i>
                        <span>
                          {(this.props.faturaSelecionada.retorno_banco) ? this.props.faturaSelecionada.retorno_banco : 'sem Retorno'}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Mensagem do Banco:
                      </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="fas fa-university text-muted ml--2 mr-2"></i>
                        <span>
                          {(this.props.faturaSelecionada.mensagem_banco) ? this.props.faturaSelecionada.mensagem_banco : 'sem mensagem'}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <h3 className="mb-0 text-center">
                        Detalhes da fatura
                      </h3>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Associado</th>
                          <th scope="col">Descrição</th>
                          <th scope="col" className="text-center">Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        (!this.props.faturaSelecionada.detalhes) ? 
                        <tr>
                          <td className="text-center" colSpan="4">
                            Não foi encontrado detalhes para essa fatura.
                          </td>
                        </tr>
                            :
                            <>
                              {this.props.faturaSelecionada.detalhes.itens.map((item) => {
                                return <tr key={item.numero}>
                                  <td>{item.associado}</td>
                                  <td>{item.descricao}</td>
                                  <td className="text-center">{item.valor}</td>
                                </tr>
                              })}
                            <tr>
                              <td colSpan="2">Total:</td>  
                              <td className="text-center">{this.props.faturaSelecionada.detalhes.valor}</td>
                            </tr>
                          </>
                        }
                      </tbody>
                    </Table> 
                  </Card>
                <Row className="my-4 text-center">
                  { this.confirmationBtns() }
                </Row>
              </CardBody>
            </Card>
          </div>
        </Modal>
      );
    }
    else {
      return null;
    }
  }
}

export default ModalFatura;

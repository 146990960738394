import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import InputGroupValidation from "../../components/Utilities/InputGroupValidation";
import InputAddon from "../../components/Utilities/InputAddon";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroup,
  Row,
  NavLink,
  Col,
} from "reactstrap";
import { addUsuario } from "../../logic/actions";
import { connect } from "react-redux";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      email: "",
      senha: "",
      unidade: "minas",
      dados: null,
    };

    this.redirect = this.redirect.bind(this);
  }

  setProperty(key, value) {
    this.setState({ [key]: value });
  }

  redirect() {
    this.props.history.push({
      pathname: "/admin/home",
    });
  }

  async submitForm(event) {
    event.preventDefault();

    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/login_site.php",
      "f=" +
        this.state.unidade +
        "&s=" +
        encodeURIComponent(this.state.senha) +
        "&e=" +
        this.state.email
    );

    if (data.status === "ok" && data.npf) {
      data["unidade"] = this.state.unidade;

      if (data.nome == null) {
        data.nome = data.npf;
      }

      data["expira_em"] = new Date().getTime() + 1 * 60 * 60 * 1000;
      await this.props.dispatch(addUsuario(data));
      this.props.dialog(true, "Bem Vindo!", data.nome, this.redirect);
    }
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 ">
              <div className="text-muted text-center pt-3 pb-4 font-bree-regular">
                <big>
                  <i className="fas fa-door-open"></i> <b>Login</b>
                </big>
              </div>
              <Form role="form" onSubmit={(e) => this.submitForm(e)}>
                <InputGroupValidation id="group-login">
                  <FormGroup>
                    <InputGroup className="justify-content-between">
                      <div className="text-muted text-left ml-0">Unidade:</div>
                      <div className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          defaultChecked
                          id="customRadioMinas"
                          name="customRadio"
                          type="radio"
                          onClick={(e) => this.setState({ unidade: "minas" })}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadioMinas"
                        >
                          Minas
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          id="customRadioNautico"
                          name="customRadio"
                          type="radio"
                          onClick={(e) => this.setState({ unidade: "nautico" })}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadioNautico"
                        >
                          Náutico
                        </label>
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <InputAddon
                    id="email"
                    validate={true}
                    placeholder="Email"
                    type="email"
                    value={this.state.email}
                    setValue={(value) => this.setProperty("email", value)}
                    leftAddon="fas fa-envelope"
                    tipError="E-mail inválido"
                  />
                  <InputAddon
                    id="senha"
                    validate={true}
                    placeholder="Senha"
                    type="password"
                    value={this.state.senha}
                    setValue={(value) => this.setProperty("senha", value)}
                    leftAddon="fas fa-unlock-alt"
                    rightAddon="fas fa-eye"
                    rightTip="Mostar/Ocultar senha"
                  />
                  <div className="text-center">
                    <Button
                      id="buttonId"
                      type="submit"
                      className="my-2"
                      color="minas-blue-dark"
                    >
                      Entrar
                    </Button>
                  </div>
                </InputGroupValidation>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-1">
            <Col xs="6">
              <NavLink
                className="text-light text-minas-blue-light"
                to="/auth/recuperar-senha"
                tag={Link}
              >
                <i className="fas fa-question-circle"></i>{" "}
                <small>Esqueceu a senha?</small>
              </NavLink>
            </Col>
            <Col className="text-right" xs="6">
              <NavLink
                className="text-light text-minas-blue-light"
                to="/auth/registrar"
                tag={Link}
              >
                <i className="fas fa-id-card"></i> <small>Registrar</small>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { usuario: state.usuario, usuarioDados: state.usuarioDados };
};

export default withRouter(connect(mapStatetoProps)(Login));

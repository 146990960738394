import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert, FormGroup, InputGroup, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import SelecionarUsuario from "../Modals/SelecionarUsuario";
import InputAddon from "./InputAddon";

class Ingresso extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            usuarioConvidado: false,
            npf: '',
            nome: '',
            identidade: '',
            idade: '',
            tipoConvite: '',
            valorConvite: ''
        }
    }

    componentDidMount() {
        this.inputDataUpdate('npf', this.props.usuarioDados.npf);
        this.inputDataUpdate('usuarioConvidado', false);
        this.inputDataUpdate('nome', this.props.usuarioDados.nome);
    }

    inputDataUpdate = (prop, value) => {
        this.setState({ [prop]: value });
        this.props.update(this.props.id, prop, value);
    }

    onSelectUsuario = (npf, nome) => {
        this.inputDataUpdate('npf', npf);
        this.inputDataUpdate('nome', nome);
    }

    render() {

        return (
            <form>
                <Row className="mb-3">
                    <Col className='bg-lighter pt-3 rounded'>
                        <Row>
                            <Col md='6'>
                                <FormGroup className="mb-3">
                                    <div>
                                        <InputGroup >
                                            <div className="text-left ml-0 mr-3">Tipo de espectador:</div>
                                            <div className="text-left">
                                                <div className="custom-control custom-radio">
                                                    <input className="custom-control-input" defaultChecked id={"customRadioSocio" + this.props.id} name="customRadio" type="radio" onClick={(value) => this.inputDataUpdate('usuarioConvidado', false)} />
                                                    <label className="custom-control-label" htmlFor={"customRadioSocio" + this.props.id}>Sócio</label>
                                                </div>
                                                <div className="custom-control custom-radio mt-1">
                                                    <input className="custom-control-input" id={"customRadioVisitante" + this.props.id} name="customRadio" type="radio" onClick={(value) => this.inputDataUpdate('usuarioConvidado', true)} />
                                                    <label className="custom-control-label" htmlFor={"customRadioVisitante" + this.props.id}>Visitante</label>
                                                </div>
                                            </div>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md='6' className='d-flex'>
                                <div>
                                    <div className="text-left ml-0 mr-3">Tipo de ingresso:</div>
                                </div>
                                <div style={{ flex: '1' }}>
                                    <UncontrolledDropdown className="w-100">
                                        <DropdownToggle caret className="m-0 alt-style w-100">
                                            {this.state.tipoConvite}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                this.props.detalhesEvento.ingressos.map((item, key) => {
                                                    return <DropdownItem key={key} onClick={() => { this.inputDataUpdate('tipoConvite', item.codTipoIngresso); this.inputDataUpdate('valorConvite', item.vlr_ingresso) }}>{item.codTipoIngresso} - {item.vlr_ingresso}</DropdownItem>
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </Col>
                        </Row>
                        {(this.state.usuarioConvidado) ?
                            <>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-3">
                                            <InputAddon placeholder="Nome" preValidade={false} outClass="input-group" id={"input-nome" + this.props.id} validate={true} type="text" value={this.state.nome} setValue={(value) => this.inputDataUpdate('nome', value)} leftAddon="fas fa-user" tipError="Preencha o nome." />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="8">
                                        <FormGroup className="mb-3">
                                            <InputAddon placeholder="Identidade" preValidade={false} outClass="input-group" id={"input-rg" + this.props.id} validate={true} type="text" value={this.state.identidade} setValue={(value) => this.inputDataUpdate('identidade', value)} leftAddon="fas fa-id-card" tipError="Preencha a identidade." />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup md="6" className="mx-md-auto mb-0">
                                            <InputAddon placeholder="Idade" preValidade={false} outClass="input-group" id={"input-idade" + this.props.id} validate={true} type="number" max={120} min={0} value={this.state.idade} setValue={(value) => this.inputDataUpdate('idade', value)} leftAddon="fas fa-calendar-alt" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> :
                            <Row>
                                <Col>
                                    <SelecionarUsuario styleAlternative={true} changeUsuario={this.onSelectUsuario} {...this.props}></SelecionarUsuario>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col xs="1">
                        <Button className='h-100 m-auto' id={"ingresso" + this.props.id} color="warning" type="button" onClick={() => this.props.remover(this.props.id)}>
                            <i className="fas fa-times text-lg"></i>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}

export default Ingresso;

import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert, FormGroup, InputGroup } from "reactstrap";
import moment from "moment";
import ReactToPrint from 'react-to-print';
import ModalLoading from './Loading';

var Barcode = require('react-barcode');

class ModalEventoImprimirIngresso extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      copiedText: '',
      showLoading: false
    };
  }

  formatarTexto(ingresso) {
    var linhas = [];
    var count = 1;

    while (ingresso.hasOwnProperty("des_texto_" + count)) {
      if (ingresso["des_texto_" + count] !== null) {
        linhas.push(<small>{ingresso["des_texto_" + count]}<br /></small>);
      } else {
        linhas.push(<br />);
      }
      count++;
    }

    return linhas;
  }

  render() {

    return (
      <>
        <ModalLoading show={this.state.showLoading} />
        <Modal isOpen={this.props.show} className="modal-dialog-centered" size='lg'>
          <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1 }} data-dismiss="modal" type="button" onClick={() => this.props.close()}>
            <span aria-hidden={true}>×</span>
          </button>
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-center">
                  <span className="text-muted text-center d-block">
                    Ingressos
                </span>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 pb-lg-3 d-block text-center" style={{ margin: '0 2rem' }}>
                <Row>
                  <Col>
                    <h6>Compra realizada com sucesso! </h6>
                    <p>Imprima seus ingressos e bom evento!</p>
                  </Col>
                </Row>
                <div className="m-auto" id="imprimir" ref={el => (this.componentRef = el)} style={{maxWidth: '700px'}}>
                  {this.props.ingressos.map((item, key) => {
                    return <>
                      <div key={key} className="text-black border border-gray p-4 m-0">
                      <div>
                        <h6 className="text-center d-block text-black">
                          {item.des_evento}
                        </h6>
                        <h7 className="text-center d-block text-black">
                          {item.des_dia_semana} - {item.des_data_hora}
                        </h7>
                        <h7 className="text-center d-block text-black">
                          LOCAL - {item.nom_local}
                        </h7>
                      </div>
                        <hr className="my-4 border-gray" />
                      <Row className="flex-nowrap">
                        <Col md="6" className="border-right border-gray">
                          <div className="text-center">
                            <h6 className="text-center d-block text-black">
                              Ingresso nº {item.nro_ingresso}
                            </h6>
                            <span className="text-center d-block">
                              <Barcode value={item.cod_barras} />
                            </span>
                          </div>
                            <hr className="mt-2 mb-4 border-gray" />
                          <h7 className="text-left d-block">
                            <b>Sócio:</b> {item.nom_associado_comp}
                          </h7>
                          <h7 className="text-left d-block">
                            <b>NPF:</b> {item.npf_associado_comp}
                          </h7>
                          <br />
                          <h7 className="text-left d-block">
                            <b>Espectador:</b>  {(item.nom_espectador) ? item.nom_espectador : item.nom_associado_expe}
                          </h7>
                          <h7 className="text-left d-block">
                            <b>Identidade:</b> {(item.des_identidade) ? item.des_identidade : '-'}
                          </h7>
                          <br />
                          <h7 className="text-left d-block">
                            <b>Mesa:</b>  {(item.des_mesa) ? item.des_mesa : '-'}
                          </h7>
                          <h7 className="text-left d-block">
                            <b>Cadeira:</b> {(item.des_cadeira) ? item.des_cadeira : '-'}
                          </h7>
                        </Col>
                        <Col md="6" className="d-flex flex-column">
                          <div className="m-auto">
                            {this.formatarTexto(item)}
                          </div>
                          <div className="mb-0 mt-auto">
                            <h7 className="d-block"><small> Valor {item.des_vlr_ingresso}.<br />
                            Igresso obtido pelo {item.des_internet} em {moment(item.dat_ingresso, "YYYY-MM-DD-HH:mm").format('DD/MM/YYYY [as] HH:mm')}.</small>
                          </h7>
                            <h6 className="mb-0"><small>MINAS TÊNIS CLUBE</small></h6>
                          </div>
                        </Col>
                      </Row>
                      </div>
                      {(key < this.props.ingressos.length - 1) ?
                        <div className="separator-cut"><i class="fas fa-cut"></i><i class="fas fa-cut"></i></div> : ''}
                      </>
                  })}
                </div>
                <Row className="my-4 text-center">
                  <Col className="text-center">
                    <Button color="danger" type="button" onClick={() => this.props.close()}>
                      Fechar
                      </Button>
                    <ReactToPrint removeAfterPrint={true} bodyClass="text-center" pageStyle="margin: 15mm 10mm;"
                      trigger={() => <Button color="default" type="button">Imprimir</Button>}
                      content={() => this.componentRef}
                      onBeforeGetContent={() => this.setState({ showLoading: true })}
                      onAfterPrint={() => this.setState({ showLoading: false })}
                    />
                    
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalEventoImprimirIngresso;

import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import {
  addUsuario,
  clearUsuario,
  clearProtocolo,
  addUsuarioDados,
  clearUsuarioDados,
  clearEventos,
  clearFaturas,
  clearCursos,
  clearHome,
  clearModalidades,
  clearNovaMatricula,
  clearOcupacao,
  clearSorteios,
  clearTurmas,
} from "../logic/actions";
import { connect } from "react-redux";
import Header from "components/Headers/Header.jsx";
import routes from "routes.js";
import RequestDialog from "components/Utilities/RequestDialog";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userFeched: false,
    };
  }

  componentDidMount() {
    this.setUsuario();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  setUsuario() {
    if (this.props.usuario && this.props.usuario.isLogged) {
      if (
        this.props.usuario.expira_em &&
        this.props.usuario.expira_em > new Date()
      ) {
        this.setUsuarioData();
      } else {
        this.logout(true);
      }
    } else {
      this.logout(false);
    }
  }

  async setUsuarioData() {
    if (
      this.props.usuarioDados.npf === "" ||
      this.props.usuario.npd != this.props.usuarioDados.npf
    ) {
      var data = await this.makeRequisition(
        true,
        true,
        "https://socio.minastc.com.br/ws/dados_associado.php",
        "f=" + this.props.usuario.unidade + "&t=" + this.props.usuario.token
      );

      if (data.status === "ok") {
        var usuario = { ...this.props.usuario, ...data };
        this.props.dispatch(addUsuarioDados(usuario));
      }
    }
    this.setState({ userFeched: true });
  }

  logout = async (showDialog) => {
    if (showDialog) {
      sessionStorage.clear();
      var cookies = document.cookie;

      for (var i = 0; i < cookies.split(";").length; ++i) {
        var myCookie = cookies[i];
        if (myCookie) {
          var pos = myCookie.indexOf("=");
          var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }

      this.props.dispatch(clearUsuarioDados());
      this.props.dispatch(clearEventos());
      this.props.dispatch(clearFaturas());
      this.props.dispatch(clearHome());
      this.props.dispatch(clearCursos());
      this.props.dispatch(clearModalidades());
      this.props.dispatch(clearNovaMatricula());
      this.props.dispatch(clearOcupacao());
      this.props.dispatch(clearSorteios());
      this.props.dispatch(clearTurmas());
      this.props.dispatch(clearUsuario());
      this.props.dispatch(clearProtocolo());

      this.showDialog(
        "error",
        "Sua sessão expirou!",
        "Favor efetue login novamente!",
        this.redirect
      );
    } else {
      this.redirect();
    }
  };

  redirect = async () => {
    await this.props.dispatch(clearUsuario());
    await this.props.dispatch(clearUsuarioDados());
    this.setState({ userFeched: false });
    this.props.history.push({
      pathname: "/auth/login",
    });
  };

  getRoutes = (routes) => {
    if (this.props.usuario.token !== "") {
      return routes.map(({ component: CustomComponent, ...prop }, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => (
                <CustomComponent
                  requisitionHTML={this.makeHtmlRequisition}
                  requisition={this.makeRequisition}
                  dialog={this.showDialog}
                  {...this.props}
                />
              )}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    }
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  makeHtmlRequisition = async (showErrorDialog, requestUrl, requestParams) => {
    var data = await this.requestRef.requisitionHTML(requestUrl, requestParams);

    if (!data && showErrorDialog) {
      this.requestRef.showError(
        "Erro!",
        "Houve um erro ao carregar o script, tente novamente mais tarde."
      );
    }
    return data;
  };

  makeRequisition = async (
    showErrorDialog,
    isPost,
    requestUrl,
    requestParams,
    header,
    body
  ) => {
    var data = await this.requestRef.requisition(
      isPost,
      requestUrl,
      requestParams,
      header,
      body
    );

    if (data.status !== "ok" && showErrorDialog) {
      if (
        data.error.indexOf("expirou") > -1 &&
        data.error.indexOf("fatura") < 0
      ) {
        this.logout(true);
      } else {
        this.requestRef.showError("Erro!", data.error);
      }
    }

    return data;
  };

  showDialog = (type, title, text, callback) => {
    if (type === "success") {
      this.requestRef.showSuccess(title, text, callback);
    } else if (type === "error") {
      this.requestRef.showError(title, text, callback);
    } else if (type === "warning") {
      this.requestRef.showWarning(title, text, callback);
    }
  };

  render() {
    return (
      <>
        <RequestDialog
          wrappedComponentRef={(ref) => (this.requestRef = ref)}
        ></RequestDialog>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/home",
            imgSrc: require("assets/img/brand/Minas-Logo-Azul.svg"),
            imgAlt: "...",
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            logoutHandler={this.logout}
          />
          <Header />
          <Container className="mt--8" fluid>
            {this.state.userFeched ? (
              <Switch>{this.getRoutes(routes)}</Switch>
            ) : (
              ""
            )}
          </Container>
          <AdminFooter />
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { usuario: state.usuario, usuarioDados: state.usuarioDados };
};

export default withRouter(connect(mapStatetoProps)(Admin));

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { addModalidades, addTurmas, addNovaMatricula } from "../../logic/actions";
import ModalModalidades from '../../components/Modals/Modalidades';
import ModalNovaMatricula from '../../components/Modals/NovaMatricula';
import SelecionarUsuario from '../../components/Modals/SelecionarUsuario';
import { ButtonToggle , Card, CardHeader, CardBody, Col, Row } from "reactstrap";

class Matricular extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false,
      showModalNova: false,
      nomeModalidade: '',
      codModalidade: null,
      npf: this.props.usuario.npf
    };
  }

   componentDidMount() {
     this.getModalidades(this.props.usuario.npf);
  }

  async getModalidades(npf)
  {
    var dataModalidade = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/matricula_curso_modalidades.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&a=' + npf);
    var dataModalidadeFila = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/inscricao_fila_espera_modalidades.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&a=' + npf);

    var data = [];

    if (dataModalidade.status === 'ok') {
      data = data.concat(dataModalidade.modalidades);
    }

    if (dataModalidadeFila.status === 'ok') {
      data = data.concat(dataModalidadeFila.modalidades);
    }

    this.setModalidades(data);
  }

  novaMatricula = async (matricula) =>
  {
    this.props.dispatch(addNovaMatricula(matricula));
    this.setState({ showModal: false });

    if(matricula.idt_status === 'INSCRICAO_FILA_DISPONIVEL')
    {
      var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/inscricao_fila_espera.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&cc=' + matricula.cod_curso + '&ct=' + matricula.cod_turma + '&a=' + this.state.npf);

      if (data.status === 'ok')
      {
        this.props.dialog('success', data.mens, "Para verificar suas inscrições na fila de espera acesse o menu 'Fila de espera'");
      }

    } else {
      this.setState({ showModalNova: true });
    }
  }

  setModalidades(modalidades)
  {
    modalidades = modalidades.filter((elem, index, self) => self.findIndex(
      (t) => { return (t.cod_mod_curso === elem.cod_mod_curso) }) === index);

    this.props.dispatch(addModalidades(modalidades));
  }
  
  async selectModalidade(codModalidade, nomeModalidade)
  {
    this.setState({codModalidade: codModalidade});
    this.setState({nomeModalidade: nomeModalidade});

    var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/matricula_curso_turmas_com_fila.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&cmc=' + codModalidade + '&a=' + this.state.npf);
     
    if (data.status === "ok")  
    {
      this.props.dispatch(addTurmas(data.turmas));
      this.setState({ showModal: true });
    }
  }

  openModal()
  {
    this.setState({showModal: true});
  }

  closeModal = () =>  {
    this.setState({showModal: false});
  }

  openModalNova() {
    this.setState({ showModalNova: true });
  }

  closeModalNova = () => {
    this.setState({ showModalNova: false });
  }

  onSelectUsuario = (npf) => {
    this.setState({ npf: npf });
    this.getModalidades(npf);
  }

  render() {

    return (
      <>
        <ModalModalidades show={this.state.showModal} open={() => this.openModal} close={() => this.closeModal} turmas={this.props.turmas} modalidade={this.state.nomeModalidade} matricular={this.novaMatricula} />
        {(this.state.showModalNova) ? <ModalNovaMatricula show={this.state.showModalNova} close={() => this.closeModalNova} open={() => this.openModalNova} npf={this.state.npf} {...this.props} /> : ''}
        <Row className="justify-content-md-center">
          <Col className="mb-5 mb-lg-0" lg="8">
            <SelecionarUsuario changeUsuario={this.onSelectUsuario} {...this.props}></SelecionarUsuario>
          </Col>
        </Row>
        <Row  className="justify-content-md-center">
          <Col className="mb-5 mb-lg-0" lg="8">
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col >
                    <h3 className="mb-0 text-center">Modalidades</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-3">
                <Row className="pb-2 text-center">
                  <p className="m-auto">Selecione uma das modalidades abaixo para ver mais detalhes.</p>
                </Row>
                <Row>
                  <Col className="text-center" id='lista-modalidades'>
                  { this.props.modalidades.todas.map((item, index) => {
                      return <ButtonToggle  color="info" className="m-1" size="sm" key={index + item.cod_mod_curso} active={this.state.codModalidadeD === item.cod_mod_curso} onClick={() => this.selectModalidade(item.cod_mod_curso, item.des_curso)} >{item.des_curso}</ButtonToggle > ;
                    })}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        </>
    );
  }
}

const mapStatetoProps = (state) => {return { modalidades: state.modalidades, turmas: state.turmas, novaMatricula: state.novaMatricula }};

export default withRouter(connect(mapStatetoProps)(Matricular));
export const clearProtocolo = () => ({
  type: "CLEAR_PROTOCOLO",
});

export const clearDocsByTpo = () => ({
  type: "CLEAR_DOCS_BY_TPO",
});

export const addNovoProtocolo = (protocolo) => ({
  type: "ADD_NOVO_PROTOCOLO",
  protocolo,
});

export const setTiposOperacao = (tiposOperacao) => ({
  type: "SET_TIPOS_OPERACAO",
  tiposOperacao,
});

export const setdocsByTpo = (docsByTpo) => ({
  type: "SET_DOCS_BY_TPO",
  docsByTpo,
});

export const setProtocolosAssociado = (protocolosAssociado) => ({
  type: "SET_PROTOCOLOS_ASSOCIADO",
  protocolosAssociado,
});

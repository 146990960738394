import React from "react";
import { Card, CardBody,  Button, Row, Container, Col } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          hasError: false,
          error: null,
          errorInfo: null
      };
  }
    componentDidCatch(error, errorInfo) {
        this.setState({ error: error, errorInfo: errorInfo, hasError: true });
    }

  render() {
    if (this.state.hasError) {
        return <>
            
        <div className="header-auth">
          <div className="header minas-gradient-bg-esportes-blue-light pt-5">
            <div className="separator separator-bottom separator-xl-top separator-skew zindex-100">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2560 839.3" preserveAspectRatio="xMinYMin meet">
                <g>
                  <path className="fill-minas-blue-dark" d="M2386.5,308.7c-214,134.6-502.5,191.3-699,260.2s-354.6,202.8-369.9,236c-15.3,33.2-37,34.4-37,34.4H2560V0
		C2560,45.9,2544.7,209.2,2386.5,308.7z"/>
                  <path className="fill-minas-blue-dark" d="M1243.7,804.8c-15.3-33.2-173.5-167.1-369.9-236c-196.4-68.9-484.9-125.6-699-260.2C16.6,209.2,1.3,45.9,1.3,0v839.3
		h1279.3C1280.7,839.3,1259,838,1243.7,804.8z"/>
              </g>
              </svg>
            </div>
          </div>
          <div className="main-content">
            <AuthNavbar />
                <Container>
                    <Row className="justify-content-center mb-4">
                        <Col xs="6" md="4" lg="3">
                        <img alt="..." src={require("assets/img/brand/Minas-Logo-Branca.svg")} />
                        </Col>
                    </Row>
                        <Row className="justify-content-center" lang="pt-BR">
                            <Col lg="5" md="7">
                                <Card className="bg-secondary shadow border-0">
                                    <CardBody className="px-lg-5 ">
                                        <div  className="text-muted text-center pt-3 pb-4 font-bree-regular">
                                            <big><i className='fas fa-exclamation-triangle' />  <b>Erro!</b></big>
                                        </div>
                                        <div className="text-center">
                                        <h4 className="heading mt-4"><big>Houve um erro no sistema!</big></h4>
                                            <p className="mx-3"> Por favor entre em contato com o Minas. </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            <AuthFooter />
          </div>
        </div>
    </>
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
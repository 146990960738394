import React from "react";
import { withRouter  } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

class CustomPagination extends React.Component {

  pageActivate(list, pageNum) {

    list.lastChild.classList.remove('disabled');
    list.firstChild.classList.remove('disabled');

    if (pageNum === 0) {
      list.firstChild.classList.add('disabled');
    } else if (pageNum === this.props.numPages - 1) {
      list.lastChild.classList.add('disabled');
    }
  }

  pagePrev(event) {
    event.preventDefault();

    var list = document.getElementById('page-list');
    var active = list.querySelector('.active');

    if (active != null) {
      var pageNum = active.firstChild.firstChild.nodeValue - 2;

      if (pageNum > -1 && pageNum < this.props.numPages) {
        this.props.changePage(pageNum);

        active.classList.remove('active');
        active.previousSibling.classList.add('active');

        this.pageActivate(list, pageNum);
      }
    }
  }

  pageNext(event) {
    event.preventDefault();

    var list = document.getElementById('page-list');
    var active = list.querySelector('.active');

    if (active != null) {
      var pageNum = active.firstChild.firstChild.nodeValue - 0;

      if (pageNum > -1 && pageNum < this.props.numPages) {
        this.props.changePage(pageNum);

        active.classList.remove('active');
        active.nextSibling.classList.add('active');

        this.pageActivate(list, pageNum);
      }
    }
  }

  pageNumber(event) {
    event.preventDefault();

    var pageNum = event.target.firstChild.nodeValue - 1;

    if (pageNum > -1 && pageNum < this.props.numPages) {
      this.props.changePage(pageNum);

      var list = event.target.parentNode.parentNode;

      var active = list.querySelector('.active');
      active.classList.remove('active');
      event.target.parentNode.classList.add('active');

      this.pageActivate(list, pageNum);
    }
  }

  render() {

    var pagination = [];

    if (this.props.numPages > 0) {
      pagination.push(<PaginationItem id='page-list-prev' className="disabled" key={0}>
        <PaginationLink href="#pablo" onClick={e => this.pagePrev(e)}>
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>);

      for (var i = 1; i <= this.props.numPages; i++) {
        pagination.push(<PaginationItem key={i} className={(i === 1) ? "active" : ''} >
          <PaginationLink href="#pablo" onClick={e => this.pageNumber(e)} >
            {i}
          </PaginationLink>
        </PaginationItem>);
      }

      pagination.push(<PaginationItem id='page-list-next' key={this.props.numPages + 1}>
        <PaginationLink href="#pablo" onClick={e => this.pageNext(e)}>
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>);
    }   

    return <>
      <nav aria-label="...">
        <Pagination id='page-list' className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
          {pagination}
        </Pagination>
      </nav>
    </>;
  }
}
  
export default withRouter(CustomPagination);

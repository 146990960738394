import Faturas from "views/admin/Faturas.jsx";
import FilaEspera from "./views/admin/FilaEspera";
import Home from "views/admin/Home.jsx";
import Matricular from "./views/admin/Matricular";
import Matriculas from "./views/admin/Matriculas";
import Profile from "views/admin/Profile.jsx";
import Eventos from "views/admin/Eventos.jsx";
import Calendario from "views/admin/Calendario.jsx";
import Sorteios from "views/admin/Sorteios.jsx";
import Satisfacao from "views/admin/Satisfacao.jsx";
import Bioprotecao from "views/admin/Bioprotecao.jsx";
import Rankings from "views/admin/Rankings.jsx";
import Protocolos from "./views/admin/Protocolos";

import Login from "views/auth/Login.jsx";
import RecuperarSenha from "views/auth/RecuperarSenha.jsx";
import Registrar from "views/auth/Registrar.jsx";

var routes = [
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home text-minas-blue-dark",
    component: Home,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  /*
  {
    path: "/bioprotecao",
    name: "Protocolo de Racionalização",
    icon: "fas fa-medkit text-minas-institucional",
    component: Bioprotecao,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  */
  {
    path: "/protocolos",
    name: "Meus Protocolos",
    icon: "fas fa-file-alt text-minas-institucional",
    component: Protocolos,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/profile",
    name: "Atualizar Cadastro",
    icon: "ni ni-single-02 text-minas-blue",
    component: Profile,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/faturas",
    name: "Faturas",
    icon: "fas fa-money-check-alt text-minas-blue-light",
    component: Faturas,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: false,
  },
  {
    path: "/satisfacao",
    name: "Pesquisa de Satisfação",
    icon: "fas fa-grin-alt text-minas-institucional",
    component: Satisfacao,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/calendario",
    name: "Calendário",
    icon: "fas fa-calendar-alt text-minas-green",
    component: Calendario,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/meus-cursos",
    name: "Meus Cursos",
    icon: "fas fa-dumbbell text-minas-green-light",
    component: Matriculas,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/matriculas",
    name: "Novas Matrículas",
    icon: "fas fa-graduation-cap text-minas-green",
    component: Matricular,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/fila-espera",
    name: "Minha fila de espera",
    icon: "fas fa-clipboard-list text-minas-green-dark",
    component: FilaEspera,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/eventos",
    name: "Eventos",
    icon: "fas fa-store text-minas-orange-light",
    component: Eventos,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/sorteios",
    name: "Sorteios",
    icon: "fas fa-dice text-minas-orange",
    component: Sorteios,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/random",
    name: "Rankings",
    icon: "fas fa-random text-esportes",
    component: Rankings,
    layout: "/admin",
    showSideBar: true,
    showNaoTitular: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    showSideBar: false,
    showNaoTitular: true,
  },
  {
    path: "/registrar",
    name: "Registrar",
    icon: "ni ni-circle-08 text-pink",
    component: Registrar,
    layout: "/auth",
    showSideBar: false,
    showNaoTitular: true,
  },
  {
    path: "/recuperar-senha",
    name: "Recuperar Senha",
    icon: "ni ni-circle-08 text-pink",
    component: RecuperarSenha,
    layout: "/auth",
    showSideBar: false,
    showNaoTitular: true,
  },
];
export default routes;

import React from "react";
import { Modal } from "reactstrap";

class ModalLoading extends React.Component {

  render() {
    return (
      <Modal id="loading-modal" className="modal-dialog-centered" contentClassName="bg-transparent shadow-none" isOpen={this.props.show} >
          <div className="modal-dialog-centered" role="document">
              <div className="text-white m-auto text-xl">
                  <big><i className="fas fa-spinner fa-pulse" /></big>
              </div>
          </div>
      </Modal>
    );
  }
}

export default ModalLoading;


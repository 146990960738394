const initialState = []

export default function turmas(state = initialState, action) {
  switch (action.type) {
    case 'SET_TURMAS':
      return action.turmas;
    case 'ADD_TURMAS':
      return [...state, action.turmas];
    case 'CLEAR_TURMAS':
        return [];
    default:
      return state
  }
};

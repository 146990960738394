const initialState = {
  banners: [],
  mensagens: [],
  parceiros: [],
  popup: null,
};

export default function home(state = initialState, action) {
  switch (action.type) {
    case "ADD_BANNERS":
      return {
        ...state,
        banners: action.banners,
      };
    case "ADD_MENSAGENS":
      return {
        ...state,
        mensagens: action.mensagens,
      };
    case "ADD_POPUP":
      return {
        ...state,
        popup: action.popup,
      };
    case "ADD_PARCEIROS":
      return {
        ...state,
        parceiros: action.parceiros,
      };
    case "CLEAR_HOME":
      return {
        ...state,
        banners: [],
        mensagens: [],
        parceiros: [],
        popup: null,
      };
    default:
      return state;
  }
}

import React from "react";
import CryptoJS from "crypto-js";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ModalNovoprotocolo from "../../components/Modals/NovoProtocolo";
import {
  Card,
  Col,
  CardHeader,
  CardFooter,
  Table,
  Row,
  Button,
} from "reactstrap";
import { setProtocolosAssociado } from "../../logic/actions";

class Protocolos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    this.setProtocolosAssociado(this.props.usuario.npf);
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  setProtocolosAssociado = async (npf) => {
    var token = CryptoJS.MD5(moment().format("DD/MM/YYYY")).toString();

    var body = {
      action: "getListProtocoloByNpfAreaAssociado",
      token: token,
      npf: npf,
    };

    var data = await fetch("https://protocolo.minastc.com.br/protocolo/ws/", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });

    if (data.status) {
      this.props.dispatch(setProtocolosAssociado(data.result));
    } else {
      this.props.dispatch(setProtocolosAssociado([]));
    }
  };

  render() {
    return (
      <>
        {this.state.showModal ? (
          <ModalNovoprotocolo
            show={this.state.showModal}
            open={() => this.openModal}
            close={() => this.closeModal}
            {...this.props}
          />
        ) : (
          ""
        )}
        <Row>
          <Col>
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col className="col-2">
                    <Button color="info" onClick={() => this.openModal()}>
                      Novo Protocolo
                    </Button>
                  </Col>
                  <Col className="col-8">
                    <h3 className="mb-0 text-center">Meus Protocolos</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">
                      N° do Protocolo
                    </th>
                    <th scope="col" className="text-center">
                      Tipo de Operação
                    </th>
                    <th scope="col" className="text-center">
                      Observações
                    </th>
                    <th scope="col" className="text-center">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.protocolosAssociado.protocolos.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="6">
                        Nenhum protocolo ativo no momento.
                      </td>
                    </tr>
                  ) : (
                    this.props.protocolosAssociado.protocolos.map((item) => {
                      return (
                        <tr key={item.protocolo}>
                          <td className="text-center">{item.protocolo}</td>
                          <td className="text-center">{item.tipo_operacao}</td>
                          <td className="text-center">
                            {item.observacao_solicitante}
                          </td>
                          <td className="text-center">{item.status}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    protocolosAssociado: state.protocolos,
  };
};

export default withRouter(connect(mapStatetoProps)(Protocolos));

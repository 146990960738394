import moment from "moment";

export const uniqueArray = (array) => {
  var result = Array.from(new Set(array));
  return result;
};

export const arrayisEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const serialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const allPropertiesAreTrue = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!obj[key]) {
        return false;
      }
    }
  }
  return true;
};

export const findAncestorByClass = (el, cls) => {
  if (el) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
  }
  return el;
};

export const onlyNumbers = (value) => {
  return value.replace(/\D/g, "");
};

export const checkAndReplace = (char) => {};

export const disableEnableInput = (element, value, cssClass) => {
  element.disbled = value;
  var inputGorup = findAncestorByClass(element, cssClass);

  if (inputGorup) {
    if (value) {
      inputGorup.classList.add("disable-input");
    } else {
      inputGorup.classList.remove("disable-input");
    }
  }
};

export const setValidInvalidInput = (element, isValid, cssClass) => {
  var inputGorup = findAncestorByClass(element, cssClass);

  if (inputGorup) {
    if (isValid) {
      inputGorup.classList.add("is-valid-input");
      inputGorup.classList.remove("is-invalid-input");
    } else {
      inputGorup.classList.remove("is-valid-input");
      inputGorup.classList.add("is-invalid-input");
    }
  }
};

export const setFocusInput = (element, hasFocus, cssClass) => {
  var inputGorup = findAncestorByClass(element, cssClass);

  if (inputGorup) {
    if (hasFocus) {
      inputGorup.classList.add("has-focus");
    } else {
      inputGorup.classList.remove("has-focus");
    }
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const showPassword = (target) => {
  var icon = target;
  var element = icon.parentElement.parentElement;
  var input = element.previousSibling.previousSibling;

  if (input.type === "text") {
    input.type = "password";
    icon.classList.add("fa-eye");
    icon.classList.remove("fa-eye-slash");
  } else {
    input.type = "text";
    icon.classList.remove("fa-eye");
    icon.classList.add("fa-eye-slash");
  }
};

export const checkData = (data, cssClass, element) => {
  var retorno =
    moment().subtract(100, "years") < data && data < moment().add(100, "years");

  if (element) {
    setValidInvalidInput(element, retorno, cssClass);
  }

  return retorno;
};

export const checkEmpty = (value, cssClass, hasMask, element) => {
  var retorno = false;

  if (value) {
    var minChar = 0;

    if (hasMask) {
      minChar = value
        .replace("-", "")
        .replace("(", "")
        .replace(")", "")
        .replace("/", "")
        .replace("/", "").length;
      value = onlyNumbers(value);

      if (minChar !== value.length) {
        retorno = false;
      } else {
        retorno = true;
      }
    } else {
      if (minChar !== value.length) {
        retorno = true;
      } else {
        retorno = false;
      }
    }
  }
  if (element) {
    setValidInvalidInput(element, retorno, cssClass);
  }
  return retorno;
};

export const checkEmail = (value, cssClass, element) => {
  // eslint-disable-next-line
  var regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var retorno = regex.test(value);

  if (element) {
    setValidInvalidInput(element, retorno, cssClass);
  }

  return retorno;
};

export const validarCPF = (element, cssClass) => {
  var isValid = true;
  if (element.value) {
    var cpf = element.value.replace(/[^\d]+/g, "");
    if (cpf === "") isValid = false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      isValid = false;
    // Valida 1o digito
    var add = 0;
    for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) isValid = false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) isValid = false;
  } else {
    isValid = false;
  }

  if (isValid === true) {
    setValidInvalidInput(element, true, cssClass);
  } else {
    setValidInvalidInput(element, false, cssClass);
  }
};

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addEventos } from "../../logic/actions";
import SelecionarUsuario from "../../components/Modals/SelecionarUsuario";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import moment from "moment";
import ModalEvento from "../../components/Modals/Evento";

class Eventos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedEvento: null,
    };
  }

  componentDidMount() {
    this.setEventos();
  }

  async setEventos() {
    var data = await this.props.requisition(
      false,
      true,
      "https://socio.minastc.com.br/ws/listar_eventos.php",
      "t=" + this.props.usuario.token
    );

    if (data.status === "ok") {
      this.props.dispatch(addEventos(data.eventos));
    }
  }

  alertaCancelarInscricao(cod_curso, cod_turma, cod_nome_curso) {
    this.setState({ selected_cod_curso: cod_curso });
    this.setState({ selected_cod_turma: cod_turma });
    this.props.dialog(
      "warning",
      "Antenção!",
      "Deseja mesmo cancelar sua inscrição na modalidade " +
        cod_nome_curso +
        "?",
      this.cancelarInscricao
    );
  }

  async cancelarInscricao(c) {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/cancelamento_fila_espera.php",
      "f=" +
        this.props.usuario.unidade +
        "&t=" +
        this.props.usuario.token +
        "&cc=" +
        this.state.cod_curso +
        "&ct=" +
        this.state.cod_turma +
        "&a=" +
        this.state.selected_npf
    );

    if (data.status === "ok") {
      this.props.dialog("success", "Sucesso!", data.mens, this.setEventos);
    }
  }

  inscricaoEvento = async (
    cDDD,
    cNumero,
    sms,
    totalIngressos,
    termos,
    email
  ) => {
    var smsValue = sms ? "S" : "N";
    var termosValue = termos ? "S" : "N";

    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/inscrever_sorteio_evento.php",
      "&t=" +
        this.props.usuario.token +
        "&idf_sorteio=" +
        this.state.selectedSorteio.IdfSorteio +
        "&ddd=" +
        cDDD +
        "&celular=" +
        cNumero +
        "&receber_sms=" +
        smsValue +
        "&nro_compra=" +
        totalIngressos +
        "&concordo_termos=" +
        termosValue +
        "&email=" +
        email
    );

    if (data.status === "ok") {
      this.props.dialog("success", "Sucesso!", data.mens);
    }
  };

  maisDetalhes = (evento, e) => {
    e.preventDefault();
    this.setState({ selectedEvento: evento });
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        {this.state.selectedEvento ? (
          <ModalEvento
            show={this.state.showModal}
            close={this.closeModal}
            cancelarInscricao={this.cancelarInscricao}
            eventoSelecionado={this.state.selectedEvento}
            inscricaoEvento={this.inscricaoEvento}
            {...this.props}
          />
        ) : (
          ""
        )}
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 d-inline-block">Eventos</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Evento</th>
                    <th scope="col" className="text-center">
                      Tipo
                    </th>
                    <th scope="col" className="text-center">
                      Data Inícial
                    </th>
                    <th scope="col" className="text-center">
                      Data Final
                    </th>
                    <th scope="col" className="text-center">
                      Detalhes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.eventos.todos.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="6">
                        Nenhum evento no momento.
                      </td>
                    </tr>
                  ) : (
                    this.props.eventos.todos.map((item) => {
                      return (
                        <tr key={item.nomeEvento}>
                          <td>{item.nomeEvento}</td>
                          <td className="text-center">{item.tipoEvento}</td>
                          <td className="text-center">
                            {moment(item.dataInicioEvento, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            )}{" "}
                            {item.horaInicioEvento}
                          </td>
                          <td className="text-center">
                            {moment(item.dataFimEvento, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            )}{" "}
                            {item.horaFimEvento}
                          </td>
                          <td className="text-center">
                            <Button
                              color="link"
                              onClick={(e) => this.maisDetalhes(item, e)}
                            >
                              <i className="fas fa-search text-success text-lg"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { eventos: state.eventos };
};

export default withRouter(connect(mapStatetoProps)(Eventos));

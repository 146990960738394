import React from "react";
import { Nav, UncontrolledDropdown, DropdownToggle, Media, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

class UserHeader extends React.Component {
  render() {
    return (
      <>
        <Nav className="align-items-center d-flex dropdown-name" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0 " nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={this.props.usuarioFoto}
                  />
                </span>
                <Media className="ml-2 d-none d-md-block dropdown-name-span">
                  <span className="mb-0 text-sm font-weight-bold ">
                    {this.props.usuarioNome}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow text-center m-0">Bem vindo!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/profile" tag={Link}>
                <i className="fas fa-user-edit"></i>
                <span>Atualizar dados</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={this.props.logoutHandler}>
                <i className="fas fa-door-closed"></i>
                <span>Sair</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </>
    );
  }
}

export default UserHeader;

import React from "react";
import { Modal, Button } from "reactstrap";

class ModalGenerico extends React.Component {
  render() {

    if (!this.props.popup) {
      return ''; 
    } else {
      return (
        <Modal id="activeModal" className={"modal-dialog-centered modal-" + this.props.popup.type} contentClassName={"bg-gradient-" + this.props.popup.type} isOpen={this.props.show} toggle={this.props.close()} >
          <div className="modal-header p-3">
            <h6 className="modal-title text-x3" id="modal-title-notification">
              <i className={this.props.popup.icon} /> {this.props.popup.header}
            </h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.props.close()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-0">
            <div className="text-center">
              <h4 className="heading mt-4"><big>{this.props.popup.title}</big></h4>

              {this.props.popup.text[0] === '<' ? 
                <div className="mx-3" dangerouslySetInnerHTML={{ __html: this.props.popup.text }}></div>
                :
                <p className="mx-3"> {this.props.popup.text} </p>
              }
            </div>
          </div>
          <div className="modal-footer p-3">
            {this.props.popup.buttons.map((item, i) => {
              return <Button key={i} className={item.className} color={item.color} data-dismiss="modal" type="button" onClick={(item.clickHandler) ? item.clickHandler : this.props.close()} >
                {item.text}
              </Button>
            })}
          </div>
        </Modal>
      );
    }
  }
}

export default ModalGenerico;

export const clearNovaMatricula = () => ({
    type: 'CLEAR_NOVA_MATRICULA'
  });

export const addNovaMatricula = (mat) => ({
    type: 'ADD_NOVA_MATRICULA',
    mat
});

export const setNovaMatriculaParqQuestions = parq_q => ({
  type: 'SET_MATRICULA_PARQ_PERGUNTAS',
  parq_q
});

export const setNovaMatriculaParqConfirmations = parq_c => ({
  type: 'SET_MATRICULA_PARQ_CONFIRMACOES',
  parq_c
});

export const setNovaMatriculaDatasAula = datas_aula => ({
  type: 'SET_MATRICULA_DATAS_AULA',
  datas_aula
});

export const setNovaMatriculaDatasAvaliacao = datas_avaliacao => ({
  type: 'SET_MATRICULA_DATAS_AVALIACAO',
  datas_avaliacao
});

export const setNovaMatriculaHorasAula = horas_aula => ({
  type: 'SET_MATRICULA_HORAS_AULA',
  horas_aula
});

export const setNovaMatriculaHorasAvaliacao = horas_avaliacao => ({
  type: 'SET_MATRICULA_HORAS_AVALIACAO',
  horas_avaliacao
});

export const updateNovaMatriculaAtestado = (atest, atest_nome) => ({
  type: 'UPDATE_MATRICULA_ATESTADO',
  atest, atest_nome
});

export const updateNovaMatriculaAtestadoData = atest_data => ({
  type: 'UPDATE_MATRICULA_ATESTADO_DATA',
  atest_data
});

export const updateNovaMatriculaParq = parq => ({
  type: 'UPDATE_MATRICULA_PARQ',
  parq
});

export const updateNovaMatriculaAula = (idp , hora_aula) => ({
  type: 'UPDATE_MATRICULA_AULA',
  idp, hora_aula
});

export const updateNovaMatriculaAvaliacao = (ida, hora_avaliacao) => ({
  type: 'UPDATE_MATRICULA_AVALIACAO',
  ida, hora_avaliacao
});

export const updateNovaMatriculaAulaData = data_aula => ({
  type: 'UPDATE_MATRICULA_AULA_DATA',
  data_aula
});

export const updateNovaMatriculaAvaliacaoData = data_avaliacao => ({
  type: 'UPDATE_MATRICULA_AVALIACAO__DATA',
  data_avaliacao
});
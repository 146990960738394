const initialState = {
  cmc: "",
  cc: "",
  ct: "",
  parq: [],
  atest: "",
  atest_nome: "",
  atest_data: "",
  idp: "",
  ida: "",
  data_aula: "",
  hora_aula: "",
  data_avaliacao: "",
  hora_avaliacao: "",
  parq_q: [],
  parq_c: [],
  datas_aula: [],
  horas_aula: [],
  datas_avaliacao: [],
  horas_avaliacao: [],
  des_curso: "",
  des_unidade: "",
  des_turma: "",
  horario: "",
  vlr_matricula: "",
  vlr_mensalidade: "",
  vlr_mensalidade_em_pacote: "",
  matriculado: "",
  faixa_etaria: "",
  inf_curso: null,
  exige_atestado: "",
  exige_parq: "",
  exige_avaliacao: "",
  exige_primeira_aula: "",
  idt_status: "",
};

export default function novaMatricula(state = initialState, action) {
  switch (action.type) {
    case "ADD_NOVA_MATRICULA":
      return {
        ...state,
        cmc: action.mat.cod_mod_curso,
        cc: action.mat.cod_curso,
        ct: action.mat.cod_turma,
        des_curso: action.mat.des_curso,
        des_unidade: action.mat.des_unidade,
        des_turma: action.mat.des_turma,
        horario: action.mat.horario,
        vlr_matricula: action.mat.vlr_matricula,
        vlr_mensalidade: action.mat.vlr_mensalidade,
        vlr_mensalidade_em_pacote: action.mat.matriculado,
        matriculado: action.mat.matriculado,
        faixa_etaria: action.mat.faixa_etaria,
        inf_curso: action.mat.inf_curso,
        exige_atestado: action.mat.exige_atestado,
        exige_parq: action.mat.exige_parq,
        exige_avaliacao: action.mat.exige_avaliacao,
        exige_primeira_aula: action.mat.exige_primeira_aula,
        idt_status: action.mat.idt_status,
      };
    case "SET_MATRICULA_PARQ_PERGUNTAS":
      return {
        ...state,
        parq_q: action.parq_q,
      };
    case "SET_MATRICULA_PARQ_CONFIRMACOES":
      return {
        ...state,
        parq_c: action.parq_c,
      };
    case "SET_MATRICULA_DATAS_AULA":
      return {
        ...state,
        datas_aula: action.datas_aula,
      };
    case "SET_MATRICULA_DATAS_AVALIACAO":
      return {
        ...state,
        datas_avaliacao: action.datas_avaliacao,
      };
    case "SET_MATRICULA_HORAS_AULA":
      return {
        ...state,
        horas_aula: action.horas_aula,
      };
    case "SET_MATRICULA_HORAS_AVALIACAO":
      return {
        ...state,
        horas_avaliacao: action.horas_avaliacao,
      };
    case "UPDATE_MATRICULA_ATESTADO":
      return {
        ...state,
        atest: action.atest,
        atest_nome: action.atest_nome,
      };
    case "UPDATE_MATRICULA_ATESTADO_DATA":
      return {
        ...state,
        atest_data: action.atest_data,
      };
    case "UPDATE_MATRICULA_PARQ":
      return {
        ...state,
        parq: action.parq,
      };
    case "UPDATE_MATRICULA_AULA":
      return {
        ...state,
        idp: action.idp,
        hora_aula: action.hora_aula,
      };
    case "UPDATE_MATRICULA_AULA_DATA":
      return {
        ...state,
        data_aula: action.data_aula,
      };
    case "UPDATE_MATRICULA_AVALIACAO":
      return {
        ...state,
        ida: action.ida,
        hora_avaliacao: action.hora_avaliacao,
      };
    case "UPDATE_MATRICULA_AVALIACAO_DATA":
      return {
        ...state,
        data_avaliacao: action.data_avaliacao,
      };
    case "CLEAR_NOVA_MATRICULA":
      return {
        ...state,
        cmc: "",
        cc: "",
        ct: "",
        parq: [],
        atest: "",
        atest_nome: "",
        atest_data: "",
        idp: "",
        ida: "",
        data_aula: "",
        hora_aula: "",
        data_avaliacao: "",
        hora_avaliacao: "",
        parq_q: [],
        parq_c: [],
        datas_aula: [],
        horas_aula: [],
        datas_avaliacao: [],
        horas_avaliacao: [],
        des_curso: "",
        des_unidade: "",
        des_turma: "",
        horario: "",
        vlr_matricula: "",
        vlr_mensalidade: "",
        vlr_mensalidade_em_pacote: "",
        matriculado: "",
        faixa_etaria: "",
        inf_curso: null,
        exige_atestado: "",
        exige_parq: "",
        exige_avaliacao: "",
        exige_primeira_aula: "",
        idt_status: "",
      };
    default:
      return state;
  }
}

import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert, Form, FormGroup } from "reactstrap";
import ModalIFrame from "./Iframe";
import InputAddon from "../Utilities/InputAddon";
import InputGroupValidation from "../Utilities/InputGroupValidation";

class ModalSorteioInscricao extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showTermos: false,
      ddd: '',
      celular: '',
      receberSMS: false,
      nCompra: 0,
      termos: false,
      email: ''
    }
  }

  inputDataUpdate = (name, value) =>
  {
    this.setState({ [name]: value });
  }

  closeModalTermo = () => {
    this.setState({ showTermos: false });
  }

  openModalTermo() {
    this.setState({ showTermos: true });
  }

  render() {

    return (
      <>
        <ModalIFrame show={this.state.showTermos} close={() => this.closeModalTermo} code={this.props.termos} titulo="Termos do Sorteio" />
        <Modal isOpen={this.props.show} className="modal-dialog-centered" size="md" toggle={this.props.close()}>
          <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1 }} data-dismiss="modal" type="button" onClick={this.props.close()}>
          <span aria-hidden={true}>×</span>
        </button>
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-center">
                <span className="text-muted text-center d-block">
                    Incrição para:<br />{this.props.titulo}
                </span>
              </div>
            </CardHeader>
            <CardBody className="px-lg-4 pb-lg-3 d-block text-center my-0" style={{ margin: '2rem'}}>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <InputGroupValidation>
                  <div className="bg-lighter mx--4 px-4 py-2 d-flex mb-4 flex-column">
                    <h6 className="heading-small my-auto">
                      Informações de contato
                      </h6>
                  </div>
                    <div>
                      <Row>
                        <Col>
                          <FormGroup className="mb-1">
                              <label className="input-group-label text-center" htmlFor="input-celular">
                                  Celular
                                </label>
                            <Row>
                              <Col xs="4">
                                <InputAddon preValidade={false} outClass="input-group" id="input-celular-ddd" validate={true} type="text" value={this.state.ddd} placeholder="DDD" mask='99' setValue={(value) => this.inputDataUpdate('ddd', value)} leftAddon="fas fa-mobile-alt" />
                              </Col>
                              <Col xs="8">
                                <InputAddon preValidade={false} mask='99999-9999' outClass="input-group" id="input-celular" validate={true} type="text" value={this.state.celular} placeholder="Número" setValue={(value) => this.inputDataUpdate('celular', value)} leftAddon=" "/>
                                </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                        <Row>
                        <Col>
                          <FormGroup className="mb-4 mt-2">
                              <InputAddon preValidade={false} label='Receber SMS com comfirmação?' outClass="input-group" id="input-sms" validate={false} type="checkbox" value={this.state.receberSMS} setValue={(value) => this.inputDataUpdate('receberSMS', value)} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label className="input-group-label" htmlFor="input-email">
                              E-mail
                              </label>
                            <InputAddon preValidade={false} placeholder='E-mail' outClass="input-group" id="input-email" validate={true} type="email" value={this.state.email} setValue={(value) => this.inputDataUpdate('email', value )} leftAddon="fas fa-envelope" tipError="E-mail inválido" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="bg-lighter mx--4 px-4 py-2 d-flex mb-4 flex-column">
                        <h6 className="heading-small my-auto">
                          Informações do Sorteio
                      </h6>
                      </div>
                      <Row className="text-center">
                        <FormGroup md="6" className="mx-md-auto mb-0">
                          <label className="input-group-label" htmlFor="input-numero">
                            Quantidade de ingressos
                            </label>
                          <InputAddon preValidade={false} outClass="input-group" id="input-numero" validate={true} type="number" max={this.props.maxIngressos} min={this.props.minIngressos} value={this.state.nCompra} setValue={(value) => this.inputDataUpdate('nCompra', value)} leftAddon="fas fa-ticket-alt" />
                        </FormGroup>
                      </Row>
                      <Row className="my-3">
                        <Col md="6">
                          <Button color="default" outline type="button" onClick={() => this.openModalTermo()}>
                            Termos do sorteio
                          </Button>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-0">
                            <InputAddon preValidade={false} label='Concordo com os Termos do sorteio' outClass="input-group" id="input-termos" validate={true} type="checkbox" value={this.state.termos} setValue={(value) => this.inputDataUpdate('termos', value)} tipError="Termos de Sorteios não aceito." />
                          </FormGroup>
                        </Col>
                      </Row>
                    
                    <Row className="my-4 text-center">
                      <Col className="text-center">
                          <Button color="primary" id="button1" type="button" onClick={this.props.close()}>
                          Cancelar
                    </Button>
                          <Button color="success" id="button2" type="submit" onClick={(event) => { event.preventDefault(); this.props.inscricaoSorteio(this.state.ddd, this.state.celular, this.state.receberSMS, this.state.nCompra, this.state.termos, this.state.email); }}>
                          Finalizar
                    </Button>
                      </Col>
                    </Row>
                  </div>
                  </InputGroupValidation>
                </Form>
            </CardBody>
          </Card>
        </div>
        </Modal>
        </>
      );
  }
}

export default ModalSorteioInscricao;

/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="py-3 px-2 mt-5 minas-gradient-bg-esportes-blue-light position-relative">
        <Container fluid>
          <div className="separator separator-top separator-skew zindex-100">
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" x="0px" y="0px" viewBox="0 0 2560 839.3">
              <path fill="#f8f9fe" d="M1.3,0c0,45.9,15.3,209.2,173.5,308.7c214,134.6,502.5,191.3,699,260.2c196.4,68.9,354.6,202.8,369.9,236
	c15.3,33.2,37,34.4,37,34.4s21.7-1.3,37-34.4c15.3-33.2,173.5-167.1,369.9-236s484.9-125.6,699-260.2C2544.7,209.2,2560,45.9,2560,0
	H1.3z"/>
            </svg>
          </div>
          
          <Row className="align-items-end justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-minas-blue-dark mt-2">
                © 2020{" "}
                <a
                  className="ml-1 text-minas-blue-dark"
                  href="http://minastenisclube.com.br/"
                  target="_blank"
                >
                  Minas Tênis Clube
                  </a>
              </div>
            </Col>
            <Col xl="6" className="text-right d-flex flex-column">
                <a className="fill-minas-blue-dark mb-2" href="http://www.planb.com.br" target="_blank" rel="noopener">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 22" width="60" height="30"><path d="M22.9 13.1h-.4v2h.5c.4 0 .8 0 1-.3.2-.1.3-.4.3-.7 0-.6-.5-1-1.4-1zm.1 3l-.5-.1v2.2h-1v-6h1.2c1 0 1.6.2 2 .4.5.3.7.8.7 1.4 0 1.3-.8 2-2.4 2zm3.3 2v-5.9h1v5h2.8v1h-3.8zM33 14l-.7 2.2h1.4L33 14zm1.5 4.2L34 17h-2l-.4 1.2h-1.2l2.4-6h.5l2.3 6h-1.1zm5.6 0l-2.8-3.7v3.7h-1v-6h.5l2.8 3.6v-3.6h1v6H40zm5.8-2.9h-.6v2h.6c.4 0 .8 0 1-.2.2-.2.3-.4.3-.8 0-.3-.1-.6-.3-.7-.2-.2-.5-.3-1-.3zm0-2.2h-.6v1.4h.5c.7 0 1-.2 1-.7 0-.5-.2-.7-.9-.7zm.2 5h-1.8v-5.8l1.7-.1c.6 0 1 .1 1.4.4.3.3.5.6.5 1.1 0 .5-.2.9-.7 1.1.7.3 1 .8 1 1.6 0 .6-.1 1-.5 1.3-.4.3-1 .5-1.6.5zm-31.4-6.6zM3 10.5h9c.8 0 1.3.4 1.3.7v1.2a.8.8 0 0 0 1 .7h.2c.2 0 .4 0 .5.2.2.2.2.4.2.6L15 16a.8.8 0 0 1-1.2.6.8.8 0 0 0-1 .6l-.3 2.6c0 .3-.6.7-1.4.7H3.8c-.8 0-1.4-.4-1.4-.7l-.9-8.6c0-.3.6-.6 1.4-.6zm0-1.4C1.3 9 0 10 0 11.2v.1l1 8.6H.8C.9 21 2.2 22 3.8 22H11c1.6 0 2.9-1 2.9-2.2v.1l.1-1.7a2.3 2.3 0 0 0 2.4-2l.2-2.2v-.2a2.3 2.3 0 0 0-1.9-2.2v-.3C14.8 10 13.6 9 12 9H3zm1.3-.9S3.9 8 4 7.5C4 7 4.5 6.2 6.3 5c1.4-1 2.2-1.7 2.3-2.3.1-.6-.4-1.2-1.5-2.3-1-.8.5-.3 1.7.8 1.2 1 2.1 2.7.1 4.2-1.2 1-.5 1.6.4 2.1 1 .5 2.3.7 2.3.7H4.2z"></path></svg>
                  </a>
                <a className="text-minas-blue-dark nav-link-icon" href="http://minastenisclube.com.br/">
                  <i className="fas fa-globe-americas pr-2" /> <span className="nav-link-inner--text">Voltar ao site principal</span>
                </a>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;

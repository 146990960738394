import React from "react";
import {
  setNovaMatriculaParqConfirmations,
  setNovaMatriculaParqQuestions,
  setNovaMatriculaDatasAvaliacao,
  setNovaMatriculaHorasAvaliacao,
  setNovaMatriculaDatasAula,
  setNovaMatriculaHorasAula,
  updateNovaMatriculaAtestado,
  updateNovaMatriculaAtestadoData,
  updateNovaMatriculaParq,
  updateNovaMatriculaAvaliacao,
  updateNovaMatriculaAvaliacaoData,
  updateNovaMatriculaAula,
  updateNovaMatriculaAulaData,
} from "../../logic/actions";

import ReactDatetime from "react-datetime";
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  Row,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
import ModalNovaMatriculaEfetivar from "./NovaMatriculaEfetivar";
import { toBase64 } from "../../logic/utilities/inputHelpers";
import moment from "moment";

class ModalNovaMatricula extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkbox: "",
      checkboxValid: false,
      formValid: false,
      showModal: false,
      exige: false,
      isCheckValid: [],
      selectedCheckBox: 0,
    };
  }

  componentDidMount() {
    this.inicializar();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.novaMatricula.cmc !== prevProps.novaMatricula.cmc &&
      this.props.novaMatricula.cc !== prevProps.novaMatricula.cc &&
      this.props.novaMatricula.ct !== prevProps.novaMatricula.ct
    ) {
      this.inicializar();
    }
  }

  inicializar = () => {
    if (this.props.novaMatricula.exige_parq === "S") {
      this.getParq();
    }

    if (this.props.novaMatricula.exige_avaliacao === "S") {
      this.getDatasAvaliacao();
    }

    if (this.props.novaMatricula.exige_primeira_aula === "S") {
      this.getDatasAula();
    }
  };

  async getDatasAula() {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/matricula_data_primeira_aula.php",
      "t=" +
        this.props.usuario.token +
        "&cmc=" +
        this.props.novaMatricula.cmc +
        "&cc=" +
        this.props.novaMatricula.cc +
        "&ct=" +
        this.props.novaMatricula.ct +
        "&a=" +
        this.props.npf
    );

    if (data.status === "ok") {
      this.props.dispatch(setNovaMatriculaDatasAula(data.datas));

      if (data.datas.length > 0) {
        var primeiraData = moment(
          new Date(
            data.datas[0].split("/")[2],
            data.datas[0].split("/")[1] - 1,
            data.datas[0].split("/")[0]
          )
        );
        this.setAulaData(primeiraData);
      }
    }
  }

  async getHorariosAula(dataAula) {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/matricula_hora_primeira_aula.php",
      "t=" +
        this.props.usuario.token +
        "&cmc=" +
        this.props.novaMatricula.cmc +
        "&cc=" +
        this.props.novaMatricula.cc +
        "&ct=" +
        this.props.novaMatricula.ct +
        "&data=" +
        dataAula +
        "&a=" +
        this.props.npf
    );

    if (data.status === "ok") {
      this.props.dispatch(setNovaMatriculaHorasAula(data.datas));
    }
  }

  async getDatasAvaliacao() {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/matricula_data_avaliacao.php",
      "t=" +
        this.props.usuario.token +
        "&cmc=" +
        this.props.novaMatricula.cmc +
        "&cc=" +
        this.props.novaMatricula.cc +
        "&ct=" +
        this.props.novaMatricula.ct +
        "&a=" +
        this.props.npf
    );

    if (data.status === "ok") {
      this.props.dispatch(setNovaMatriculaDatasAvaliacao(data.datas));

      if (data.datas.length > 0) {
        var primeiraData = moment(
          new Date(
            data.datas[0].split("/")[2],
            data.datas[0].split("/")[1] - 1,
            data.datas[0].split("/")[0]
          )
        );

        this.setAvaliacaoData(primeiraData);
      }
    }
  }

  async getHorariosAvaliacao(avaliacao_data) {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/matricula_hora_avaliacao.php",
      "t=" +
        this.props.usuario.token +
        "&cmc=" +
        this.props.novaMatricula.cmc +
        "&cc=" +
        this.props.novaMatricula.cc +
        "&ct=" +
        this.props.novaMatricula.ct +
        "&data=" +
        avaliacao_data +
        "&a=" +
        this.props.npf
    );

    if (data.status === "ok") {
      this.props.dispatch(setNovaMatriculaHorasAvaliacao(data.datas));
    }
  }

  async getParq() {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/matricula_perguntas_parq.php",
      "t=" + this.props.usuario.token + "&a=" + this.props.npf
    );

    if (data.status === "ok") {
      this.props.dispatch(setNovaMatriculaParqQuestions(data.perguntas));

      var confirmacoes = [];

      for (var obj in data) {
        var index = obj.indexOf("confirmacao");
        if (index > -1) {
          confirmacoes.push(data[obj]);
        }
      }

      this.props.dispatch(setNovaMatriculaParqConfirmations(confirmacoes));

      var parq = [];

      for (var i = 0; i < data.perguntas.length; i++) {
        var qNumber = '"' + (i + 1) + '"';
        parq.push({ [qNumber]: "N" });
      }

      this.setParq(parq);
    }
  }

  setParq(parq) {
    this.props.dispatch(updateNovaMatriculaParq(parq));
  }

  setParqQuestion(event, parq) {
    var auxParq = this.props.novaMatricula.parq;

    if (event.target.checked) {
      auxParq[parq] = { ['"' + (parq + 1) + '"']: "S" };
    } else {
      auxParq[parq] = { ['"' + (parq + 1) + '"']: "N" };
    }

    this.props.dispatch(updateNovaMatriculaParq(auxParq));
  }

  setAtestado(event) {
    this.props.dispatch(
      updateNovaMatriculaAtestado(
        toBase64(event.target.files[0], event.target.files[0].name)
      )
    );
  }

  setAtestadoData = (atest_data) => {
    this.props.dispatch(
      updateNovaMatriculaAtestadoData(atest_data.format("DD/MM/YYYY"))
    );
  };

  setAulaData = (aula_data) => {
    this.props.dispatch(
      updateNovaMatriculaAulaData(aula_data.format("DD/MM/YYYY"))
    );
    this.getHorariosAula(aula_data.format("DD/MM/YYYY"));
  };

  setAula(idAula, horario) {
    this.props.dispatch(updateNovaMatriculaAula(idAula, horario));
  }

  setAvaliacaoData = (avaliacao_data) => {
    this.props.dispatch(
      updateNovaMatriculaAvaliacaoData(avaliacao_data.format("DD/MM/YYYY"))
    );
    this.getHorariosAvaliacao(avaliacao_data.format("DD/MM/YYYY"));
  };

  setAvaliacao(idAvaliacao, horario) {
    this.props.dispatch(updateNovaMatriculaAvaliacao(idAvaliacao, horario));
  }

  validateForm = () => {
    const { checkboxValid } = this.state;
    this.setState({
      formValid: checkboxValid,
    });
  };

  updateCheckbox = (checked) => {
    this.setState(
      (prev) => ({
        checkbox: checked,
        selectedCheckBox: checked
          ? prev.selectedCheckBox + 1
          : prev.selectedCheckBox - 1,
      }),
      this.validateCheckbox
    );
  };

  validateCheckbox = () => {
    let checkboxValid = true;
    let errorMsg = { ...this.state.errorMsg };
    if (this.state.selectedCheckBox < 2) {
      checkboxValid = false;
      errorMsg.checkbox = "Please select atleast 2 checkbox";
    }
    this.setState({ checkboxValid, errorMsg }, this.validateForm);
  };

  isValidDayAulas = (current) => {
    return (
      this.props.novaMatricula.datas_aula.indexOf(
        current.format("DD/MM/YYYY")
      ) !== -1
    );
  };

  isValidDayAvaliacao = (current) => {
    return (
      this.props.novaMatricula.datas_avaliacao.indexOf(
        current.format("DD/MM/YYYY")
      ) !== -1
    );
  };

  efetivarMatricula = async () => {
    var obj = {
      t: this.props.usuario.token,
      cmc: this.props.novaMatricula.cmc,
      ct: this.props.novaMatricula.ct,
      cc: this.props.novaMatricula.cc,
      parq: this.props.novaMatricula.parq,
      atest: this.props.novaMatricula.atest,
      atest_data: this.props.novaMatricula.atest_data,
      ida: this.props.novaMatricula.ida,
      idp: this.props.novaMatricula.idp,
      a: this.props.npf,
    };

    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/matricula_efetivar.php",
      "",
      "",
      obj
    );

    if (data.status === "ok") {
      this.props.dialog(
        "success",
        data.des_sucesso,
        "Curso: " + data.des_curso + ". Turma: " + data.des_turma,
        this.reloadPage
      );
    }
  };

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  reloadPage = () => {
    window.location.reload(false);
  };

  drawAula() {
    if (this.props.novaMatricula.exige_primeira_aula === "S") {
      return (
        <Row className="mb-5">
          <Col className="mb-5 mb-lg-0">
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0 text-center">
                      Agendar data da primeira aula
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-3">
                <Row className="pb-2 text-center">
                  <Col lg="7">
                    <p className="m-auto pb-2">
                      Selecione uma das datas disponíveis a baixo.
                    </p>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          open={true}
                          input={false}
                          isValidDate={this.isValidDayAulas}
                          timeFormat={false}
                          defaultValue={this.props.novaMatricula.data_aula}
                          onChange={this.setAulaData}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="5">
                    <p className="m-auto pb-2">
                      Selecione um dos horários disponíveis a baixo.
                    </p>
                    <FormGroup>
                      <InputGroup className="input-group-alternative text-left bg-white px-4 pt-4 pb-2 d-block">
                        {this.props.novaMatricula.horas_aula.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="custom-control custom-radio mb-3"
                              >
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id={"aulaRadio" + index}
                                  checked={
                                    this.props.novaMatricula.idp ===
                                    item.id_horario
                                  }
                                  onChange={() =>
                                    this.setAula(
                                      item.id_horario,
                                      item.descricao
                                    )
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={"aulaRadio" + index}
                                >
                                  {" "}
                                  {item.descricao}{" "}
                                </label>
                              </div>
                            );
                          }
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  drawAvaliaçao() {
    if (this.props.novaMatricula.exige_avaliacao === "S") {
      return (
        <Row className="mb-5">
          <Col className="mb-5 mb-lg-0">
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0 text-center">
                      Agendar data da avaliação
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-3">
                <Row className="pb-2 text-center">
                  <Col lg="7">
                    <p className="m-auto pb-2">
                      Selecione uma das datas disponíveis a baixo.
                    </p>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <ReactDatetime
                          open={true}
                          input={false}
                          isValidDate={this.isValidDayAvaliacao}
                          timeFormat={false}
                          defaultValue={this.props.novaMatricula.data_avaliacao}
                          onChange={this.setAvaliacaoData}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="5">
                    <p className="m-auto pb-2">
                      Selecione um dos horários disponíveis a baixo.
                    </p>
                    <FormGroup>
                      <InputGroup className="input-group-alternative text-left bg-white px-4 pt-4 pb-2 d-block">
                        {this.props.novaMatricula.horas_avaliacao.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="custom-control custom-radio mb-3"
                              >
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id={"avaliacaoRadio" + index}
                                  checked={
                                    this.props.novaMatricula.ida ===
                                    item.id_horario
                                  }
                                  onChange={() =>
                                    this.setAvaliacao(
                                      item.id_horario,
                                      item.descricao
                                    )
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={"avaliacaoRadio" + index}
                                >
                                  {" "}
                                  {item.descricao}{" "}
                                </label>
                              </div>
                            );
                          }
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  drawParq() {
    if (this.props.novaMatricula.exige_parq === "S") {
      return (
        <Row className="mb-5">
          <Col className="mb-5 mb-lg-0">
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0 text-center">Questionário Par-Q</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-3">
                <Row className="pb-2 text-center">
                  <Col>
                    <p className="m-auto pb-2">
                      Responda as questões a baixo com 'Sim' ou 'Não':
                    </p>
                    <FormGroup>
                      {this.props.novaMatricula.parq_q.map((item, index) => {
                        return (
                          <Row
                            key={index}
                            className="input-group-alternative mb-4 py-4 bg-white rounded mx-2"
                          >
                            <Col md="12">
                              <p>Questão {index + 1}</p>
                            </Col>
                            <Col md="9">
                              <p className="m-0 text-left pl-2">
                                {" "}
                                {item.des_pergunta}{" "}
                              </p>
                            </Col>
                            <Col
                              md="3"
                              className="d-flex justify-content-around align-items-center"
                            >
                              <span>Não</span>
                              <label className="custom-toggle m-0">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.setParqQuestion(e, index)
                                  }
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                              <span>Sim</span>
                            </Col>
                          </Row>
                        );
                      })}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="m-auto pb-2 text-center">Confirmações:</p>
                    <p className="m-auto pb-2 text-center">
                      Você precisa concordar com os termos abaixo para seguir.
                    </p>
                    <FormGroup>
                      {this.props.novaMatricula.parq_c.map((item, index) => {
                        if (item) {
                          return (
                            <Row
                              key={index}
                              className="input-group-alternative mb-4 py-4 px-4 bg-white rounded mx-2"
                            >
                              <div className="custom-control custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  id={"customCheck" + index}
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.updateCheckbox(e.target.checked)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={"customCheck" + index}
                                >
                                  {item}
                                </label>
                              </div>
                            </Row>
                          );
                        } else {
                          return "";
                        }
                      })}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  drawAtestado() {
    if (this.props.novaMatricula.exige_atestado === "S") {
      return (
        <Row className="mb-5">
          <Col className="mb-5 mb-lg-0">
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0 text-center">Atestado médico</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-3">
                <Row className="pb-2 text-center">
                  <Col>
                    <p className="m-auto pb-2">
                      Por favor faça o upload do atestado e informe a data em
                      que ele foi emitido.
                    </p>
                    <FormGroup>
                      <Row>
                        <Col md="8">
                          <CustomInput
                            type="file"
                            id="exampleCustomFileBrowser"
                            name="customFile"
                            label="Selecione um arquivo"
                            onChange={(e) => this.setAtestado(e)}
                          />
                        </Col>
                        <Col md="4">
                          <InputGroup className="input-group-alternative shadow-none border border-light">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              inputProps={{ placeholder: "Selecione a data" }}
                              onChange={this.setAtestadoData}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  render() {
    let aula = this.drawAula();
    let avaliacao = this.drawAvaliaçao();
    let parq = this.drawParq();
    let atestado = this.drawAtestado();

    return (
      <>
        <ModalNovaMatriculaEfetivar
          close={() => this.closeModal}
          show={this.state.showModal}
          novaMatricula={this.props.novaMatricula}
          efetivar={this.efetivarMatricula}
        />
        <Modal
          isOpen={this.props.show}
          className="modal-dialog-centered"
          size="xl"
          toggle={this.props.close()}
        >
          <div className="modal-body p-0">
            <Card className="bg-lighter shadow border-0 ">
              <CardHeader className="bg-white">
                <div className="text-center">
                  <span className="text-muted text-center d-block">
                    Preencha os campos corretamente
                  </span>
                </div>
                <button
                  aria-label="Close"
                  className="close position-absolute"
                  style={{ right: "1rem", top: "1rem", zIndex: 1 }}
                  data-dismiss="modal"
                  type="button"
                  onClick={this.props.close()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody className="pt-3 pb-5">
                {aula}
                {avaliacao}
                {parq}
                {atestado}
                <Row>
                  <Col>
                    {!aula && !avaliacao && !parq && !atestado ? (
                      <p className="text-center">
                        Não são exigidos dados prévios para se matricular nessa
                        modalidade.
                      </p>
                    ) : null}
                  </Col>
                </Row>
                <Row className="justify-content-around mt-4">
                  <Button color="danger" onClick={this.props.close()}>
                    Fechar
                  </Button>
                  {!aula && !avaliacao && !parq && !atestado ? (
                    <Button color="info" onClick={() => this.openModal()}>
                      Próximo
                    </Button>
                  ) : (
                    <Button
                      disabled={!this.state.formValid}
                      color="info"
                      onClick={() => this.openModal()}
                    >
                      Próximo
                    </Button>
                  )}
                </Row>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalNovaMatricula;

const initialState = {
  total_faturas: 0,
  total_pagas: 0,
  total_n_pagas: 0,
  txt_em_aberto: '',
  todas: [],
  em_aberto: [],
  pagas: []
}

export default function faturas(state = initialState, action) {
  switch (action.type) {
    case 'ADD_FATURAS':
      return {
        ...state, 
        total_faturas: action.fat.total_faturas,
        total_pagas: action.fat.total_pagas,
        total_n_pagas: action.fat.total_faturas,
        txt_em_aberto: action.fat.txt_em_aberto,
        todas: action.fat.faturas,
        em_aberto: action.fat.faturas.filter(function(value){ return value.pago !== "sim"}),
        pagas: action.fat.faturas.filter(function(value){ return value.pago === "sim"}),
        };
    case 'CLEAR_FATURAS':
        return {
          ...state,           
          total_faturas: 0,
          total_pagas: 0,
          total_n_pagas: 0,
          txt_em_aberto: '',
          todas: [],
          em_aberto: [],
          pagas: []
        };
    default:
      return state
  }
};

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addSorteios } from "../../logic/actions";
import ModalSorteio from "../../components/Modals/Sorteio";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";

class Sorteios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedSorteio: null,
    };
  }

  componentDidMount() {
    this.setEventos();
  }

  async setEventos() {
    var data = await this.props.requisition(
      false,
      true,
      "https://socio.minastc.com.br/ws/listar_eventos_sorteio.php",
      "f=" + this.props.usuario.unidade + "&t=" + this.props.usuario.token
    );

    if (data.status === "ok") {
      this.props.dispatch(addSorteios(data.eventos));
    }
  }

  cancelarInscricao = async () => {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/cancelar_inscricao_sorteio.php",
      "f=" +
        this.props.usuario.unidade +
        "&t=" +
        this.props.usuario.token +
        "&idf_sorteio=" +
        this.state.selectedSorteio.IdfSorteio +
        "&concordo_cancelamento=S"
    );

    if (data.status === "ok") {
      this.props.dialog("success", "Sucesso!", data.descricao, this.setEventos);
    }
  };

  inscricaoSorteio = async (
    cDDD,
    cNumero,
    sms,
    totalIngressos,
    termos,
    email
  ) => {
    var smsValue = sms ? "S" : "N";
    var termosValue = termos ? "S" : "N";

    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/inscrever_sorteio_evento.php",
      "f=" +
        this.props.usuario.unidade +
        "&t=" +
        this.props.usuario.token +
        "&idf_sorteio=" +
        this.state.selectedSorteio.IdfSorteio +
        "&ddd=" +
        cDDD +
        "&celular=" +
        cNumero +
        "&receber_sms=" +
        smsValue +
        "&nro_compra=" +
        totalIngressos +
        "&concordo_termos=" +
        termosValue +
        "&email=" +
        email
    );

    if (data.status === "ok") {
      this.props.dialog("success", "Sucesso!", data.descricao);
    }
  };

  maisDetalhes = (sorteio, e) => {
    e.preventDefault();
    this.setState({ selectedSorteio: sorteio });
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        {this.state.selectedSorteio ? (
          <ModalSorteio
            show={this.state.showModal}
            close={this.closeModal}
            cancelarInscricao={this.cancelarInscricao}
            sorteioSelecionado={this.state.selectedSorteio}
            inscricaoSorteio={this.inscricaoSorteio}
          />
        ) : (
          ""
        )}
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 d-inline-block">Eventos</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Evento</th>
                    <th scope="col" className="text-center">
                      Data do evento
                    </th>
                    <th scope="col" className="text-center">
                      Inscrições de
                    </th>
                    <th scope="col" className="text-center">
                      Data do sorteio
                    </th>
                    <th scope="col" className="text-center">
                      Detalhes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.sorteios.todos.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="6">
                        Nenhum evento no momento.
                      </td>
                    </tr>
                  ) : (
                    this.props.sorteios.todos.map((item) => {
                      return (
                        <tr key={item.IdfSorteio} id={item.IdfSorteio}>
                          <td>{item.DescricaoEvento}</td>
                          <td className="text-center">
                            {item.DataInicio} às {item.HoraInicio}
                          </td>
                          <td className="text-center">
                            {item.DataInicioInscricao} à {item.DataFimInscricao}
                          </td>
                          <td className="text-center">
                            {item.DataSorteio} às {item.HorasSorteio}
                          </td>
                          <td className="text-center">
                            <Button
                              color="link"
                              onClick={(e) => this.maisDetalhes(item, e)}
                              type="button"
                            >
                              <i className="fas fa-search text-success text-lg"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return { sorteios: state.sorteios };
};

export default withRouter(connect(mapStatetoProps)(Sorteios));

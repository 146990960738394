import { async } from "regenerator-runtime";

export const createError = (response) => {
  var data = {};
  data["status"] = "error";

  if (response.des_erro) {
    data["error"] = response.des_erro;
  } else if (response["yahoo.error"]) {
    data["error"] = response["yahoo.error"]["yahoo.description"];
  } else {
    data["error"] =
      "Houve um erro desconhecido na conexção. Tente novamente mais tarde.";
  }

  return data;
};

export const fetchResultPost = async (endpoint, header, body) => {
  var data = fetch(endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw createError(response.error);
      } else if (response.res && response.res.des_erro) {
        throw createError(response.res);
      }
      return response.res;
    })
    .catch((error) => {
      return error;
    });

  return data;
};

export const fetchResultGet = async (endpoint, header) => {
  var data = await fetch(endpoint, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw createError(response.error);
      } else if (response.res && response.res.des_erro) {
        throw createError(response.res);
      } else if (response["yahoo.error"]) {
        throw createError(response);
      }

      response["status"] = "ok";
      return response;
    })
    .catch((error) => {
      return error;
    });

  return data;
};

export const fetchResultHTMLPost = async (endpoint) => {
  var data = fetch(endpoint, {
    method: "POST",
    headers: {
      Accept: "text/html",
      "Content-Type": "text/html",
    },
  })
    .then((response) => response.text())
    .catch((error) => {
      return error;
    });

  return data;
};

export const fetchAcessToken123Milhas = async (npf) => {
  var body = {
    partnerId: "06eff800-2faa-4502-9237-c406f0c46320",
    customerPartnerId: npf,
    checkCustomerPartnerApi:
      "https://socio.minastc.com.br/123check.php?=" + npf,
  };

  var token = await fetch(
    "https://api.whitelabel.artviagens.cloud/partners/auth",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .catch((e) => {
      console.log("error:" + e);
    })
    .then((response) => response.json())
    .then((response) => {
      console.dir(response);
      return response.accessToken;
    });
  return token;
};

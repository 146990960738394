import React from "react";
import { allPropertiesAreTrue } from "../../logic/utilities/inputHelpers";
import { Button } from "reactstrap";
import InputAddon from "../../components/Utilities/InputAddon";


const ButtonType = (<Button />).type;
const InputAddonType = (<InputAddon />).type;

class InputGroupValidation extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            inputsDisabled: this.props.disabled,
            valid: false,
            inputsValid: {},
            display: false,
            buttonId: ''
        }

        this.setInputValidation = this.setInputValidation.bind(this);
    }

    async componentDidMount() {

        var auxInputs = {};
        
        this.recursiveMap(this.props.children, child => {
            if (child.type.name === InputAddonType.name && child.validate === true) {
                auxInputs[child.props.id] = !child.props.validate;
            }
            if (child.type.name === ButtonType.name && child.props.type === 'submit') {
                this.setState({ buttonId: child.props.id });
            }
        });

        await this.setState({ inputsValid: auxInputs });
        this.setState({ display: true });
        
    }


    componentDidUpdate(prevState) {

        if (this.props.disabled)
        {
            document.getElementById(this.state.buttonId).disabled = this.props.disabled;

        }else if (this.state.inputsValid !== prevState.inputsValid && document.getElementById(this.state.buttonId))
        {
            document.getElementById(this.state.buttonId).disabled = !allPropertiesAreTrue(this.state.inputsValid);
        }
    }

    setInputValidation(id, value) {

        var newState = { ...this.state.inputsValid };
        newState[id] = value;
        this.setState({ inputsValid: newState });    
    }

    recursiveMap(children, fn) {
        return React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
                return child;
            }

            if (child.props.children) {
                child = React.cloneElement(child, {
                    children: this.recursiveMap(child.props.children, fn)
                });
            }

            return fn(child);
        });
    }

  render() {
      return (this.state.display) ? this.recursiveMap(this.props.children, child => {
        
        if (child.type.name === 'InputAddon') //Mudar para displayName quando for para produção
        {
            return React.cloneElement(child, {
                validCallback: this.setInputValidation
            });              
        } else {
            return child;
        }          
      }) : '';
    }   
}

export default InputGroupValidation;
import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert } from "reactstrap";
import ModalEventoComprar from "./EventoComprar";
import moment from "moment";

class ModalEvento extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showInscricao: false,
      detalhesEvento: null
    }
  }

  componentDidMount() {
    this.setEventos();
  }

  async setEventos() {
    //var data = await this.props.requisition(false, true, 'https://socio.minastc.com.br/ws/listar_tipo_ingressos.php', 't=' + this.props.usuario.token + '&c=' + this.props.eventoSelecionado.codigoEvento + '&n=' +  this.props.eventoSelecionado.numeroSeqEvento);

    var data = {
      "nomeEvento": "",
      "tipoEvento": "",
      "descricaoEvento": "",
      "textoEvento": [
        {
          "nro_linha": "1",
          "des_linha": "EVENTO: ANIVERSÁRIO DO MINAS TENIS CLUBE"
        },
        {
          "nro_linha": "2",
          "des_linha": "DATA DO EVENTO: 06/10/2019"
        },
        {
          "nro_linha": "3",
          "des_linha": "LOCAL DO EVENTO: MINAS NAUTICO"
        },
        {
          "nro_linha": "4",
          "des_linha": "HORÁRIO DO EVENTO: 10H ÀS 17H"
        },
        {
          "nro_linha": "5",
          "des_linha": "ABERTURA DO CAMAROTE: 12H."
        },
        {
          "nro_linha": "6",
          "des_linha": "PROGRAMAÇÃO: SHOW BELL MARQUES (EX-CHICLETE C/ BANANA)"
        },
        {
          "nro_linha": "7",
          "des_linha": "HIDROGINÁSTICA, DJ, APRESENTAÇÃO DE BANDA E BARRACAS DE ALIMEN"
        },
        {
          "nro_linha": "8",
          "des_linha": "TAÇÃO E BEBIDAS/ ABERTURA COM JORGE BAND NO PALCO 2."
        },
        {
          "nro_linha": "9",
          "des_linha": "HAVERÁ BARRACAS DE COMIDAS E BEBIDAS"
        },
        {
          "nro_linha": "10",
          "des_linha": "EVENTO GRATUITO PARA SÓCIOS NO CLUBE"
        },
        {
          "nro_linha": "11",
          "des_linha": "HAVERÁ VENDA DE INGRESSOS PARA O CAMAROTE (EXCLÚSIVO PARA"
        },
        {
          "nro_linha": "12",
          "des_linha": "SÓCIOS MTC E MTNC )."
        },
        {
          "nro_linha": "13",
          "des_linha": "VENDA LIMITADA PARA O CAMAROTE A PARTIR DO DIA 09/09 NO VALOR"
        },
        {
          "nro_linha": "14",
          "des_linha": "DE R$ 180,00.(MÁXIMO DE DOIS CONVITES POR COTA)"
        },
        {
          "nro_linha": "15",
          "des_linha": "CAMAROTE OPEN BAR E OPEN FOOD"
        },
        {
          "nro_linha": "16",
          "des_linha": "ÍTENS INCLUÍDOS:  OPEN BAR (CERVEJA, REFRIGERANTE, ESPUMANTE E"
        },
        {
          "nro_linha": "17",
          "des_linha": "ÁGUA)."
        },
        {
          "nro_linha": "18",
          "des_linha": "BUFFET COM MESA DE ANTEPASTO COM FRUTAS E FRIOS."
        },
        {
          "nro_linha": "19",
          "des_linha": "COTA DE 100 INGRESSOS PARA SÓCIOS MTC"
        },
        {
          "nro_linha": "20",
          "des_linha": "INGRESSOS EXCLUSIVOS PARA SÓCIOS DO MTC"
        },
        {
          "nro_linha": "21",
          "des_linha": "CLASSIFICAÇÃO ETÁRIA 18 ANOS"
        }
      ],
      "dataInicioEvento": null,
      "horaInicioEvento": null,
      "dataFimEvento": null,
      "horaFimEvento": null,
      "ingressos": [
        {
          "codTipoIngresso": "CAMAR",
          "dataInicioVenda": "2019-09-09",
          "dataFimVenda": "2019-10-06",
          "vlr_ingresso": "180.00",
          "qtd_disponivel": "120"
        },
        {
          "codTipoIngresso": "PISTA",
          "dataInicioVenda": "2019-09-09",
          "dataFimVenda": "2019-10-06",
          "vlr_ingresso": "180.00",
          "qtd_disponivel": "120"
        }
      ]
    };

    this.setState({ detalhesEvento: data });
  }

  closeModalInscricao = () => {
    this.setState({ showInscricao: false });
  }

  openModalInscricao() {
    this.setState({ showInscricao: true });
  }

  formatarTexto() {
    var linhas = [];

    this.state.detalhesEvento.textoEvento.map((item, key) => {
      if (item.des_linha.indexOf(':') > 0) {
        var separado = item.des_linha.split(':', 2);
        var texto = <small key={key}><b>{separado[0]}:</b> {separado[1]}<br /></small>;
        linhas.push(texto);
      } else {
        linhas.push(<small key={key}>{item.des_linha}<br /></small>);
      }
      return null;
    });

    return linhas;
  }

  render() {

    return (
      <>
        {(this.state.detalhesEvento) ?
          <ModalEventoComprar show={this.state.showInscricao} eventoSelecionado={this.props.eventoSelecionado} detalhesEvento={this.state.detalhesEvento} usuario={this.props.usuario} usuarioDados={this.props.usuarioDados} close={this.closeModalInscricao} /> :
          ''
        }
        <Modal isOpen={this.props.show} className="modal-dialog-centered" style={{
          maxWidth: '550px'
        }} toggle={() => this.props.close()}>
          <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1 }} data-dismiss="modal" type="button" onClick={() => this.props.close()}>
            <span aria-hidden={true}>×</span>
          </button>
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-center">
                  <span className="text-muted text-center d-block">
                    Detalhes do Evento
                  </span>
                </div>
              </CardHeader>
              <CardBody className="px-lg-4 pb-lg-3 d-block text-center" style={{ margin: '0 2rem' }}>
                {(this.state.detalhesEvento) ?
                  <>
                    <Row>
                      <Col className="text-left">
                        {this.formatarTexto()}
                      </Col>
                    </Row>
                    {this.state.detalhesEvento.ingressos.map((item, key) => {
                      return <><Row>
                        <Col className="bg-lighter px-4 py-2 mt-5">
                          <h6 className="heading-small my-auto">
                            Tipo do ingresso: {item.codTipoIngresso}
                          </h6>
                        </Col>
                      </Row>
                        <Row>
                          <Col md="6">
                            <div className="fake-input pb-0">
                              <div className="fake-input__header">
                                <span>
                                  Início da venda:
                                </span>
                              </div>
                              <div className="fake-input__body bg-lighter d-inline-block">
                                <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                                <span>
                                  {moment(item.dataInicioVenda, "YYYY-MM-DD").format('DD/MM/YYYY')}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="fake-input  pb-0">
                              <div className="fake-input__header">
                                <span>
                                  Fim da venda:
                                </span>
                              </div>
                              <div className="fake-input__body bg-lighter d-inline-block">
                                <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                                <span>
                                  {moment(item.dataFimVenda, "YYYY-MM-DD").format('DD/MM/YYYY')}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <div className="fake-input pb-0 ">
                              <div className="fake-input__header">
                                <span>
                                  Valor do Ingresso:
                                </span>
                              </div>
                              <div className="fake-input__body bg-lighter d-inline-block">
                                <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                                <span>
                                  R$ {item.vlr_ingresso}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="fake-input  pb-0">
                              <div className="fake-input__header">
                                <span>
                                  Quantidade disponível:
                                </span>
                              </div>
                              <div className="fake-input__body bg-lighter d-inline-block">
                                <i className="far fa-calendar-times text-muted ml--2 mr-2"></i>
                                <span>
                                  {item.qtd_disponivel}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    })}

                    <Row className="my-4 text-center">
                      <Col className="text-center">
                        <Button color="primary" type="button" onClick={() => this.props.close()}>
                          Fechar
                        </Button>
                        <Button color="success" type="button" onClick={() => this.openModalInscricao()}>
                          Comprar
                        </Button>
                      </Col>
                    </Row>
                  </>
                  : ''
                }
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default ModalEvento;

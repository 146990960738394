import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addNovaMatricula,
  addModalidadesFila,
  addTurmas,
} from "../../logic/actions";
import ModalModalidades from "../../components/Modals/Modalidades";
import ModalNovaMatricula from "../../components/Modals/NovaMatricula";
import SelecionarUsuario from "../../components/Modals/SelecionarUsuario";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";

class FilaEspera extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalNova: false,
      nomeModalidade: "",
      codModalidade: null,
      selected_cmc: null,
      curso_origem: null,
      turma_origem: null,
      selected_cod_curso: null,
      selected_cod_turma: null,
      selected_npf: this.props.usuario.npf,
    };
  }

  componentDidMount() {
    this.setModalidadesFila(this.props.usuario.npf);
  }

  setModalidadesFila = async (npf) => {
    var data = await this.props.requisition(
      false,
      true,
      "https://socio.minastc.com.br/ws/fila_espera.php",
      "f=" +
        this.props.usuario.unidade +
        "&t=" +
        this.props.usuario.token +
        "&a=" +
        npf
    );

    if (data.status === "ok") {
      this.props.dispatch(addModalidadesFila(data.fila));
    } else {
      this.props.dispatch(addModalidadesFila([]));
    }
  };

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  openModalNova() {
    this.setState({ showModalNova: true });
  }

  closeModalNova = () => {
    this.setState({ showModalNova: false });
  };

  novaMatricula = async (matricula) => {
    this.props.dispatch(addNovaMatricula(matricula));
    this.setState({ showModal: false });
    var curso_novo = matricula.cod_curso;
    var turma_nova = matricula.cod_turma;
    var curso_origem = this.state.curso_origem;
    var turma_origem = this.state.turma_origem;

    if (matricula.idt_status === "INSCRICAO_FILA_DISPONIVEL") {
      var data = await this.props.requisition(
        true,
        true,
        "https://socio.minastc.com.br/ws/efetiva_transferencia_fila.php",
        "f=" +
          this.props.usuario.unidade +
          "&t=" +
          this.props.usuario.token +
          "&a=" +
          this.state.selected_npf +
          "&curso=" +
          curso_novo +
          "&turma=" +
          turma_nova +
          "&curso_origem=" +
          curso_origem +
          "&turma_origem=" +
          turma_origem +
          "&tipo_operacao=" +
          matricula.idt_status
      );

      if (data.status === "ok") {
        this.props.dialog(
          "success",
          data.mens,
          "Para verificar suas inscrições na fila de espera acesse o menu 'Fila de espera'"
        );
      }
    } else {
      this.setState({ showModalNova: true });
    }
  };

  consultaTransferenciaFila = async (cmc, npf, cod_curso, cod_turma) => {
    this.setState({ codModalidade: cmc });
    this.setState({ selected_npf: npf });
    this.setState({ curso_origem: cod_curso });
    this.setState({ turma_origem: cod_turma });

    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/transferencia_fila.php",
      "f=" +
        this.props.usuario.unidade +
        "&t=" +
        this.props.usuario.token +
        "&a=" +
        npf +
        "&cmc=" +
        cmc
    );

    if (data.status === "ok") {
      this.props.dispatch(addTurmas(data.turmas));
      this.setState({ showModal: true });
    }
  };

  alertaCancelarInscricao(cod_curso, cod_turma, cod_nome_curso, npf) {
    this.setState({ selected_cod_curso: cod_curso });
    this.setState({ selected_cod_turma: cod_turma });
    this.setState({ selected_npf: npf });
    this.props.dialog(
      "warning",
      "Antenção!",
      "Deseja mesmo cancelar sua inscrição na modalidade " +
        cod_nome_curso +
        "?",
      this.cancelarInscricao
    );
  }

  cancelarInscricao = async (c) => {
    var data = await this.props.requisition(
      true,
      true,
      "https://socio.minastc.com.br/ws/cancelamento_fila_espera.php",
      "f=" +
        this.props.usuario.unidade +
        "&t=" +
        this.props.usuario.token +
        "&cc=" +
        this.state.selected_cod_curso +
        "&ct=" +
        this.state.selected_cod_turma +
        "&a=" +
        this.state.selected_npf
    );

    if (data.status === "ok") {
      this.props.dialog(
        "success",
        "Sucesso!",
        data.mens,
        window.location.reload(false)
      );
    }
  };

  getUsuarioDados(npf) {
    var nome = npf;
    var usuario = this.props.usuarioDados.dependentes.find(
      (element) => element.npf === npf
    );

    if (usuario) {
      nome = usuario.nome;
    } else if (this.props.usuarioDados.npf === npf) {
      nome = this.props.usuarioDados.nome;
    }

    return nome;
  }

  onSelectUsuario = (npf) => {
    this.setState({ selected_npf: npf });
    this.setModalidadesFila(npf);
  };

  render() {
    return (
      <>
        {this.state.showModal ? (
          <ModalModalidades
            show={this.state.showModal}
            open={() => this.openModal}
            close={() => this.closeModal}
            turmas={this.props.turmas}
            modalidade={this.state.nomeModalidade}
            matricular={this.novaMatricula}
          />
        ) : (
          ""
        )}
        {this.state.showModalNova ? (
          <ModalNovaMatricula
            show={this.state.showModalNova}
            close={() => this.closeModalNova}
            open={() => this.openModalNova}
            npf={this.state.npf}
            {...this.props}
          />
        ) : (
          ""
        )}
        <Row className="justify-content-md-center">
          <Col className="mb-5 mb-lg-0" md="12">
            <SelecionarUsuario
              changeUsuario={this.onSelectUsuario}
              {...this.props}
            ></SelecionarUsuario>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 d-inline-block">Minhas inscrições</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Associado</th>
                    <th scope="col">Modalidade</th>
                    <th scope="col" className="text-center">
                      Dia e Hora
                    </th>
                    <th scope="col" className="text-center">
                      Unidade
                    </th>
                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      Cancelar Inscrição
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.modalidades.filaEspera.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan="6">
                        Nenhuma inscrição em fila de espera ativa no momento.
                      </td>
                    </tr>
                  ) : (
                    this.props.modalidades.filaEspera.map((item) => {
                      return (
                        <tr key={item.cod_curso + item.cod_turma}>
                          <td>{this.getUsuarioDados(item.npf)}</td>
                          <td>{item.curso}</td>
                          <td className="text-center">{item.turma}</td>
                          <td className="text-center">{item.unidade}</td>
                          {item.situacao_fila !== "F" ? (
                            <td className="text-center">{item.status}</td>
                          ) : (
                            <td className="text-center">
                              {item.status}
                              <Button
                                color="link"
                                onClick={() =>
                                  this.consultaTransferenciaFila(
                                    item.cmc,
                                    item.npf,
                                    item.cod_curso,
                                    item.cod_turma
                                  )
                                }
                              >
                                <i className="fas fa-exchange-alt text-alert text-sm"></i>
                              </Button>
                            </td>
                          )}
                          <td className="text-center">
                            <Button
                              color="link"
                              onClick={() =>
                                this.alertaCancelarInscricao(
                                  item.cod_curso,
                                  item.cod_turma,
                                  item.curso,
                                  item.npf
                                )
                              }
                            >
                              <i className="fas fa-clipboard-check text-danger text-lg"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    modalidades: state.modalidades,
    turmas: state.turmas,
    novaMatricula: state.novaMatricula,
  };
};

export default withRouter(connect(mapStatetoProps)(FilaEspera));

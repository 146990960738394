import { combineReducers } from "redux";
import usuario from "./usuario";
import faturas from "./faturas";
import modalidades from "./modalidades";
import turmas from "./turmas";
import novaMatricula from "./novaMatricula";
import usuarioDados from "./usuarioDados";
import home from "./home";
import meusCursos from "./meusCursos";
import eventos from "./eventos";
import sorteios from "./sorteios";
import ocupacao from "./ocupacao";
import protocolos from "./protocolos";

export default combineReducers({
  usuario,
  faturas,
  modalidades,
  turmas,
  novaMatricula,
  usuarioDados,
  home,
  meusCursos,
  eventos,
  sorteios,
  ocupacao,
  protocolos,
});

import React from "react";
import { withRouter  } from "react-router-dom";
import { fetchResultPost, fetchResultGet, fetchResultHTMLPost } from '../../logic/utilities/request';
import ModalLoading from '../Modals/Loading';
import ModalGenerico from '../Modals/Generico';

class RequestDialog extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      popup: {
          header: "",
          title: "",
          text: "",
          type: "success",
          icon: "fas fa-check-circle",
          buttons: [{ className: "text-white m-auto", color: "link", text: 'OK', clickHandler: () => this.closeModal(true) }]
      },
      callback: null
    }
  }

  closeModal = (callFunction) => {
    this.setState({ showModal: false });
    setTimeout(() => {
      if (callFunction === true && this.state.callback) {
        this.state.callback();
      }
      this.setState({ showModal: false });
      this.setState({ callback: null });
    }, 1000);

  };

  async requisitionHTML(requestUrl, requestParams) {

    var data = null;
    this.setState({ loading: true });
    data = await fetchResultHTMLPost(requestUrl + '?' + requestParams);
    this.setState({ loading: false });

    return data;
  }

  async requisition(isPost, requestUrl, requestParams, header, body){
    
    var data = null;
    this.setState({ loading: true });

    if (isPost) {
      data = await fetchResultPost(requestUrl + '?' + requestParams, header, body);
    } else {
      data = await fetchResultGet(requestUrl + '?' + requestParams, header);
    }

    this.setState({ loading: false });

    return data;
  }

  showSuccess(title, text, callback) {

    if (callback) {
      this.setState({ callback: callback });
    }

    this.setState({
      popup: {
        header: "OK!",
        title: title,
        text: text,
        type: "success",
        icon: "fas fa-check-circle",
        buttons: [{ className: "text-white m-auto", color: "link", text: 'OK', clickHandler: () => this.closeModal(true) }]
      }
    });

    this.setState({ showModal: true });
  }

  showError(title, error, callback) {

    if (callback) {
      this.setState({ callback: callback });
    }

    this.setState({
      popup: {
        header: "Atenção!",
        title: title,
        text: error,
        type: "danger",
        icon: "fas fa-exclamation-triangle",
        buttons: [{ className: "text-white m-auto", color: "link", text: 'OK', clickHandler: () => this.closeModal(true) }]
      }
    });

    this.setState({ showModal: true });
  }

  showWarning(title, error, callback) {

    if (callback) {
      this.setState({ callback: callback });
    }

    this.setState({
      popup: {
        header: "Atenção!",
        title: title,
        text: error,
        type: "danger",
        icon: "fas fa-exclamation-triangle",
        buttons: [{ className: "text-white m-auto", color: "link", text: 'Não', clickHandler: () => this.closeModal(false) },
        { className: "text-white m-auto", color: "link", text: 'Sim', clickHandler: () => this.closeModal(true) }]
      }
    });

    this.setState({ showModal: true });
  }

  render() {
    return <>
      <ModalGenerico show={this.state.showModal} close={() => this.closeModal} popup={this.state.popup} />
      <ModalLoading show={this.state.loading}></ModalLoading>
    </>;
    }
  }
  
export default withRouter(RequestDialog);

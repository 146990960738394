const initialState = {
  todos: []
}

export default function eventos(state = initialState, action) {
  switch (action.type) {
    case 'ADD_EVENTOS':
      return {
        ...state, 
        todos: action.eventos,
        };
    case 'CLEAR_EVENTOS':
        return {
          ...state,           
          todos: []
        };
    default:
      return state
  }
};

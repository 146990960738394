export const clearHome = () => ({
  type: "CLEAR_HOME",
});

export const addBanners = (banners) => ({
  type: "ADD_BANNERS",
  banners,
});

export const addMensagens = (mensagens) => ({
  type: "ADD_MENSAGENS",
  mensagens,
});

export const addPopup = (popup) => ({
  type: "ADD_POPUP",
  popup,
});

export const addParceiros = (parceiros) => ({
  type: "ADD_PARCEIROS",
  parceiros,
});

const initialState = {
  minas1: 0,
  minas2: 0,
  country: 0,
  nautico: 0,
  total: 0,
  saldoacademia1: 0,
  saldoacademia2: 0,
  saldoacademia1nautico: 0,
  saldominas1: 0,
  saldominas: 0,
  saldonautico: 0,
  saldocountry: 0,
  capacidadeacademia1: 0,
  capacidadeacademia2: 0,
  capacidadeacademianautico: 0,
  capacidademinas1: 0,
  capacidademinas2: 0,
  capacidadenautico: 0,
  capacidadecountry: 0,
  exibir_capacidade: "",
};

export default function ocupacao(state = initialState, action) {
  switch (action.type) {
    case "SET_OCUPACAO":
      if (action.acessos) {
        return {
          ...state,
          minas1: action.acessos.minas1 != null ? action.acessos.minas1 : 0,
          minas2: action.acessos.minas2 != null ? action.acessos.minas2 : 0,
          country: action.acessos.country != null ? action.acessos.country : 0,
          nautico: action.acessos.nautico != null ? action.acessos.nautico : 0,
          exibirCapacidade:
            action.acessos.exibir_capacidade != null
              ? action.acessos.exibir_capacidade
              : "N",
          total: action.acessos.total != null ? action.acessos.total : 0,
          saldoacademia1:
            action.acessos.saldoacademia1 != null
              ? action.acessos.saldoacademia1
              : 0,
          saldoacademia2:
            action.acessos.saldoacademia2 != null
              ? action.acessos.saldoacademia2
              : 0,
          saldoacademia1nautico:
            action.acessos.saldoacademia1nautico != null
              ? action.acessos.saldoacademia1nautico
              : 0,
          saldominas:
            action.acessos.saldominas != null ? action.acessos.saldominas : 0,
          saldominas1:
            action.acessos.saldominas1 != null ? action.acessos.saldominas1 : 0,
          saldonautico:
            action.acessos.saldonautico != null
              ? action.acessos.saldonautico
              : 0,
          saldocountry:
            action.acessos.saldocountry != null
              ? action.acessos.saldocountry
              : 0,
          capacidadeacademia1:
            action.acessos.capacidadeacademia1 != null
              ? action.acessos.capacidadeacademia1
              : 0,
          capacidadeacademia2:
            action.acessos.capacidadeacademia2 != null
              ? action.acessos.capacidadeacademia2
              : 0,
          capacidadeacademianautico:
            action.acessos.capacidadeacademianautico != null
              ? action.acessos.capacidadeacademianautico
              : 0,
          capacidademinas1:
            action.acessos.capacidademinas1 != null
              ? action.acessos.capacidademinas1
              : 0,
          capacidademinas2:
            action.acessos.capacidademinas2 != null
              ? action.acessos.capacidademinas2
              : 0,
          capacidadenautico:
            action.acessos.capacidadenautico != null
              ? action.acessos.capacidadenautico
              : 0,
          capacidadecountry:
            action.acessos.capacidadecountry != null
              ? action.acessos.capacidadecountry
              : 0,
        };
      } else {
        return state;
      }
    case "CLEAR_OCUPACAO":
      state = initialState;
      return state;
    default:
      return state;
  }
}

import React from "react";
import { Modal, Button, Row, Col, Card, CardHeader, CardBody, Alert, FormGroup, InputGroup } from "reactstrap";

class ModalEventoFinalizarCompra extends React.Component {

  render() {

    var total = 0;
    for (var i = 0; i < this.props.ingressos.length; i++) {
      total += parseFloat( this.props.ingressos[i].valorConvite);
    }

    return (
      <>
        <Modal isOpen={this.props.show} className="modal-dialog-centered" size='md'>
        <button aria-label="Close" className="close position-absolute" style={{ right: '1rem', top: '1rem', zIndex: 1}} data-dismiss="modal" type="button" onClick={()=>this.props.close()}>
          <span aria-hidden={true}>×</span>
        </button>
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-center">
                  <span className="text-muted text-center d-block">
                    Detalhes da compra
                </span>
              </div>
              </CardHeader>
              <CardBody className="px-lg-4 pb-lg-3 d-block text-center" style={{ margin: '0 2rem' }}>
                <Row className="mb-3">
                  <Col>
                    <h6>{this.props.eventoSelecionado.nomeEvento}</h6>
                  </Col>
                </Row>
                <hr className="my-1"></hr>
                {
                  this.props.ingressos.map((item, key) => {
                    return <><Row key={key} className="mb-1">
                      <Col sm="6" className="texto-left">
                        {item.nome}
                      </Col>
                      <Col sm="3">
                        {item.tipoConvite}
                      </Col>
                      <Col sm="3" className="text-right">
                        R$ {item.valorConvite}
                      </Col>
                    </Row>
                      <hr className="my-1"></hr>
                      </>
                  })
                }
                <Row className="mb-2 text-center">
                    <Col md="6">
                      <div className="fake-input ">
                        <div className="fake-input__header">
                          <span>
                            Total de ingressos:
                          </span>
                        </div>
                        <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-ticket-alt text-muted ml--2 mr-2"></i>
                          <span>
                            {this.props.ingressos.length}
                          </span>
                        </div>
                      </div>
                    </Col>
                  <Col md="6">
                    <div className="fake-input ">
                      <div className="fake-input__header">
                        <span>
                          Valor total:
                          </span>
                      </div>
                      <div className="fake-input__body bg-lighter d-inline-block">
                        <i className="far fa-money-bill text-muted ml--2 mr-2"></i>
                        <span>
                          {total.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                  <Row className="my-4 text-center">
                    <Col className="text-center">
                      <Button color="danger" type="button" onClick={() => this.props.close()}>
                          Cancelar
                      </Button>
                    <Button color="success" type="button" onClick={() => this.props.confirmarCompra()}>
                          Confirmar
                      </Button>
                    </Col>            
                  </Row>
            </CardBody>
          </Card>
        </div>
        </Modal>
        </>
      );
  }
}

export default ModalEventoFinalizarCompra;

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import SelecionarUsuario from '../../components/Modals/SelecionarUsuario';
import { addCursos } from "../../logic/actions";

import { Card, CardHeader, CardFooter, Table, Row, Col, Button, CardBody } from "reactstrap";
import { element } from "prop-types";

class Matriculas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      selected_cod_curso: null,
      selected_cod_turma: null,
      selected_npf: this.props.usuario.npf
    };
  }

  async componentDidMount() {
    await this.setModalidades(this.props.usuario.npf);
  }

  async setModalidades(npf) {
    var data = await this.props.requisition(false, true, 'https://socio.minastc.com.br/ws/matriculas_associado.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&a=' + npf);

    if (data.status === 'ok') {
      this.props.dispatch(addCursos(data.matriculas));
    } else {
      this.props.dispatch(addCursos([]));
    }
  }

  alertaCancelarInscricao(cod_curso, cod_turma, cod_nome_curso, npf) {
    this.setState({ selected_cod_curso: cod_curso });
    this.setState({ selected_cod_turma: cod_turma });
    this.setState({ selected_npf: npf });
    this.props.dialog('warning', 'Antenção!', 'Deseja mesmo cancelar sua inscrição na modalidade ' + cod_nome_curso + '?', this.cancelarInscricao);
  }

  cancelarInscricao = async() => {
    var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/cancelar_matricula_curso.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&cc=' + this.state.selected_cod_curso + '&ct=' + this.state.selected_cod_turma + '&a=' + this.state.selected_npf);

    if (data.status === 'ok') {
      this.props.dialog('success', 'Sucesso!', "Matricula cancelada com sucesso!", this.reloadPage);
    }
  }

  suspenderInscrição(cod_curso, cod_turma, npf){
    this.setState({ selected_cod_curso: cod_curso });
    this.setState({ selected_cod_turma: cod_turma });
    this.setState({ selected_npf: npf });
    window.open('http://socio.minastc.com.br/cursos_covid.php?filial='+ this.props.usuario.unidade +'&token=' + this.props.usuario.token + '&curso=' + cod_curso + '&turma=' + cod_turma + '&npf=' + npf, '_blank');
  }

  getUsuarioDados(npf) {
    var nome = npf;
    var usuario = this.props.usuarioDados.dependentes.find(element => element.npf == npf);

    if (usuario) {
      nome = usuario.nome;
    } else if (this.props.usuarioDados.npf == npf) {
      nome = this.props.usuarioDados.nome;
    }

    return nome;
  }

  onSelectUsuario = (npf) => {
    this.setState({ npf: npf });
    this.setModalidades(npf);
  }

  reloadPage = () => {
    window.location.reload(false);
  }
  
  render() {
    return (
      <>
        <Row className="justify-content-md-center">
          <Col className="mb-5 mb-lg-0" md="12">
            <SelecionarUsuario changeUsuario={this.onSelectUsuario} {...this.props}></SelecionarUsuario>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0 d-inline-block">
                  Meus Cursos
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Associado</th>
                    <th scope="col">Modalidade</th>
                    <th scope="col" className="text-center">Dia e Hora</th>
                    <th scope="col" className="text-center">Unidade</th>
                    {/* <th scope="col" className="text-center">Solicitar licença</th> */}
                    <th scope="col" className="text-center">Cancelar Matrícula</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (this.props.meusCursos.todos.length === 0) ?
                      <tr>
                        <td className="text-center" colSpan="6">
                          Nenhum curso matriculado no momento.
                        </td>
                      </tr>
                      :
                      this.props.meusCursos.todos.map((item) => {
                        return <tr key={item.cod_curso + item.cod_turma}>
                          <td> {this.getUsuarioDados(item.npf)}</td>
                          <td>{item.curso}</td>
                          <td className="text-center">{item.turma}</td>
                          <td className="text-center">{item.des_local}</td>
                          {/* <td className="text-center"><Button color="link" onClick={() => this.suspenderInscrição(item.cod_curso, item.cod_turma, item.npf)}><i className="fas fa-times-circle text-danger text-lg"></i></Button></td>*/}
                          <td className="text-center"><Button color="link" onClick={() => this.alertaCancelarInscricao(item.cod_curso, item.cod_turma, item.curso, item.npf)}><i className="fas fa-times-circle text-danger text-lg"></i></Button></td>
                        </tr>
                      })
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4">
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStatetoProps = (state) => { return { meusCursos: state.meusCursos } };

export default withRouter(connect(mapStatetoProps)(Matriculas));

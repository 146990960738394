import React from "react";
import { withRouter } from "react-router-dom";
import { updateUsuarioDados, updateUsuarioCorrespondencia, updateUsuarioCobranca, updateUsuarioDadosDependentes } from "../../logic/actions/index";
import InputAddon from "../../components/Utilities/InputAddon";
import InputGroupValidation from "../../components/Utilities/InputGroupValidation";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Row, Col } from "reactstrap";
import { serialize, onlyNumbers, setValidInvalidInput, disableEnableInput } from '../../logic/utilities/inputHelpers';
import SelecionarUsuario from '../../components/Modals/SelecionarUsuario';

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCopying: false,
      beforeCopyAdress: null,
      npf: this.props.usuarioDados.npf,
      telefone: this.props.usuarioDados.telefone_fixo,
      celular: this.props.usuarioDados.telefone_celular,
      email: this.props.usuarioDados.email,
      disablecobranca: false,
      disablecorrespondencia: false
    }
  }

  async buscaCep(type)
  {
    var cep = '';
    if (type === 'cobranca') {
      cep = this.props.usuarioDados.enderecos.cobranca.cep;
    } else {
      cep = this.props.usuarioDados.enderecos.correspondencia.cep;
    }
    cep = onlyNumbers(cep);

    if (cep.length === 8) {
      var data = await this.props.requisition(false, false, 'https://viacep.com.br/ws/' + cep + '/json/');

      if (data.status === 'ok') {

        var adress = {};
        adress["cep"] = data.cep;
        adress["logradouro"] = data.logradouro;
        adress["bairro"] = data.bairro;
        adress["cidade"] = data.localidade;
        adress["uf"] = data.uf;
        
        if (type === 'cobranca') {
          this.props.dispatch(updateUsuarioCobranca(adress));
        } else {
          this.props.dispatch(updateUsuarioCorrespondencia(adress));
        }
      }
    } 
  }

  async salvarData(event)
  {
    event.preventDefault();


    if (this.state.npf === this.props.usuarioDados.npf)
    {
      var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/alterar_dados.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&celular=' + this.state.celular + '&telefone=' + this.state.telefone + '&email=' + this.state.email);
      
      if (data && data.status === 'ok') {
        this.props.dialog('success', 'Sucesso!', data.des);

        var saveData = {};
        saveData["telefone_celular"] = this.state.celular;
        saveData["telefone_fixo"] = this.state.telefone;
        saveData["email"] = this.state.email;
        this.props.dispatch(updateUsuarioDados(saveData));
      } 
    } else {
      var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/alterar_dados_socio.php', 'f=' + this.props.usuario.unidade + '&t=' + this.props.usuario.token + '&celular=' + this.state.celular + '&n=' + this.state.npf + '&email=' + this.state.email);

      if (data && data.status === 'ok') {
        this.props.dialog('success', 'Sucesso!', data.des);

        var dependentes = this.props.usuarioDados.dependentes;
        var dependenteIndex = dependentes.findIndex(elem => elem.npf === this.state.npf);
        
        if (dependenteIndex > -1)
        {
          dependentes[dependenteIndex]["nro_celular"] = this.state.celular;
          dependentes[dependenteIndex]["email"] = this.state.email;
          this.props.dispatch(updateUsuarioDadosDependentes(dependentes));
        }
      }
    }
     

  } 

  async salvarAdress(event, type)
  {  
    event.preventDefault();

    var data = '';
    var query = {};

    if (type === 'correspondencia') {

      query = {
        t: this.props.usuario.token,
        f: this.props.usuario.unidade,
        tipo: 2,
        ...this.props.usuarioDados.enderecos.correspondencia
      };
    
    } else {
      query = {
        t: this.props.usuario.token,
        f: this.props.usuario.unidade,
        tipo: 1,
        ...this.props.usuarioDados.enderecos.cobranca
      };
    }
    
    data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/alterar_endereco.php', serialize(query));

    if (data.status === 'ok') {
      this.props.dialog('success', 'Sucesso!', data.des);
    }

  }

  async inputCep(value, type)
  {
    var cep = value;

    if ((type === 'cobranca' && cep !== this.props.usuarioDados.enderecos.cobranca.cep) || (type === 'correspondencia' && cep !== this.props.usuarioDados.enderecos.correspondencia.cep)) {
      cep = onlyNumbers(cep);
      var isValid = false;
      var cssClass = 'input-parent';
      var adress = {};

      adress["cep"] = cep;
      adress["logradouro"] = "";
      adress["bairro"] = "";
      adress["cidade"] = "";
      adress["uf"] = "";
      adress["numero"] = "";
      adress["complemento"] = "";

      if (cep.length > 7) {
        var data = await this.props.requisition(false, false, 'https://viacep.com.br/ws/' + cep + '/json/');

        if (data.status === 'ok') {
          isValid = true;
          adress["cep"] = data.cep;
          adress["logradouro"] = data.logradouro;
          adress["bairro"] = data.bairro;
          adress["cidade"] = data.localidade;
          adress["uf"] = data.uf;
        }
      }
    
      if (type === 'cobranca') {
        this.props.dispatch(updateUsuarioCobranca(adress));
      } else {
        this.props.dispatch(updateUsuarioCorrespondencia(adress));
      }

      if (document.getElementById('input-address-' + type)) {

        setValidInvalidInput(document.getElementById('input-postal-code-' + type), isValid, cssClass);
        setValidInvalidInput(document.getElementById('input-address-' + type), isValid, cssClass);
        setValidInvalidInput(document.getElementById('input-number-' + type), isValid, cssClass);
        setValidInvalidInput(document.getElementById('input-neighborhood-' + type), isValid, cssClass);
        setValidInvalidInput(document.getElementById('input-city-' + type), isValid, cssClass);
        setValidInvalidInput(document.getElementById('input-state-' + type), isValid, cssClass);
      }
    }
  }

  inputDataUpdate(property, value)
  {
    var data = {};
    data[property] = value;
    this.props.dispatch(updateUsuarioDados(data));

  }

  inputStateUpdate(property, value)
  {
    this.setState({ [property]: value });
  }

  inputAdressUpdate(value, property, type)
  {
    var adress = {};

    if (type === 'cobranca')
    {
      adress = this.props.usuarioDados.enderecos.cobranca;
      adress[property] = value;
      this.props.dispatch(updateUsuarioCobranca(adress));

      if (this.state.isCopying)
      {
        this.props.dispatch(updateUsuarioCorrespondencia(adress));
      }
    } else {
      adress = this.props.usuarioDados.enderecos.correspondencia;
      adress[property] = value;
      this.props.dispatch(updateUsuarioCorrespondencia(adress));
    }
  }
  
  copyAdress(event)
  {
    if (event.target.checked) {
      this.setState({
        isCopying: true,
        beforeCopyAdress: this.props.usuarioDados.enderecos.correspondencia
      });

      this.props.dispatch(updateUsuarioCorrespondencia(this.props.usuarioDados.enderecos.cobranca));
      this.toggleAdress('correspondencia', true);

    } else {

      this.props.dispatch(updateUsuarioCorrespondencia(this.state.beforeCopyAdress));
      this.toggleAdress('correspondencia', false);

    }
  }

  toggleAdress(type, value)
  {
    this.toggleAllAdress(type, value);

    if (!value)
    {
      this.setState({
        isCopying: false,
        beforeCopyAdress: null
      }); 
    }
  }

  toggleAllAdress(type, value) {
    disableEnableInput(document.getElementById('input-postal-code-' + type), value, 'input-parent');
    disableEnableInput(document.getElementById('input-address-' + type), value, 'input-parent');
    disableEnableInput(document.getElementById('input-number-' + type), value, 'input-parent');
    disableEnableInput(document.getElementById('input-complement-' + type), value, 'input-parent');
    disableEnableInput(document.getElementById('input-neighborhood-' + type), value, 'input-parent');
    disableEnableInput(document.getElementById('input-city-' + type), value, 'input-parent');
    disableEnableInput(document.getElementById('input-state-' + type), value, 'input-parent');
    this.inputStateUpdate('disable' + type, value);
    
  }


  onSelectUsuario = (npf) => {

    if (npf !== this.state.npf) {
      this.setState({ npf: npf });
      
      var dependente = this.props.usuarioDados.dependentes.find(elem => elem.npf == npf);
      
      if (dependente)
      {
        this.setState({
          npf: dependente.npf,
          telefone: '-',
          celular: dependente.nro_celular,
          email: dependente.email
        });

        disableEnableInput(document.getElementById('input-telefone'), true, 'input-parent');
        this.toggleAllAdress("cobranca", true);
        this.toggleAllAdress("correspondencia", true);
      } else {
        this.setState({
          npf: this.props.usuarioDados.npf,
          telefone: this.props.usuarioDados.telefone_fixo,
          celular: this.props.usuarioDados.telefone_celular,
          email: this.props.usuarioDados.email
        });

        disableEnableInput(document.getElementById('input-telefone'), false, 'input-parent');
        this.toggleAllAdress("cobranca", false);
        this.toggleAllAdress("correspondencia", false);
      }

    }
  }

  render() {
    return (
      <>
          <Row>
            <Col className="order-md-2 mb-5 mb-md-0 col-md-4 col-xxl-3">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img alt="..." className="rounded-circle" src={this.props.usuarioDados.foto_url} />
                      </a>
                    </div>
                </Row>
              <CardBody className="mt-8 pt-0 pt-md-2">
                  <div className="text-left">
                    <h5>
                    {this.props.usuarioDados.nome}
                    </h5>
                    <hr className="my-2" />
                    <h6>
                      <span className="font-weight-light">Data de Nasc.: </span>
                      {this.props.usuarioDados.dat_nascimento}
                    </h6> 
                    <h6>
                      <span className="font-weight-light">CPF: </span>
                      {this.props.usuarioDados.cpf}
                    </h6> 
                    <h6>
                      <span className="font-weight-light">NPF: </span>
                      {this.props.usuarioDados.npf}
                    </h6> 
                    <h6>
                      <span className="font-weight-light">Cota nº: </span>
                      {this.props.usuarioDados.cota}
                    </h6> 
                    <hr className="my-2" />
                    <h6>
                      <span className="font-weight-light">Foto válida até: </span> {this.props.usuarioDados.dat_validade_foto}                   
                    </h6>
                    <h6>
                      <span className="font-weight-light">Carteirinha vence em: </span> {this.props.usuarioDados.dat_validade_cart}                   
                    </h6>
                  </div>
                </CardBody>
            </Card>
            {(this.props.usuarioDados.dependentes.length === 0) ? '' :
              
                  this.props.usuarioDados.dependentes.map((item, key) => {
                    return <Card className="card-profile shadow mt-4" key={key}>
                      <CardBody className="">
                        <div className="dependente" key={key}>
                      <div className="card-profile-image--small">

                        <h6 className='text-justify'>
                          <img alt="..." className="rounded-circle" src={item.foto_url} />
                          {item.nome}
                        </h6>


                      </div>
                      <hr className="my-2" />
                      <h6>
                        <span className="font-weight-light">CPF: </span>
                        {item.cpf}
                      </h6>
                      <h6>
                        <span className="font-weight-light">NPF: </span>
                        {item.npf}
                      </h6>
                      <hr className="my-2" />
                      <h6>
                        <span className="font-weight-light">Foto válida até: </span> {item.data_validade_carteira}
                      </h6>
                      <h6>
                        <span className="font-weight-light">Carteirinha vence em: </span> {item.data_validade_foto}
                      </h6>
                    </div>
                  </CardBody>
                </Card>
                })
              }
            </Col>
            <Col className="order-mb-1 col-md-8 col-xxl-7 offset-xxl-1">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col >
                    <h3 className="mb-0">Meus dados</h3>
                  </Col>
                  <Col >
                    <h6 className="mt-4">
                      <span className="font-weight-light">Data da última alteração: </span> {this.props.usuarioDados.data_alteracao}
                    </h6>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <Form>
                    <div className="bg-lighter mx--4 px-4 py-2 d-flex mb-4 flex-column">
                      <h6 className="heading-small my-auto">
                        Informações de contato
                        </h6>
                    </div>
                  <InputGroupValidation>
                      <div>
                        <Row>
                        <Col>
                          <SelecionarUsuario changeUsuario={this.onSelectUsuario} styleAlternative={true} {...this.props}></SelecionarUsuario>
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-email">
                                E-mail
                              </label>
                            <InputAddon preValidade={true} outClass="input-group" id="input-email" validate={true} type="email" value={this.state.email} setValue={(value) => this.inputStateUpdate('email', value)} leftAddon="fas fa-envelope" tipError="E-mail inválido" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-telefone" >
                                Telefone
                              </label>
                            <InputAddon outClass="input-group" id="input-telefone" validate={false} type="text" value={this.state.telefone} setValue={(value) => this.inputStateUpdate('telefone', value)} leftAddon="fas fa-phone" tipError="O campo não pode estar vazio." />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-celular">
                                Celular
                              </label>
                            <InputAddon preValidade={true} outClass="input-group" id="input-celular" validate={true} type="text" value={this.state.celular} setValue={(value) => this.inputStateUpdate('celular', value)} leftAddon="fas fa-mobile-alt" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                        <Col className="text-center">
                          <Button color="info" id="submit-contato" onClick={(event) => this.salvarData(event)} size="sm" type="submit">
                              Atualizar contato
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </InputGroupValidation>
                  <div className="bg-lighter mx--4 px-4 py-2 my-4 d-flex flex-column">
                      <h6 className="heading-small my-auto">
                        Informações de endereço
                      </h6>
                  </div>
                  {this.props.usuarioDados.titular == "não" ? null :
                    <div>
                      <h6 className="heading-small text-muted d-block mb-3">
                        Endereço de cobrança
                      </h6>
                      <InputGroupValidation id="form-cobranca" disabled={this.state.disablecobranca}>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-postal-code-cobranca">
                                CEP
                            </label>
                              <InputAddon preValidade={true} outClass="input-group" mask="99999-999" id="input-postal-code-cobranca" validate={true} type="text" value={this.props.usuarioDados.enderecos.cobranca.cep} setValue={(value) => this.inputCep(value, "cobranca")} leftAddon="fas fa-mailbox" />
                            </FormGroup>
                          </Col>
                          <Col lg="5">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-city-cobranca">
                                Cidade
                          </label>
                              <InputAddon preValidade={true} outClass="input-group" id="input-city-cobranca" validate={true} type="text" value={this.props.usuarioDados.enderecos.cobranca.cidade} setValue={(value) => this.inputAdressUpdate(value, "cidade", "cobranca")} leftAddon="fas fa-map-marker-alt" />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-state-cobranca">
                                UF
                            </label>
                              <InputAddon preValidade={true} outClass="input-group" id="input-state-cobranca" validate={true} type="text" value={this.props.usuarioDados.enderecos.cobranca.uf} setValue={(value) => this.inputAdressUpdate(value, "uf", "cobranca")} leftAddon="fas fa-map-marked-alt" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-address-cobranca">
                                Logradouro
                            </label>
                              <InputAddon preValidade={true} outClass="input-group" id="input-address-cobranca" validate={true} type="text" value={this.props.usuarioDados.enderecos.cobranca.logradouro} setValue={(value) => this.inputAdressUpdate(value, "logradouro", "cobranca")} leftAddon="fas fa-road" />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="5">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-neighborhood-cobranca">
                                Bairro
                            </label>
                              <InputAddon outClass="input-group" id="input-neighborhood-cobranca" preValidade={true} validate={true} type="text" value={this.props.usuarioDados.enderecos.cobranca.bairro} setValue={(value) => this.inputAdressUpdate(value, "bairro", "cobranca")} leftAddon="fas fa-map-pin" />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-number-cobranca">
                                Número
                            </label>
                              <InputAddon preValidade={true} outClass="input-group" id="input-number-cobranca" validate={true} type="text" value={this.props.usuarioDados.enderecos.cobranca.numero} setValue={(value) => this.inputAdressUpdate(value, "numero", "cobranca")} leftAddon="fas fa-sign" />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="input-group-label" htmlFor="input-complement-cobranca">
                                Complemento
                            </label>
                              <InputAddon outClass="input-group" id="input-complement-cobranca" validate={false} type="text" value={this.props.usuarioDados.enderecos.cobranca.complemento} setValue={(value) => this.inputAdressUpdate(value, "complemento", "cobranca")} leftAddon="fas fa-map-signs" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Button id="input-submit-cobranca" color="info" type="submit" onClick={(event) => this.salvarAdress(event, "cobranca")} size="sm" >
                              Atualizar endereço de cobrança
                          </Button>
                          </Col>
                        </Row>
                      </InputGroupValidation>
                    </div> }
                  <hr className="my-4" />
                    <div>
                      <h6 className="heading-small text-muted d-block mb-3">
                      Endereço de correspondência
                      </h6>
                    <InputGroupValidation id="form-correspondencia" disabled={this.state.disablecorrespondencia}>
                      <Row>
                        {this.props.usuarioDados.titular == "não" ? null :
                          <Col lg="12">
                            <FormGroup className="text-center">
                              <div className="custom-control custom-checkbox mb-3">
                                <input className="custom-control-input" id="copyAdress" type="checkbox" onChange={(e) => this.copyAdress(e)} />
                                <label className="custom-control-label" htmlFor="copyAdress">
                                  <span className="input-group-label">Usar o mesmo endereço de cobrança</span>
                                </label>
                              </div>
                            </FormGroup>
                          </Col>
                        }
                      <Col lg="4">
                        <FormGroup>
                          <label className="input-group-label" htmlFor="input-postal-code-correspondencia">
                            CEP
                            </label>
                            <InputAddon preValidade={true} outClass="input-group" mask="99999-999" id="input-postal-code-correspondencia" validate={true} type="text" value={this.props.usuarioDados.enderecos.correspondencia.cep} setValue={(value) => this.inputCep(value, "correspondencia")} leftAddon="fas fa-mailbox" />
                        </FormGroup>
                      </Col>  
                      <Col lg="5">
                        <FormGroup>
                          <label className="input-group-label" htmlFor="input-city-correspondencia">
                            Cidade
                          </label>
                            <InputAddon preValidade={true} outClass="input-group" id="input-city-correspondencia" validate={true} type="text" value={this.props.usuarioDados.enderecos.correspondencia.cidade} setValue={(value) => this.inputAdressUpdate(value, "cidade", "correspondencia")} leftAddon="fas fa-map-marker-alt" />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label className="input-group-label" htmlFor="input-state-correspondencia">
                            UF
                            </label>
                            <InputAddon preValidade={true} outClass="input-group" id="input-state-correspondencia" validate={true} type="text" value={this.props.usuarioDados.enderecos.correspondencia.uf} setValue={(value) => this.inputAdressUpdate(value, "uf", "correspondencia")} leftAddon="fas fa-map-marker-alt" />
                        </FormGroup>
                      </Col>
                    </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                          <label className="input-group-label" htmlFor="input-address-correspondencia">
                              Logradouro
                            </label>
                            <InputAddon preValidade={true} outClass="input-group" id="input-address-correspondencia" validate={true} type="text" value={this.props.usuarioDados.enderecos.correspondencia.logradouro} setValue={(value) => this.inputAdressUpdate(value, "logradouro", "correspondencia")} leftAddon="fas fa-road" />
                          </FormGroup>
                        </Col>
                      </Row>
                    
                    <Row>
                    <Col lg="5">
                          <FormGroup>
                          <label className="input-group-label" htmlFor="input-neighborhood-correspondencia">
                              Bairro
                            </label>
                            <InputAddon outClass="input-group" id="input-neighborhood-correspondencia" preValidade={true} validate={true}  type="text" value={this.props.usuarioDados.enderecos.correspondencia.bairro} setValue={(value) => this.inputAdressUpdate(value, "bairro", "correspondencia")} leftAddon="fas fa-map-pin" />
                          </FormGroup>
                        </Col>
                    <Col lg="3">
                          <FormGroup>
                          <label className="input-group-label" htmlFor="input-number-correspondencia">
                              Número
                            </label>
                            <InputAddon preValidade={true} outClass="input-group" id="input-number-correspondencia" validate={true} type="text" value={this.props.usuarioDados.enderecos.correspondencia.numero} setValue={(value) => this.inputAdressUpdate(value, "numero", "correspondencia")} leftAddon="fas fa-sign" />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                          <label className="input-group-label" htmlFor="input-complement-correspondencia">
                              Complemento
                            </label>
                          <InputAddon outClass="input-group" id="input-complement-correspondencia" validate={false} type="text" value={this.props.usuarioDados.enderecos.correspondencia.complemento} setValue={(value) => this.inputAdressUpdate(value, "complemento", "correspondencia")} leftAddon="fas fa-map-signs" />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                          <Button id="input-submit-correspondencia" color="info" type="submit" onClick={(event) => this.salvarAdress(event, "correspondencia")} size="sm" >
                            Atualizar endereço de correspondência
                          </Button>
                        </Col>
                      </Row>
                    </InputGroupValidation>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
      </>
    );
  }
}

export default withRouter(Profile);
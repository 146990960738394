import React from "react";
import { FormGroup, InputGroupAddon, InputGroupText, InputGroup, Input, UncontrolledTooltip  } from "reactstrap";
import { showPassword, checkEmail, setFocusInput, checkEmpty, checkData } from '../../logic/utilities/inputHelpers';
import InputMask from 'react-input-mask';
import ReactDatetime from "react-datetime";

class InputAddon extends React.Component {

    componentDidMount() {

        if (this.props.preValidade && this.props.preValidade === true) {
            var target = document.getElementById(this.props.id);

            if (target) {
                this.validate(target, this.props.value);
            } else {
                this.validate(null, this.props.value);
            }
        }
    } 

    validate(target, value) {
        var isValid = false;
        if (this.props.validate === true) {

            if (this.props.checkValue) {

                isValid = this.props.checkValue(target, 'input-parent');
            } else {
                var inputType = this.props.type;

                if (inputType === "email") {

                    isValid = checkEmail(value, 'input-parent', target);
                }
                else if (inputType === 'dateTime') {
                    isValid = checkData(value, 'input-parent', target);
                }
                else if (inputType === 'text' || inputType === 'password') {
                    var hasMask = (this.props.mask) ? true : false;
                    isValid = checkEmpty(value, 'input-parent', hasMask, target);
                } else if (inputType === 'number') {
                    var min = 0;

                    if (this.props.min) {
                        min = this.props.min;
                    }

                    if (this.props.max) {
                        if (value >= min && value <= this.props.max) {
                            isValid = true;
                        }
                    } else if (value >= min) {
                        isValid = true;
                    }
                } else if (inputType === 'checkbox') {
                    isValid = true;
                }
            }
        } else {
            isValid = true;
        }

        if (this.props.validCallback) {
            this.props.validCallback(this.props.id, isValid);
        }

        this.props.setValue(value);
    }

    checkValidation(event) {
        event.preventDefault();
        this.validate(event.target, event.target.value);
    }

    onFocus(event) {
        setFocusInput(event.target, true, 'input-parent');
    }

    onBlur(event) {
        setFocusInput(event.target, false, 'input-parent');
    }

    generateInput()
    {
        if (this.props.mask) {
            return <InputMask  mask={this.props.mask} value={this.props.value} onChange={e => this.checkValidation(e)} onFocus={e => this.onFocus(e)} onBlur={e => this.onBlur(e)}>
                {(inputProps) => <Input id={this.props.id} type={this.props.type} placeholder={this.props.placeholder} />}
            </InputMask>;
        } else if (this.props.type === 'dateTime') {
            return <ReactDatetime value={this.props.value} inputProps={{ placeholder: this.props.placeholder, id: this.props.id }} input={true} onChange={(e) => this.validate(document.getElementById(this.props.id), e)} dateFormat='DD/MM/YYYY' closeOnSelect={true}  timeFormat={false}/>;
        } else if (this.props.type === 'checkbox') {
            return <div className="custom-control custom-checkbox m-auto">
                <input className="custom-control-input" value={this.props.value} id={this.props.id} type="checkbox" onChange={e => this.checkValidation(e)} />
                <label className="custom-control-label" htmlFor={this.props.id}>
                    {this.props.label}
                </label>
            </div>
        } else {
            return <Input id={this.props.id} max={this.props.max} min={this.props.min} placeholder={this.props.placeholder} type={this.props.type} value={this.props.value} onChange={e => this.checkValidation(e)} onFocus={e => this.onFocus(e)} onBlur={e => this.onBlur(e)} />;
        }
    }

    render() {
        return (
            <>
                <FormGroup className="form-gorup mb-3">
                    <InputGroup className={"input-parent " + this.props.outClass} /* "input-group-alternative" */ >
                        {(this.props.leftAddon) ?
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText className="left-addon">
                                    <i className={this.props.leftAddon} />
                                </InputGroupText>
                            </InputGroupAddon>
                            : ''}
                        {this.generateInput()}
                        {(this.props.type === 'checkbox') ? <div>
                            <i id={"input-ok" + this.props.id} className="fas fa-check text-success d-none" />
                            <i id={'input-error' + this.props.id} className="fas fa-exclamation text-danger d-none" />
                            {(this.props.tipError) ?
                                <UncontrolledTooltip delay={0} placement="bottom" target={'input-error' + this.props.id}>
                                    <small>{this.props.tipError}</small>
                                </UncontrolledTooltip>
                                : ''}
                             </div> :
                            <InputGroupAddon addonType="append">
                                <InputGroupText className="border-left-0 right-addon">
                                    <i id={"input-ok" + this.props.id} className="fas fa-check text-success d-none" />
                                    <i id={'input-error' + this.props.id} className="fas fa-exclamation text-danger d-none" />
                                    {(this.props.tipError) ?
                                        <UncontrolledTooltip delay={0} placement="bottom" target={'input-error' + this.props.id}>
                                            <small>{this.props.tipError}</small>
                                        </UncontrolledTooltip>
                                        : ''}
                                </InputGroupText>
                            </InputGroupAddon>
                        }
                        {(this.props.rightAddon) ?
                            <InputGroupAddon addonType="append">
                                <InputGroupText id={'right-addon' + this.props.id} className="right-addon">
                                    <i className={this.props.rightAddon} onClick={(this.props.type === 'password') ? e => showPassword(e.target) : (this.props.rightAddonAction ? () => this.props.rightAddonAction() :  e => e.preventDefault() )} /> 
                                </InputGroupText>
                                {(this.props.rightTip) ?
                                    <UncontrolledTooltip delay={0} placement="bottom" target={'right-addon' + this.props.id}>
                                        <small>{this.props.rightTip}</small>
                                </UncontrolledTooltip>
                                : ''}
                            </InputGroupAddon>
                        : ''}
                    </InputGroup>
                </FormGroup>
            </>
        )
    }
}

export default InputAddon;
import React from "react";
import {
  Modal,
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import CryptoJS from "crypto-js";
import moment from "moment";
import InputGroupValidation from "../Utilities/InputGroupValidation";
import InputAddon from "../Utilities/InputAddon";
import ModalLoading from "../Modals/Loading";
import {
  setTiposOperacao,
  setdocsByTpo,
  clearDocsByTpo,
} from "../../logic/actions";
import { connect } from "react-redux";

class ModalNovoprotocolo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      tpoOperacao: "",
      descricaoOperacao: "",
      docsByTpo: {
        descricao: "",
        extensoes: "",
        idFile: "",
        link: "",
        nomeDoc: "",
        obrigatorio: "",
      },
      npf: this.props.usuarioDados.npf,
      telefone: this.props.usuarioDados.telefone_fixo,
      celular: this.props.usuarioDados.telefone_celular,
      email: this.props.usuarioDados.email,
      obsProtocolo: "",
      file: null,
      files: [],
      base64URL: "",
      exigeDoc: true,
    };
  }

  componentDidMount() {
    this.getTpoAreaAssociado();
  }

  componentWillUnmount() {
    this.props.dispatch(clearDocsByTpo());
  }

  inputStateUpdate(property, value) {
    this.setState({ [property]: value });
  }

  handleTpoChange(event) {
    this.getDocByTpoAreaAssociado(event.target.value);
    this.setState({ tpoOperacao: event.target.value });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  handleFileInputChange = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        this.setState({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState(
      {
        file: e.target.files[0],
      },
      () => {
        this.setState({
          files: [...this.state.files, this.state.file],
        });
      }
    );
  };

  handleSubmitForm = async (event) => {
    event.preventDefault();

    this.setState({ showLoading: true });

    if (
      this.state.exigeDoc &&
      this.state.files.length === 0 &&
      this.state.obsProtocolo === ""
    ) {
      this.setState({ showLoading: false });
      this.props.dialog(
        "warning",
        "Atenção",
        "Preencha o campo de observação e anexe um arquivo."
      );
      return;
    }

    var token = CryptoJS.MD5(moment().format("DD/MM/YYYY")).toString();

    var files = this.state.files.map((file, index) => {
      const lastDot = file.name.lastIndexOf(".");
      const fileExtension = file.name.substring(lastDot);

      return {
        nomeDoc: this.props.protocolosAssociado.docsByTpo[index].nomeDoc,
        idFile: this.props.protocolosAssociado.docsByTpo[index].idFile,
        extensao: fileExtension,
        base64: file.base64,
      };
    }, this);

    var body = {
      action: "saveNewProtocoloAreaAssociado",
      token: token,
      tpo: this.state.tpoOperacao,
      unidade: 1,
      npf: this.state.npf,
      email: this.state.email,
      nome: this.props.usuarioDados.nome,
      cod_parentesco: this.props.usuarioDados.cod_parentesco,
      obsProtocolo: this.state.obsProtocolo,
      celular: this.state.celular,
      telefone: this.state.telefone,
      cota: this.props.usuarioDados.cota,
      files: files,
    };

    var data = await fetch("https://protocolo.minastc.com.br/protocolo/ws/", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });

    if (data.status) {
      this.setState({ showLoading: false });
      this.props.dialog("success", "Sucesso!", data.result, this.props.close());
    } else {
      this.setState({ showLoading: false });
      this.props.dialog(
        "warning",
        "Ocorreu um erro! tentar novamente?",
        data.result,
        this.props.close()
      );
    }
  };

  handleInputClick = (event) => {
    const tpoDescricao = event.selectedOptions[0].dataset.descricao;
    if (tpoDescricao !== undefined) {
      this.setState({ descricaoOperacao: tpoDescricao });
    } else {
      this.setState({ descricaoOperacao: "" });
    }
  };

  async getDocByTpoAreaAssociado(tpoId) {
    var token = CryptoJS.MD5(moment().format("DD/MM/YYYY")).toString();

    var body = {
      action: "getDocByTpoAreaAssociado",
      token: token,
      tpoId: tpoId,
    };

    var data = await fetch("https://protocolo.minastc.com.br/protocolo/ws/", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });

    if (data.status) {
      this.props.dispatch(setdocsByTpo(data.result));
    } else if (
      data.result === "Nenhum documento listado para este tipo de operação!"
    ) {
      this.setState({ exigeDoc: false });
    } else {
      this.props.dispatch(clearDocsByTpo());
    }
  }

  async getTpoAreaAssociado() {
    var token = CryptoJS.MD5(moment().format("DD/MM/YYYY")).toString();

    var body = {
      action: "getTpoAreaAssociado",
      token: token,
    };

    var data = await fetch("https://protocolo.minastc.com.br/protocolo/ws/", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });

    if (data.status) {
      this.props.dispatch(setTiposOperacao(data.result));
    } else {
      this.props.dispatch(setTiposOperacao([]));
    }
  }

  render() {
    return (
      <>
        <ModalLoading show={this.state.showLoading} />
        <Modal
          isOpen={this.props.show}
          className="modal-dialog-centered"
          size="xl"
          toggle={this.props.close()}
        >
          <div className="modal-body p-0">
            <Card className="bg-lighter shadow border-0 ">
              <CardHeader className="bg-white">
                <div className="text-center">
                  <h2 className="text-center d-block">Abertura de Protocolo</h2>
                </div>
                <button
                  aria-label="Close"
                  className="close position-absolute"
                  style={{ right: "1rem", top: "1rem", zIndex: 1 }}
                  data-dismiss="modal"
                  type="button"
                  onClick={this.props.close()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody className="pt-3 pb-5">
                <div className="pb-5">
                  <span className="text-center text-center d-block">
                    Selecione o <strong>Tipo de Solicitação</strong>, e descreva
                    detadalhamente abaixo.
                    <br />
                    Para alterar os dados que são preenchidos automaticamente,
                    altere no menu Atualizar Cadastro.
                  </span>
                </div>
                <Form>
                  <InputGroupValidation>
                    <Row className="border-1">
                      <Col lg="12">
                        <FormGroup>
                          <label className="input-group-label">
                            Tipo de Solicitação
                          </label>
                          <Input
                            type="select"
                            name="tipo_operacao"
                            id="tipo_operacao"
                            className="form-control"
                            placeholder="Tipo de Operação"
                            onChange={(event) => this.handleTpoChange(event)}
                            onClick={(event) =>
                              this.handleInputClick(event.currentTarget)
                            }
                            required={true}
                          >
                            <option>Selecione um tipo</option>

                            {this.props.protocolosAssociado.tiposOperacoes
                              .length > 0
                              ? this.props.protocolosAssociado.tiposOperacoes.map(
                                  (item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item.tpoId}
                                        data-descricao={item.descricao}
                                      >
                                        {item.nome}
                                      </option>
                                    );
                                  }
                                )
                              : ""}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12 pb-2">
                        {this.state.descricaoOperacao !== "" ? (
                          <div>
                            <label>Descrição: </label>
                            <div className="bg-white p-2 border rounded border-primary">
                              <p>{this.state.descricaoOperacao}</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="input-group-label">NPF</label>
                          <Input
                            disable="true"
                            readOnly={true}
                            value={this.props.usuarioDados.npf}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="input-group-label">Quota</label>
                          <Input
                            disable="true"
                            readOnly={true}
                            value={this.props.usuarioDados.cota}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="input-group-label">Nome</label>
                          <Input
                            disable="true"
                            readOnly={true}
                            value={this.props.usuarioDados.nome}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="input-group-label">Email</label>
                          <Input
                            disable="true"
                            readOnly={true}
                            value={this.props.usuarioDados.email}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="input-group-label">Telefone</label>
                          <Input
                            disable="true"
                            readOnly={true}
                            value={this.props.usuarioDados.telefone_fixo}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="input-group-label">Celular</label>
                          <Input
                            disable="true"
                            readOnly={true}
                            value={this.props.usuarioDados.telefone_celular}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Documentos</label>
                      </Col>
                    </Row>
                    <Row>
                      {this.props.protocolosAssociado.docsByTpo.length > 0
                        ? this.props.protocolosAssociado.docsByTpo.map(
                            (item, index) => {
                              return (
                                <Col lg="4">
                                  <FormGroup key={index}>
                                    <label
                                      style={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Descrição:
                                    </label>
                                    &nbsp;
                                    <span style={{ fontWeight: 500 }}>
                                      {item.descricao}
                                      {item.obrigatorio === "S" ? "*" : ""}
                                    </span>
                                    <Input
                                      type="file"
                                      onChange={this.handleFileInputChange}
                                    />
                                    <label style={{ fontSize: 12 }}>
                                      Extensões: {item.extensoes}
                                    </label>
                                    <br />
                                    {item.link !== null ? (
                                      <div className="pb-1">
                                        <label>Formulário padrão:</label>
                                        <br />
                                        <a
                                          href={item.link}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {item.nomeDoc}
                                        </a>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </FormGroup>
                                </Col>
                              );
                            }
                          )
                        : ""}
                    </Row>
                    <Row>
                      <Col md="12">
                        <label className="input-group-label">Solicitação</label>
                        <InputAddon
                          outClass="input-group"
                          id="input-obs-protocolo"
                          validate={true}
                          type="textarea"
                          setValue={(value) =>
                            this.inputStateUpdate("obsProtocolo", value)
                          }
                        />
                      </Col>
                    </Row>
                  </InputGroupValidation>
                  <Button color="success" onClick={this.handleSubmitForm}>
                    Enviar
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    tiposOperacoes: state.protocolos.tiposOperacoes,
  };
};

export default connect(mapStatetoProps)(ModalNovoprotocolo);

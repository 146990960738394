import React from "react";
import { withRouter } from "react-router-dom";
import { Link  } from "react-router-dom";
import InputGroupValidation from "../../components/Utilities/InputGroupValidation";
import InputAddon from "../../components/Utilities/InputAddon";
import { Button, Card, CardBody, FormGroup, Form, InputGroup, Row, NavLink, Col } from "reactstrap";

class RecuperarSenha extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showPassword: false,
      email: '',
      unidade: 'minas',
      response: ''
    }
    this.setEmail = this.setEmail.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  setEmail(value) { this.setState({ email: value }); }

  redirect() {
      this.props.history.push({
        pathname: '/auth/login'
      });
  }

  async submitForm(event) {
    event.preventDefault();
    var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/recuperar_senha.php', 'f=' + this.state.unidade + '&e=' + this.state.email);

    if (data.status === 'ok') {
      this.setState({ dados: data });
      this.props.dialog(true, "Usuário encontrado!", data.mens, this.redirect);
    }
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 ">
              <div className="text-muted text-center pt-3 pb-4 font-bree-regular">
                <big><i className="fas fa-question-circle"></i> <b>Recuperar Senha</b></big>
              </div>
              <Form role="form" onSubmit={e => this.submitForm(e)}>
                  <InputGroupValidation>
                  <FormGroup>
                  <InputGroup className="justify-content-between">
                    <div className="text-muted text-left ml-0">Unidade:</div>
                    <div className="custom-control custom-radio">
                      <input className="custom-control-input" defaultChecked id="customRadioMinas" name="customRadio" type="radio" onClick={e => this.setState({ unidade: 'minas' })} />
                      <label className="custom-control-label" htmlFor="customRadioMinas">Minas</label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input className="custom-control-input" id="customRadioNautico" name="customRadio" type="radio" onClick={e => this.setState({ unidade: 'nautico' })} />
                      <label className="custom-control-label" htmlFor="customRadioNautico">Náutico</label>
                    </div>
                  </InputGroup>
                </FormGroup>
                  <InputAddon id="email" validate={true} placeholder="Email" type="email" value={this.state.email} setValue={this.setEmail} leftAddon="fas fa-envelope" tipError="E-mail inválido" />
                  <div className="text-center">
                    <Button id="buttonId" type="submit" className="my-2" color="minas-blue-dark" >
                      Enviar
                    </Button>
                    </div>
                </InputGroupValidation>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-1">
            <Col xs="6">
              <NavLink className="text-light text-minas-blue-light" to="/auth/login" tag={Link}>
              <i className="fas fa-door-open"></i> <small>Login</small>
              </NavLink>
            </Col>
            <Col className="text-right" xs="6">
              <NavLink className="text-light text-minas-blue-light" to="/auth/registrar" tag={Link}>
              <i className="fas fa-id-card"></i> <small>Registrar</small>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default withRouter(RecuperarSenha);

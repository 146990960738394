import React from "react";
import { Card, CardHeader, CardBody, Col, Row, Button } from "reactstrap";

class Rankings extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      squash: ''
    };
  }

  componentDidMount() {
    this.getSquash();
  }

  async getSquash() {
    var data = await this.props.requisition(true, true, 'https://socio.minastc.com.br/ws/ws_planilha_squash.php', 'key=df6900b4b38edbff1ece1b6a6e53d07e');
    if (data.status === 'ok') {
      this.setState({ squash: data.link });
    }
  }
  
  render() {

    return (
      <>
        <Row>
          <Col>
            <Card className="bg-secondary  shadow">
              <CardHeader className="bg-white border-0">
                <Row>
                  <Col >
                    <h3 className="mb-0 text-center">Rankings</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="text-center">
                <h6>
                  Modalidades:
                </h6>
                {(this.state.squash === '') ? '' :
                  <a href={this.state.squash} download><Button color="info" className="m-1" size="sm">Squash</Button></a>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        </>
    );
  }
}

export default Rankings;